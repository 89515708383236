import $ from 'jquery';
import Clipboard from 'clipboard';
import {last, trim} from 'lodash-es';
import moment from 'moment-timezone';
import StackTrace from 'stacktrace-js';
import removeFieldInit from './modules/utils/removeFieldInit';
import empty from './modules/utils/empty';
import forEach from './modules/utils/forEach';
import initTT from './modules/utils/initTT';
import timeZoneFix from './modules/utils/timeZoneFix';
import contentColorPickers from './modules/builder/design/contentColorPickers';
import initDesign from './modules/builder/design';
import reloadPreview from './modules/builder/design/reloadPreview';
import loginRedirect from './modules/utils/loginRedirect';
import {errorToast, showToast} from './modules/utils/toasts';
import {eventAddYtChannel} from './modules/youtube-subscribe/app';
import {
  checkEmptyPlaylists,
  initQuiz,
  initSls,
  reindexLibProfiles,
  reindexQuestions
} from './modules/playlist-generator/app';
import {eventAddSpotifyArtist} from './modules/presave/spotify/app/artist';
import {eventAddSpotifyPodcast} from './modules/presave/spotify/app/podcast';
import {eventAddAmazonArtist} from './modules/presave/amazon/app/artist';
import {eventAddAmazonPodcast} from './modules/presave/amazon/app/podcast';
import {eventAddExistingPl} from './modules/presave/app';
import {startContentLoading, startLoading, stopContentLoading, stopLoading} from './modules/utils/loading';

import intercom from './modules/utils/intercom';
import 'jquery-validation';

import 'select2';
import '@babel/polyfill';
import '@fortawesome/fontawesome-pro/js/fontawesome';
import '@fortawesome/fontawesome-pro/js/brands';
import '@fortawesome/fontawesome-pro/js/light';
import '@fortawesome/fontawesome-pro/js/solid';
import 'materialize-css';
import 'froala-editor/js/froala_editor.pkgd.min';
//import 'froala-editor/js/third_party/embedly.min';

import '../scss/app.scss';
import 'froala-editor/css/froala_editor.pkgd.css';
import 'codemirror/lib/codemirror.css';
import 'select2/dist/css/select2.css';
import {getMlCollections, initIntegrationStep} from "./modules/integration-step";
import initGenderOptionsModal from "./modules/utils/initGenderOptionsModal";
import initCustomOptionsModal from "./modules/utils/initCustomOptionsModal";
import initGenresOptionsBuModal from "./modules/utils/initGenresOptionsBuModal";
import initGenresOptionsFormModal from "./modules/utils/initGenresOptionsFormModal";
import initSocialStep from "./modules/social-step";
import './modules/builder/design/embed-player';

/* eslint-disable new-cap */
window.$ = $;
window.Clipboard = Clipboard;

window.onerror = (msg, url, lineNo, columnNo, error) => {
  (function() {
    if(null !== error) {
      StackTrace.fromError(error).then(response => {
        StackTrace.report(response, wp.errors, msg);
      });
    } else {
      console.error('Uncatched Error');
    }
  })();
  return true;
};

/*
 * Content-Type:text/javascript
 *
 * A bridge between iPad and iPhone touch events and jquery draggable,
 * sortable etc. mouse interactions.
 * @author Oleg Slobodskoi
 *
 * modified by John Hardy to use with any touch device
 * fixed breakage caused by jquery.ui so that mouseHandled internal flag is reset
 * before each touchStart event
 *
 */
(function( $ ) {

  $.support.touch = typeof Touch === 'object';

  if (!$.support.touch) {
    return;
  }

  const proto =  $.ui.mouse.prototype;
    const _mouseInit = proto._mouseInit;

  $.extend( proto, {
    _mouseInit: function() {
      this.element
        .bind( "touchstart." + this.widgetName, $.proxy( this, "_touchStart" ) );
      _mouseInit.apply( this, arguments );
    },

    _touchStart: function( event ) {
      // if ( event.originalEvent.targetTouches.length != 1 ) {
      //   return false;
      // }

      this.element
        .bind( "touchmove." + this.widgetName, $.proxy( this, "_touchMove" ) )
        .bind( "touchend." + this.widgetName, $.proxy( this, "_touchEnd" ) );

      this._modifyEvent( event );

      $( document ).trigger($.Event("mouseup")); // reset mouseHandled flag in ui.mouse
      this._mouseDown( event );

      // return false;
    },

    _touchMove: function( event ) {
      this._modifyEvent( event );
      this._mouseMove( event );
    },

    _touchEnd: function( event ) {
      this.element
        .unbind( "touchmove." + this.widgetName )
        .unbind( "touchend." + this.widgetName );
      this._mouseUp( event );
    },

    _modifyEvent: function( event ) {
      event.which = 1;
      const target = event.originalEvent.targetTouches[0];
      event.pageX = target.clientX;
      event.pageY = target.clientY;
    }

  });

})( jQuery );

$.FroalaEditor.ICON_TEMPLATES = {
  font_awesome: '<i class="fas fa-[NAME]"></i>',
  text: '<span style="text-align: center;">[NAME]</span>',
  image: '<img src=[SRC] alt=[ALT] />'
};

$.FroalaEditor.DefineIcon('undo', {
  NAME: 'undo-alt'
});
$.FroalaEditor.DefineIcon('redo', {
  NAME: 'redo-alt'
});
$.FroalaEditor.DefineIcon('quickInsert', {
  NAME: 'plus'
});

let clipboard;
const dashReload = $('#dashboard-reload');

$(document).ready(() => {
  init();
});

let modalAdmin;
const modalAdminElem = document.querySelector('#modal-admin-options');
let sideNav;
const sideNavElem = document.querySelector('.sidenav');

const setRetargetingCheckboxState = (e, wrapper, isMainCheckbox) => {
  const isChecked = $(e.target).prop('checked');

  let $selectors = wrapper
    .find('input[type="checkbox"]');

  const $values = wrapper
    .find('input[type="hidden"]');

  if (!isChecked) {
    $selectors.prop('checked', false);
    $values.val(false);
  }

  if (isChecked && isMainCheckbox) {
    $selectors = $('.may-edit-checkbox, .show-checkbox')
      .find('input[type="checkbox"]');
  }

  $selectors.prop('disabled', !isChecked);
};

function initColorPickers() {
  if($(".color-picker").length) {
    contentColorPickers();
  }
}


/**
 * Init
 *
 * Should be called on all AJAX or regular page loads
 */
function init() {
  $('#bu_retargeting_consent_selector').on('click', e => {
    const wrapper = $('.retargeting-consent')
      .nextAll('.retargeting-checkbox-wrapper');

    setRetargetingCheckboxState(e, wrapper, true);
  });

  $('#bu_retargeting_show_checkbox_selector').on('click', e => {
    const wrapper = $('.show-checkbox')
    .nextAll('.retargeting-checkbox-wrapper');

    setRetargetingCheckboxState(e, wrapper);
  });

  $('#campaign_retargeting_show_checkbox_selector').on('click', e => {
    const wrapper = $('.show-checkbox')
    .nextAll('.retargeting-checkbox-wrapper');

    setRetargetingCheckboxState(e, wrapper);
  });

  // Login redirect
  loginRedirect();

  // Materialize collapsable
  $('.collapsible').collapsible();

  // Materialize text fields
  M.updateTextFields();

  // Tooltips function
  initTT();

  // Activity links function
  activityLinkInit();

  // Select2
  $('.mselect').select2();

  // Topbar setup
  $('.topbar-red').sidenav();

  // Dropdown
  $('.dropdown-trigger').dropdown({
    coverTrigger: false,
    constrainWidth: false
  });

  // Fix times to local
  timeZoneFix();

  /**
   * Admin Option Modal
   */
  if (empty(modalAdmin)) {
    modalAdmin = M.Modal.init(modalAdminElem, {
      dismissible: false,
      opacity: 0.8
    });
  }

  /**
   * Setup the artist page submit button
   */
  if ($('#artist-submit').length) {
    $('#artist-submit').floatingActionButton({
      direction: 'left'
    });
  }

  /**
   * Side Nav
   * If we haven't initialized the sidenav yet do it
   */
  if (empty(sideNav)) {
    sideNav = M.Sidenav.init(sideNavElem, {
      inDuration: 2000,
      outDuration: 2000
    });
  }

  // Assign the sidenav instance
  sideNav = M.Sidenav.getInstance(sideNavElem);

  // Minimize sidenav on click function
  $('#minimize-sidenav')
    .off()
    .on('click', () => {
      // Close the side nav
      sideNav.close();
      $('body').removeClass('side-nav-max');
      $('body').addClass('side-nav-min');
      $('#slide-out').addClass('hide');
      $('.sidenav-trigger').css('visibility', 'visible');
      $('#footer-dashboard').css('padding-left', '0px');
      $('.show-minimal-menu').removeClass('hide');
      // If the campaign preview is around we need to reload it
      if ($('#campaign-preview-card').length) {
        reloadPreview();
        init();
        initDesign();
      }
      // Update the users saved preferences
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'update_side_nav',
          minimize: 'true'
        }
      });
    });

  // Maximize sidenav on click function
  $('.sidenav-trigger')
    .off()
    .on('click', () => {
      $('body').addClass('side-nav-max');
      $('body').removeClass('side-nav-min');
      $('.sidenav-trigger').css('visibility', 'hidden');
      $('#slide-out').removeClass('hide');
      $('#footer-dashboard').css('padding-left', '300px');
      $('.show-minimal-menu').addClass('hide');

      // If the campaign preview is around we need to reload it
      if ($('#campaign-preview-card').length) {
        reloadPreview();
        init();
        initDesign();
      }

      // Update users preferences
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'update_side_nav',
          minimize: 'false'
        }
      });
    });

  /**
   * Create campaign logic
   */
  if ($('#modal-create-campaign').length) {
    // Setup create campaign modal variables.
    let modalCreate;
    const modalCreateElem = document.querySelector('#modal-create-campaign');
    const modalCreateSubmit = $(
      '#modal-create-campaign .modal-footer button[name="submit"]'
    );

    // Chr count on campaign name field
    $('#create_campaign_name').characterCounter();

    // On-click of create campaign button.
    $('#create-campaign')
      .off()
      .on('click', () => {
        // If we haven't setup the modal do it
        if (empty(modalCreate)) {
          modalCreate = M.Modal.init(modalCreateElem, {
            dismissible: false,
            opacity: 0.8
          });
        }

        // Get the modal create instance
        modalCreate = M.Modal.getInstance(modalCreateElem);

        // Open the create campaign modal
        modalCreate.open();
        modalCreateSubmit.attr('disabled', 'disabled');
      });

    $('#create_campaign_name').keyup(function() {
      const value = $(this).val();
      const { length } = value;
      if (length < 5) {
        $(this).removeClass('valid');
        $(this).addClass('invalid');
        $('#modal-create-campaign .helper-text').attr(
          'data-error',
          'Enter something more descriptive'
        );
        modalCreateSubmit.attr('disabled', 'disabled');
      } else if (length > 60) {
        $(this).removeClass('valid');
        $(this).addClass('invalid');
        $('#modal-create-campaign .helper-text').attr(
          'data-error',
          'Campaign name too long'
        );
        modalCreateSubmit.attr('disabled', 'disabled');
      } else {
        $(this).removeClass('invalid');
        $(this).addClass('valid');
        modalCreateSubmit.removeAttr('disabled');
      }
    });

    $('.serivce-row-input')
      .on('click', function() {
        const campType = $('#create_campaign_type').val();

        if("soc_sign_form_combo" === campType) {
          if($('.serivce-row-input:checked').length === 0) {
            $('input#create_campaign_spotify').prop('checked', true)
          }
        }
      });

    // Campaign Editor Show/Hide
    $('#form-create-campaign #create_campaign_type')
      .off()
      .select2()
      .on('change', function() {
        const type = $(this).val();
        const $servicesRow = $('.campaign_creator_services_row');
        const $formRow = $('.campaign_creator_form_row');
        const $servicesBlocks = $('.col.service-elem');
        // Services blocks without spotify
        const $servBlNotSpotify = $servicesBlocks.not('.create_campaign_spotify');
        const $hostedFalse = $('#create_campaign_hosted_false');
        const $spotifyField = $('input#create_campaign_spotify');

        switch (type) {
          case 'form':
          default:
            $servBlNotSpotify.removeClass('hide');
            $servicesRow
              .find('input[type="checkbox"]')
              .prop('checked', false)
              .addClass('hide');
            $formRow.addClass('hide');
            $hostedFalse
              .prop('disabled', false)
              .closest('p')
              .show();
            break;
          case 'pre_save':
            $spotifyField.prop('disabled', false);
            $servBlNotSpotify.removeClass('hide');
            $servicesRow.removeClass('hide');
            $formRow.removeClass('hide');
            $hostedFalse
              .prop('disabled', false)
              .closest('p')
              .show();
            break;
          case 'playlist_generator_quiz':
          case 'playlist_generator_sls':
            $spotifyField
              .prop('checked', true)
              .prop('disabled', true);
            $servBlNotSpotify
              .find('input[type="checkbox"]')
              .prop('checked', false);
            $servBlNotSpotify.addClass('hide');
            $servicesRow.removeClass('hide');
            $formRow.removeClass('hide');
            $('#create_campaign_hosted_true').prop('checked', true);
            $hostedFalse
              .prop('disabled', true)
              .closest('p')
              .hide();
            break;
          case 'soc_sign_form_combo':
            if($('.serivce-row-input:checked').length === 0) {
              $spotifyField.prop('checked', true)
            }
            $spotifyField.prop('disabled', false);
            $servBlNotSpotify.removeClass('hide');
            $servicesRow.removeClass('hide');
            $formRow.addClass('hide');
            $hostedFalse
              .prop('disabled', false)
              .closest('p')
              .show();
            break;
          case 'youtube_subscribe':
            $formRow.removeClass('hide');
            $servicesRow.addClass('hide');
            break;
        }
      });

    // On create campaign modal form submit.
    $('#form-create-campaign')
      .off()
      .on('submit', function(e) {
        e.preventDefault();

        // // Turn disabled input on temporarily so that it is included in serialize
        const $spotifyCheckbox = $('#create_campaign_spotify');
        const isSpotifyDisabled = $spotifyCheckbox.prop('disabled');

        if (isSpotifyDisabled === true) {
          $spotifyCheckbox
            .prop('disabled', false);
        }

        // Get the form data.
        const formData = $(this).serialize();

        // Turn disabled input back on just in case of validation failure
        if (isSpotifyDisabled === true) {
          $spotifyCheckbox
            .prop('disabled', true);
        }

        const $footer = $('#form-create-campaign .modal-footer');
        const $okBtn = $footer.find('button');
        const $cancelBtn = $footer.find('a');

        // Start the create campaign work.
        $.ajax({
          url: wp.ajaxurl,
          method: 'POST',
          data: {
            action: 'create_campaign',
            data: formData,
            userid: wp.userid
          },
          beforeSend() {
            // Disable submit button.
            $okBtn
              .addClass('disabled')
              .text('Creating Campaign');

            $cancelBtn
              .addClass('disabled');

            showToast('Hang Tight, Creating Your Campaign...');
          },
          error(error) {
            // Handle SalesForce API response errors
            if (typeof error.responseJSON.api_message !== 'undefined') {
              errorToast(error.responseJSON.api_message);
            }

            errorToast(error.responseJSON.message);
            if ( error.responseJSON.message === 'Campaign name exists, please change and resubmit' ) {
              $('#create_campaign_name')
                .removeClass('valid')
                .addClass('invalid');
            }
          },
          success(data) {
            showToast('Redirecting To Campaign Editor...');
            // Inject new page into dash reload div
            dashReload.html(data);

            // Close the modal
            modalCreate.close();
            // Cleanup
            modalCreate.destroy();
            startLoading();
            init();
            initCampaigns();
            $('body').addClass('form');
            stopLoading();
          },
          complete: function () {
            $okBtn
              .removeClass('disabled')
              .text('Create New Campaign');

            $cancelBtn
              .removeClass('disabled');
          }
        });
      });
  }

  /**
   * Copy campaign logic
   */
  if ($('#modal-copy-campaign').length) {
    $('#form-copy-campaign')
      .off()
      .on('submit', function(e) {
        e.preventDefault();
        const data = $(this).serialize();
        const copyFrom = $('#form-copy-campaign #copy-from-campaign').val();
        const elem = document.querySelector('#modal-copy-campaign');
        const modalInstance = M.Modal.getInstance(elem);
        $.ajax({
          url: wp.ajaxurl,
          method: 'POST',
          data: {
            action: 'create_campaign',
            copy_id: copyFrom,
            data,
            userid: wp.userid
          },
          beforeSend() {
            $('#form-copy-campaign .modal-footer button').addClass('disabled');
            $('#form-copy-campaign .modal-footer a').addClass('disabled');
            $('#copy-campaign-reload').addClass('hide');
            $('.smf-content-loading-animation').removeClass('hide');
            showToast('Copying Your Campaign...');
          },
          error(error) {
            $('#form-copy-campaign .modal-footer button').removeClass(
              'disabled'
            );
            $('#form-copy-campaign .modal-footer a').removeClass('disabled');
            $('#copy-campaign-reload').removeClass('hide');
            $('.smf-content-loading-animation').addClass('hide');
            M.toast({
              html: error.responseJSON.message,
              classes: 'error-toast'
            });
            if (
              error.responseJSON.message ===
              'Campaign name exists, please change and resubmit'
            ) {
              $('#copy-campaign-reload #copy_campaign_name').removeClass(
                'valid'
              );
              $('#copy-campaign-reload #copy_campaign_name').addClass(
                'invalid'
              );
            }
          },
          success(response) {
            showToast('Redirecting To Campaign Editor...');
            dashReload.html(response);
            $('#form-copy-campaign .modal-footer button').removeClass(
              'disabled'
            );
            $('#form-copy-campaign .modal-footer a').removeClass('disabled');
            $('#copy-campaign-reload').removeClass('hide');
            modalInstance.close();
            modalInstance.destroy();
            startLoading();
            init();
            initCampaigns();
            $('body').addClass('form');
            stopLoading();
          }
        });
      });
  }

  /**
   * Delete campaign logic
   */
  if ($('#modal-delete-campaign').length) {
    let modalDelete;
    const modalDeleteElem = document.querySelector('#modal-delete-campaign');
    modalDelete = M.Modal.init(modalDeleteElem, {
      opacity: 0.8,
      onCloseStart() {
        $('#modal-delete-campaign #delete-message').text(
          'Loading data please wait...'
        );
        $('#modal-delete-campaign #delete-campaign').text(
          'Yes, delete this campaign'
        );
        $('#modal-delete-campaign #delete-campaign').addClass('disabled');
      }
    });
    modalDelete = M.Modal.getInstance(modalDeleteElem);

    // If we can delete or inactive this will be the button action
    $('#delete-campaign').on('click', function() {
      const deleteId = $(this).data('deleteid');
      const inactiveId = $(this).data('inactiveid');
      const security = $(this).data('security');
      const dataObj = {
        security
      };
      if (empty(deleteId)) {
        dataObj.action = 'inactive_campaign';
        dataObj.campaign = inactiveId;
      } else {
        dataObj.action = 'delete_campaign';
        dataObj.campaign = deleteId;
      }
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: dataObj,
        beforeSend() {
          startLoading();
          modalDelete.close();
        },
        error() {
          if (empty(deleteId)) {
            errorToast('Inactivating campaign failed');
          } else {
            errorToast('Deleting campaign failed');
          }
        },
        success(data) {
          if (empty(deleteId)) {
            showToast('Campaign made inactive');
          } else {
            showToast('Campaign deleted');
          }
          dashReload.html(data);
        },
        complete() {
          stopLoading();
          $('body').addClass('form');
          init();
          modalDelete.destroy();
        }
      });
    });
  }

  /**
   * Global Search
   */
  $('.topnav-search-container input').on('focusin', () => {
    $('.topnav-search-container .fa-search').css('color', '#333');
    $('.topnav-search-container .search-filters').css('color', '#333');
    $('.topnav-search-container .fa-search').css('display', 'inline-block');
    $('.topnav-search-container .fa-spinner').css('display', 'none');
  });

  $('.topnav-search-container input').on('focusout', () => {
    $('.topnav-search-container .fa-search').css('color', '#fff');
    $('.topnav-search-container .search-filters').css('color', '#fff');
    $('.topnav-search-container .fa-search').css('display', 'inline-block');
    $('.topnav-search-container .fa-spinner').css('display', 'none');
  });

  $('.topnav-search-container input').on('keyup', function() {
    const searchChr = $(this).val().length;
    if (searchChr > 3) {
      const activeFilter = $('.topnav-search-container input').data(
        'activefilter'
      );
      const activeSearch = $(this).val();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'global_search',
          filter: activeFilter,
          search: activeSearch
        },
        beforeSend() {
          $('.topnav-search-container .fa-search').css('display', 'none');
          $('.topnav-search-container .fa-spinner').css(
            'display',
            'inline-block'
          );
        },
        error() {},
        success() {},
        complete() {
          $('.topnav-search-container .fa-search').css(
            'display',
            'inline-block'
          );
          $('.topnav-search-container .fa-spinner').css('display', 'none');
        }
      });
    }
  });

  $('#filter-dropdown a')
    .off()
    .on('click', function() {
      const filter = $(this).data('filter');
      $('.topnav-search-container .active-filter').text(filter);
      $('.topnav-search-container input').attr('data-activefilter', filter);
    });

  $('#forms-reload').on('click', '.pagination a', event => {
    $(event.target)
      .closest('.clickable a')
      .each(function() {
        const page = $(this).data('page');
        const postsPerPage = $('input:radio[name=form_per_page]:checked').val();
        const searchQuery = $('#campaigns_search').val();
        const searchType = $('#campaigns-search').find('input[name="search_type"]:checked').val() == 'creator';
        const div = $('#forms-reload');
        $.ajax({
          url: wp.ajaxurl,
          method: 'POST',
          data: {
            action: 'forms_table_content',
            page,
            posts_per_page: postsPerPage,
            search_query: searchQuery,
            search_type: searchType
          },
          error() {
            errorToast();
          },
          success(updateContent) {
            div.html(updateContent);
            div.find('.tooltipped').tooltip();
          },
          complete() {
            $('html').animate(
              {
                scrollTop: $('#campaigns-search').offset().top
              },
              700
            );
            activityLinkInit();
          }
        });
      });
  });

  initColorPickers();

  initCampaignActivityIndication();

  $(".input-field input")
    .on('focus', function () {
      $(this).parent().find(".awesome-prefix").css('color',  '#89181a');
    })
    .on('focusout', function () {
      $(this).parent().find(".awesome-prefix").css('color',  'black');
    });
}


// Init Campaigns Page
// ==========================================================================
// Should be called anytime the campaign builder is AJAX loaded.
// ==========================================================================
const initCampaigns = () => {
  // Which page we are saving
  const tabLink = $('input[name=campaign_link]').val();
  console.log('initCampaigns. tabLink = ' + tabLink);
  const $reloadWrap = $('#campaign_reload_wrap');

  // Tooltips
  initTT();
  // Remove fields
  removeFieldInit();
  // Activity Link
  activityLinkInit();
  // Materialize tabs
  $('.tabs').tabs();
  // Materialize collapsible
  $('.collapsible').collapsible();
  // Fix dates
  timeZoneFix();

  eventAddYtChannel();
  eventAddSpotifyArtist();
  eventAddAmazonArtist();
  eventAddExistingPl();
  eventAddSpotifyPodcast();
  eventAddAmazonPodcast();

  // jQuery UI sortable elements
  $('.sortable').sortable({
    axis: 'y',
    containment: 'parent',
    distance: 10,
    tolerance: 'pointer',
    handle: '.drag-handle',
    helper(e, row) {
      row.children().each(function() {
        $(this).width($(this).width());
      });
      return row;
    },
    start(e, ui) {
      const sort = $(this).sortable('instance');
      sort.containment[3] += ui.helper.height();
    }
  });

  $('.quiz-sortable').sortable({
    placeholder: 'highlight',
    distance: 10,
    tolerance: 'pointer',
    stop() {
      reindexQuestions();
    }
  });

  $('.sls-sortable').sortable({
    placeholder: 'highlight',
    distance: 10,
    tolerance: 'pointer',
    stop() {
      reindexLibProfiles();
    }
  });

  function formatState (state) {
    if (!state.element || !state.element.dataset.label) {
      return state.text;
    }
    let $state = $(
      '<span>' + state.text + '- <i class="campaign-meta-dates">' + state.element.dataset.label + '</i>' + '</span>'
    );
    return $state;
  };

  // Select2
  $('.mselect').select2({
    templateResult: formatState
  });

  // Save button
  $('.fixed-action-btn').floatingActionButton({
    direction: 'left'
  });

  // Linking in the form creator menu
  $('a[data-step], span[data-step]')
    .unbind('click')
    .click(function() {
      const campaignLink = $(this).data('step');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'campaign_creator_step',
          campaign_link: campaignLink
        },
        beforeSend() {
          startLoading();
        },
        complete() {
          stopLoading();
          const form = $('#campaign-step-reload form.campaign-creator-form');
          form.append('<input type="hidden" name="step_tab_changed" value="true" />')
          form.submit();
        }
      });
    });

  $.validator.addMethod(
    'spotify_track_album',
    function(value, element) {
      return (
        this.optional(element) || /^spotify:(track|album):\b\w+$/i.test(value)
      );
    },
    'Please enter a valid Spotify track or album URI.'
  );

  $.validator.addMethod(
    'spotify_artist_user',
    function(value, element) {
      return (
        this.optional(element) ||
        /^spotify:(artist|user):\b[\w.-]+$/i.test(value)
      );
    },
    'Please enter a valid Spotify artist or user URI'
  );

  $.validator.addMethod(
    'spotify_playlist',
    function(value, element) {
      return (
        this.optional(element) ||
        /^spotify:(?:user:[\w.]*:)*playlist:\b\w*$/i.test(value)
      );
    },
    'Please enter a valid Spotify playlist URI.'
  );

  $.validator.addMethod(
    'amazon_track_album',
    function(value, element) {
      return (
        this.optional(element) || /^https:\/\/music.amazon.com\/albums\/.*$/i.test(value)
      );
    },
    'Please enter a valid Amazon track or album URL.'
  );

  $.validator.addMethod(
    'amazon_artist_user',
    function(value, element) {
      return (
        this.optional(element) ||
        /^https:\/\/music.amazon.com\/artists\/B0.*$/i.test(value)
      );
    },
    'Please enter a valid Amazon artist or user URL'
  );

  $.validator.addMethod(
    'amazon_playlist',
    function(value, element) {
      return (
        this.optional(element) ||
        /^https:\/\/music.amazon.com\/playlists\/[a-zA-Z0-9]{10}$/i.test(value) || /^https:\/\/music.amazon.com\/user-playlists\/[a-zA-Z0-9]{36}$/i.test(value)
      );
    },
    'Please enter a valid Amazon playlist URL.'
  );

  $.validator.addMethod(
    'deezer_track_album',
    function(value, element) {
      return (
        this.optional(element) ||
        /^(http|https):\/\/www.deezer.com.*\/(track|album)\/\d+\/?$/i.test(
          value
        )
      );
    },
    'Please enter a valid Deezer track or album URL.'
  );

  $.validator.addMethod(
    'deezer_artist_user',
    function(value, element) {
      return (
        this.optional(element) ||
        /^(http|https):\/\/www.deezer.com.*\/(artist|profile)\/\d+\/?$/i.test(
          value
        )
      );
    },
    'Please enter a valid Deezer artist or user URL'
  );

  $.validator.addMethod(
    'deezer_playlist',
    function(value, element) {
      return (
        this.optional(element) ||
        /^(http|https):\/\/www.deezer.com.*\/playlist\/\d+\/?$/i.test(value)
      );
    },
    'Please enter a valid Deezer playlist URL.'
  );

  // Pre-Save jqueryValidation
  function validateOnSave() {
    $('#campaign-integrations-step').validate({
      rules: {
        campaign_spotify_follow_text: {
          minlength: 2
        },
        campaign_amazon_follow_text: {
          minlength: 2
        },
        playlist_generator_playlists: {
          minlength: 10,
          spotify_playlist: true
        },
        spotify_add_new_task_name: {
          minlength: 2
        },
        spotify_add_new_task_uri: {
          minlength: 10,
          spotify_track_album: true
        },
        amazon_add_new_task_name: {
          minlength: 2
        },
        amazon_add_new_task_uri: {
          minlength: 10,
          amazon_track_album: true
        },
        campaign_deezer_follow_text: {
          minlength: 2
        }
      },
      errorClass: 'helper-text invalid',
      validClass: 'helper-text valid',
      errorElement: 'span',
      errorPlacement(error, element) {
        const placement = $(element).data('error');
        if (placement) {
          $(placement).append(error);
        } else {
          error.insertAfter(element);
        }
      }
    });

    $('.spotify-generator-pl-collection input').each(function() {
      $(this).rules('add', {
        required: true,
        minlength: 10,
        spotify_playlist: true
      });
    });
  }

  /**
   * Save Campaign Data
   * This workhorse is the functionality for when the save button is clicked
   * big things happening here so edit with care and make sure you know what you
   * are doing.
   * Calls PHP method campaign_creator_post_data() from the Smf_Core_Forms class
   */
  $('#dashboard-reload .campaign-creator-form').on('submit', function(e) {
    e.preventDefault();

    let geoBlock = false;
    let geoAllow = false;
    let youtubeCollection = false;
    let spotifyCollection = false;
    let spotifyArtistCollection = false;
    let spotifyCollectionOther = false;
    let amazonCollection = false;
    let amazonArtistCollection = false;
    let amazonCollectionOther = false;
    let deezerCollection = false;
    let deezerCollectionOther = false;
    let appleLibraryAdd = false;
    let appleLibraryFollow = false;
    let applePlaylist = false;
    let mlCollections = false;
    let formCollection = false;

    const $form = $(this);

    const isGeoOn = $form.find('#campaign_geo_mode_selector').is(':checked');

    if (tabLink === 'design') {
      const $redirectThanksUlr = $("#campaign_redirect_thanks_url");
      if($redirectThanksUlr !== undefined) {
        if ("1" === $("input:radio:checked[name='campaign_redirect_thanks']").val()) {
          $("#campaign-design-step").validate({
            rules: {
              campaign_redirect_thanks_url: {
                minlength: 10,
                url: true
              },
            },
            errorClass: 'helper-text invalid',
            validClass: 'helper-text valid',
            errorElement: 'span',
            errorPlacement(error, element) {
              const placement = $(element).data('error');
              if (placement) {
                $(placement).append(error);
              } else {
                error.insertAfter(element);
              }
            }
          });
          const valid = $('#campaign-design-step').valid();
          if (!valid) {
            M.toast({
              html: 'Please correct any errors to continue',
              classes: 'error-toast'
            });
            return;
          }
        } else if ("2" === $("input:radio:checked[name='campaign_redirect_thanks']").val()) {
          $("#campaign-design-step").validate({
            rules: {
              campaign_redirect_thanks_url_spotify: {
                minlength: 10,
                url: true
              },
              campaign_redirect_thanks_url_amazon: {
                minlength: 10,
                url: true
              },
              campaign_redirect_thanks_url_deezer: {
                minlength: 10,
                url: true
              },
              campaign_redirect_thanks_url_apple: {
                minlength: 10,
                url: true
              },
              campaign_redirect_thanks_url_youtube: {
                minlength: 10,
                url: true
              },
              campaign_redirect_thanks_url_email: {
                minlength: 10,
                url: true
              },
            },
            errorClass: 'helper-text invalid',
            validClass: 'helper-text valid',
            errorElement: 'span',
            errorPlacement(error, element) {
              const placement = $(element).data('error');
              if (placement) {
                $(placement).append(error);
              } else {
                error.insertAfter(element);
              }
            }
          });
          const valid = $('#campaign-design-step').valid();
          if (!valid) {
            M.toast({
              html: 'Please correct any errors to continue',
              classes: 'error-toast'
            });
            return;
          }
        }
      }
    }

    if (tabLink === 'main' && isGeoOn) {
      geoBlock = $form.find('#campaign_geo_block').val();
      geoAllow = $form.find('#campaign_geo_allow').val();

      if (!geoAllow && !geoBlock) {
        M.toast({
          html: 'At least one country should be added to the allow/block list',
          classes: 'error-toast'
        });

        return false;
      }
    }

    $('textarea').each(function() {
      const froalaID = $(this).attr('id');
      if ($(`#${froalaID}`).froalaEditor('codeView.isActive')) {
        const code = $(`#${froalaID}`).froalaEditor('codeView.get');
        $(`#${froalaID}`).froalaEditor('html.set', code);
      }
    });

    // All form data serialized
    let data = $form.serialize();

    // Div we are reloading
    const div = $('#campaign-step-reload');
    // Initially set all these variables to false and over-ride them later

    // Saving the integrations page
    if (tabLink === 'integrations') {
      validateOnSave();

      if ($('#spotify-playlist-generator-playlists-collection').length) {
        const playlists = $(
          '#spotify-playlist-generator-playlists-collection > li'
        ).length;
        if (playlists < 2) {
          M.toast({
            html: '2 playlists is the minimum',
            classes: 'error-toast'
          });
          return false;
        }
      }

      if ($('#youtube-container .youtube-collection tbody tr').length) {
        youtubeCollection = {};
        const youtubeCollectionItemAll = document.querySelectorAll(
          '#youtube-container .youtube-collection tbody tr'
        );
        forEach(youtubeCollectionItemAll, (index, value) => {
          const youtubePl = {};
          const plName = $(value)
            .find('.channel-name input')
            .val();
          const plUri = $(value)
            .find('.channel-url input')
            .val();
          $(value)
            .find('.channel-name input')
            .attr('name', `channel_name[${index}]`);
          $(value)
            .find('.channel-url input')
            .attr('name', `channel_url[${index}]`);
          youtubePl.name = plName;
          youtubePl.url = plUri;
          youtubeCollection[index] = youtubePl;
        });
      } else {
        youtubeCollection = 'empty';
      }

      if ($('#spotify-container .spotify-collection tbody tr').length) {
        spotifyCollection = {};
        const spotifyCollectionItemAll = document.querySelectorAll(
          '#spotify-container .spotify-collection tbody tr'
        );
        forEach(spotifyCollectionItemAll, (index, value) => {
          const spotifyCPl = {};
          const plNameC = $(value)
            .find('.playlist-name input')
            .val();
          const plUriC = $(value)
            .find('.playlist-uri input')
            .val();
          $(value)
            .find('.playlist-name input')
            .attr('name', `spotify_existing_playlist[${index}][name]`);
          $(value)
            .find('.playlist-uri input')
            .attr('name', `spotify_existing_playlist[${index}][uri]`);
          spotifyCPl.name = plNameC;
          spotifyCPl.uri = plUriC;
          spotifyCollection[index] = spotifyCPl;
        });
      } else {
        spotifyCollection = 'empty';
      }

      if ($('#spotify-container .spotify-artist-collection tbody tr').length) {
        spotifyArtistCollection = {};
        const spotifyArtistCollectionItemAll = document.querySelectorAll(
          '#spotify-container .spotify-artist-collection tbody tr'
        );
        forEach(spotifyArtistCollectionItemAll, (index, value) => {
          const spotifyArtist = {};
          const artistName = $(value)
            .find('.artist-name input')
            .val();
          const artistUri = $(value)
            .find('.artist-uri input')
            .val();
          $(value)
            .find('.artist-name input')
            .attr('name', `artist_name[${index}]`);
          $(value)
            .find('.artist-uri input')
            .attr('name', `artist_uri[${index}]`);
          spotifyArtist.name = artistName;
          spotifyArtist.url = artistUri;
          spotifyArtistCollection[index] = spotifyArtist;
        });
      } else {
        spotifyArtistCollection = 'empty';
      }

      if ($('#spotify-container .spotify-collection-other tbody tr').length) {
        let spotifyCollectionOtherSelector = '.spotify-collection-other tbody tr';
        const $spotifyPlMode = $("#campaign_spotify_playlist_mode");

        if ($spotifyPlMode.length) {
          if ($spotifyPlMode.val() == 'collective' ) {
            spotifyCollectionOtherSelector = "#spotify-container " + spotifyCollectionOtherSelector;
          } else {
            const optSettBlock = "#campaign_spotify_" + $spotifyPlMode.val() + "_mode_options_settings ";
            spotifyCollectionOtherSelector = optSettBlock + spotifyCollectionOtherSelector;
          }
        } else {
          spotifyCollectionOtherSelector = "#spotify-container " + spotifyCollectionOtherSelector;
        }

        spotifyCollectionOther = {};
        const spotifyCollectionOtherItemAll = document.querySelectorAll(spotifyCollectionOtherSelector);

        forEach(spotifyCollectionOtherItemAll, (index, value) => {
          const spotifyPl = {};
          const plName = $(value)
            .find('.playlist-name input')
            .val();
          const plUri = $(value)
            .find('.playlist-uri input')
            .val();
          $(value)
            .find('.playlist-name input')
            .attr('name', `spotify_existing_other_playlist[${index}][name]`);
          $(value)
            .find('.playlist-uri input')
            .attr('name', `spotify_existing_other_playlist[${index}][uri]`);
          spotifyPl.name = plName;
          spotifyPl.uri = plUri;
          spotifyCollectionOther[index] = spotifyPl;
        });
      } else {
        spotifyCollectionOther = 'empty';
      }

      if ($('#amazon-container .amazon-collection tbody tr').length) {
        amazonCollection = {};
        const amazonCollectionItemAll = document.querySelectorAll(
          '#amazon-container .amazon-collection tbody tr'
        );
        forEach(amazonCollectionItemAll, (index, value) => {
          const amazonCPl = {};
          const plNameC = $(value)
            .find('.playlist-name input')
            .val();
          const plUriC = $(value)
            .find('.playlist-uri input')
            .val();
          $(value)
            .find('.playlist-name input')
            .attr('name', `amazon_existing_playlist[${index}][name]`);
          $(value)
            .find('.playlist-uri input')
            .attr('name', `amazon_existing_playlist[${index}][uri]`);
          amazonCPl.name = plNameC;
          amazonCPl.uri = plUriC;
          amazonCollection[index] = amazonCPl;
        });
      } else {
        amazonCollection = 'empty';
      }

      if ($('#amazon-container .amazon-artist-collection tbody tr').length) {
        amazonArtistCollection = {};
        const amazonArtistCollectionItemAll = document.querySelectorAll(
          '#amazon-container .amazon-artist-collection tbody tr'
        );
        forEach(amazonArtistCollectionItemAll, (index, value) => {
          const amazonArtist = {};
          const artistName = $(value)
            .find('.artist-name input')
            .val();
          const artistUri = $(value)
            .find('.artist-uri input')
            .val();
          $(value)
            .find('.artist-name input')
            .attr('name', `artist_name[${index}]`);
          $(value)
            .find('.artist-uri input')
            .attr('name', `artist_uri[${index}]`);
          amazonArtist.name = artistName;
          amazonArtist.url = artistUri;
          amazonArtistCollection[index] = amazonArtist;
        });
      } else {
        amazonArtistCollection = 'empty';
      }

      if ($('#amazon-container .amazon-collection-other tbody tr').length) {
        let amazonCollectionOtherSelector = '.amazon-collection-other tbody tr';
        const $amazonPlMode = $("#campaign_amazon_playlist_mode");

        if ($amazonPlMode.length) {
          if ($amazonPlMode.val() == 'collective' ) {
            amazonCollectionOtherSelector = "#amazon-container " + amazonCollectionOtherSelector;
          } else {
            const optSettBlock = "#campaign_amazon_" + $amazonPlMode.val() + "_mode_options_settings ";
            amazonCollectionOtherSelector = optSettBlock + amazonCollectionOtherSelector;
          }
        } else {
          amazonCollectionOtherSelector = "#amazon-container " + amazonCollectionOtherSelector;
        }

        amazonCollectionOther = {};
        const amazonCollectionOtherItemAll = document.querySelectorAll(amazonCollectionOtherSelector);

        forEach(amazonCollectionOtherItemAll, (index, value) => {
          const amazonPl = {};
          const plName = $(value)
            .find('.playlist-name input')
            .val();
          const plUri = $(value)
            .find('.playlist-uri input')
            .val();
          $(value)
            .find('.playlist-name input')
            .attr('name', `amazon_existing_other_playlist[${index}][name]`);
          $(value)
            .find('.playlist-uri input')
            .attr('name', `amazon_existing_other_playlist[${index}][uri]`);
          amazonPl.name = plName;
          amazonPl.uri = plUri;
          amazonCollectionOther[index] = amazonPl;
        });
      } else {
        amazonCollectionOther = 'empty';
      }

      if ($('#deezer-container .deezer-collection tbody tr').length) {
        deezerCollection = {};
        const deezerCollectionItemAll = document.querySelectorAll(
          '#deezer-container .deezer-collection tbody tr'
        );
        forEach(deezerCollectionItemAll, (index, value) => {
          const deezerCPl = {};
          const plNameC = $(value)
            .find('.playlist-name input')
            .val();
          const plUriC = $(value)
            .find('.playlist-uri input')
            .val();
          $(value)
            .find('.playlist-name input')
            .attr('name', `deezer_existing_playlist[${index}][name]`);
          $(value)
            .find('.playlist-uri input')
            .attr('name', `deezer_existing_playlist[${index}][uri]`);
          deezerCPl.name = plNameC;
          deezerCPl.uri = plUriC;
          deezerCollection[index] = deezerCPl;
        });
      } else {
        deezerCollection = 'empty';
      }

      if ($('#deezer-container .deezer-collection-other tbody tr').length) {
        deezerCollectionOther = {};
        const deezerCollectionOtherItemAll = document.querySelectorAll(
          '#deezer-container .deezer-collection-other tbody tr'
        );
        forEach(deezerCollectionOtherItemAll, (index, value) => {
          const deezerPl = {};
          const plName = $(value)
            .find('.playlist-name input')
            .val();
          const plUri = $(value)
            .find('.playlist-uri input')
            .val();
          $(value)
            .find('.playlist-name input')
            .attr('name', `deezer_existing_other_playlist[${index}][name]`);
          $(value)
            .find('.playlist-uri input')
            .attr('name', `deezer_existing_other_playlist[${index}][uri]`);
          deezerPl.name = plName;
          deezerPl.uri = plUri;
          deezerCollectionOther[index] = deezerPl;
        });
      } else {
        deezerCollectionOther = 'empty';
      }

      if ($('#campaign-apple-library .apple-collection tbody tr').length) {
        appleLibraryAdd = {};
        const appleLibraryAddItemAll = document.querySelectorAll(
          '#campaign-apple-library .apple-collection tbody tr'
        );
        forEach(appleLibraryAddItemAll, (index, value) => {
          const appleListItem = {};
          const itemName = $(value)
            .find('.library-add-name')
            .val();
          const itemId = $(value)
            .find('.library-add-id')
            .val();
          appleListItem.name = itemName;
          appleListItem.id = itemId;
          appleLibraryAdd[index] = appleListItem;
        });
      } else {
        appleLibraryAdd = 'empty';
      }

      if ($('#campaign-apple-existing .apple-collection tbody tr').length) {
        appleLibraryFollow = {};
        const appleLibraryFollowItemAll = document.querySelectorAll(
          '#campaign-apple-existing .apple-collection tbody tr'
        );
        forEach(appleLibraryFollowItemAll, (index, value) => {
          const appleListItem = {};
          const itemName = $(value)
            .find('.existing-playlist-name')
            .val();
          const itemId = $(value)
            .find('.existing-playlist-id')
            .val();
          appleListItem.name = itemName;
          appleListItem.id = itemId;
          appleLibraryFollow[index] = appleListItem;
        });
      } else {
        appleLibraryFollow = 'empty';
      }

      if ($('#campaign-apple-custom .apple-collection tbody tr').length) {
        applePlaylist = {};
        const applePlaylistItemAll = document.querySelectorAll(
          '#campaign-apple-custom .apple-collection tbody tr'
        );
        forEach(applePlaylistItemAll, (index, value) => {
          const appleListItem = {};
          const itemName = $(value)
            .find('.custom-playlist-name')
            .val();
          const itemId = $(value)
            .find('.custom-playlist-id')
            .val();
          appleListItem.name = itemName;
          appleListItem.id = itemId;
          applePlaylist[index] = appleListItem;
        });
      } else {
        applePlaylist = 'empty';
      }

      if ($("#opt-ins-block").length) {
        mlCollections = getMlCollections();
        // console.log(mlCollections);
      }

      if ($('#campaign_spotify_custom_auto_playlist_name').length) {
        const value = $('#campaign_spotify_playlist_mode').val();
        const haveItems = $('#campaign_spotify_playlist_custom_settings #spotify-collection>li').length
        if (value === 'collective') {
          const plNameValue = $(
            '#campaign_spotify_custom_auto_playlist_name'
          ).val();
          if ( (plNameValue == null || plNameValue == '' ) && haveItems ) {
            M.toast({
              html: 'You need to enter a playlist name for Spotify',
              classes: 'error-toast'
            });
            return;
          }
        }
      }

      if ($('#campaign_amazon_custom_auto_playlist_name').length) {
        const value = $('#campaign_amazon_playlist_mode').val();
        const haveItems = $('#campaign_amazon_playlist_custom_settings #amazon-collection>li').length
        if (value === 'collective') {
          const plNameValue = $(
            '#campaign_amazon_custom_auto_playlist_name'
          ).val();
          if ( (plNameValue == null || plNameValue == '' ) && haveItems ) {
            M.toast({
              html: 'You need to enter a playlist name for Amazon',
              classes: 'error-toast'
            });
            return;
          }
        }
      }

      if ($('#campaign_deezer_custom_auto_playlist_name').length) {
        const value = $('#campaign_deezer_playlist_mode').val();
        if (value === 'custom') {
          const plNameValue = $(
            '#campaign_deezer_custom_auto_playlist_name'
          ).val();
          if (plNameValue == null || plNameValue == '') {
            M.toast({
              html: 'You need to enter a playlist name for Deezer',
              classes: 'error-toast'
            });
            return;
          }
        }
      }

      const valid = $('#campaign-integrations-step').valid();
      if (!valid) {
        M.toast({
          html: 'Please correct any errors to continue',
          classes: 'error-toast'
        });
        return;
      }

      data = $(this).serialize();
    }

    // Saving the forms page
    if (tabLink === 'form') {
      formCollection = {};

      $('.displayed-form-fields').each(function () {
        const $formFieldsContainer = $(this);
        const prefix = $formFieldsContainer.data('prefix');
        const $formCollectionAll = $formFieldsContainer.find('tbody tr');
        if (prefix === '') {
          formCollection = compilateFormCollection($formCollectionAll);
        } else {
          formCollection[prefix] = compilateFormCollection($formCollectionAll);
        }
      });
    }

    if (tabLink === 'quiz') {
      reindexQuestions();
      data = $(this).serialize();
    }

    if (tabLink === 'lib-scan') {
      reindexLibProfiles();
      data = $(this).serialize();
    }

    const hasExpirationDate = $('#campaign_expiration_mode').prop('checked');
    if (hasExpirationDate === true) {
      const dateValue = $('#expiration-date-value').val();
      const timeValue = $('#expiration-time-value').val();

      if (!dateValue.length || !timeValue.length) {
        M.toast({
          html: 'Expiration must have both date and time',
          classes: 'error-toast'
        });
        return;
      }
    }

    if (!ccpaValidation()) return false;

    const $presaveReturnUrl = $("#campaign_presave_return_url");
    if($presaveReturnUrl.length > 0 && $presaveReturnUrl.val().length > 0 && !isValidHttpUrl($presaveReturnUrl.val())) {
      M.toast({
        html: 'Expiration must be valid HTTP URL (http:// or https://)',
        classes: 'error-toast'
      });
      return false;
    }

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'campaign_creator_post_data',
        data,
        userid: wp.userid,
        youtube_collection: youtubeCollection,
        spotify_collection: spotifyCollection,
        spotify_artist_collection: spotifyArtistCollection,
        spotify_collection_other: spotifyCollectionOther,
        amazon_collection: amazonCollection,
        amazon_artist_collection: amazonArtistCollection,
        amazon_collection_other: amazonCollectionOther,
        deezer_collection: deezerCollection,
        deezer_collection_other: deezerCollectionOther,
        apple_library_add: appleLibraryAdd,
        apple_library_follow: appleLibraryFollow,
        apple_playlist: applePlaylist,
        ml_collections: mlCollections,
        form_collection: formCollection,
        geo_block: geoBlock,
        geo_allow: geoAllow
      },
      beforeSend() {
        startLoading();
      },
      error(error) {
        if (typeof error.responseJSON.api_message !== 'undefined') {
          errorToast(error.responseJSON.api_message);
        }

        errorToast('Error Saving');
      },
      success(response) {
        div.html(response);
        showToast('Saved Successfully');
        // checkEmptyPlaylists($('input[name=campaign_id]').val());
        // Fix timezones
        timeZoneFix();
      },
      complete() {
        stopLoading();
        initCampaigns();
        if ($('#campaign-design-step').length) {
          initDesign();
        }
      }
    });

  }); // END Saving Data

  function compilateFormCollection($formCollectionAll) {
    const formCollection = {};
    let formCountry;
    let formState;
    $formCollectionAll.each(function( index ) {
      const formField = {};
      const formFieldValue = $(this).data('formfield');
      if( formFieldValue === 'country' ){
        formCountry = formFieldValue;
      }
      if( formFieldValue === 'state' ){
        formState = formFieldValue;
      }
      const formFieldName = $(this)
        .find('#formfield-name')
        .text();
      const formFieldLabel = $(this)
        .find('#formfield-label input')
        .val();
      const formFieldValidation = $(this)
        .find('#formfield-validation input')
        .val();
      const formFieldPublic = $(this).data('public');
      const formFieldType = $(this).data('fieldtype');
      const formFieldReq = $(this)
        .find('select')
        .val();
      const formFieldOptions = $(this)
        .find('.options-value')
        .val();
      formField.value = formFieldValue;
      formField.name = formFieldName;
      formField.label = formFieldLabel;
      formField.validation = formFieldValidation;
      formField.req = formFieldReq;
      formField.public = formFieldPublic;
      formField.fieldtype = formFieldType;
      formField.options = formFieldOptions;
      formCollection[index] = formField;
    });
    if( typeof formCountry === 'undefined' && typeof formState !== 'undefined'){
      M.toast({
        html: 'Please add the Country field to the form if you are using State.',
        classes: 'error-toast'
      });
      return;
    }

    return formCollection;
  }

  /**
   * Validation CCPA fields - shouldn't be empty
   *
   * @return {boolean}
   */
  function ccpaValidation() {
    const $ccpaFields = $('input[id^=campaign_ccpa_]');
    let valid = true;
    if($ccpaFields.length > 0) {
      $ccpaFields.each(function () {
        if (!$(this).val().length) {
          const fieldName = $("label[for='" + $(this).attr('id') + "']").html();
          M.toast({
            html: `Field "${fieldName}" should not be empty`,
            classes: 'error-toast'
          });
          valid = false;
        }
      });
    }

    return valid;
  }

  /**
   * Validate HTTP URLs
   * @param string - url
   * @returns {boolean}
   */
  function isValidHttpUrl(string) {
    try {
      const newUrl = new URL(string);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }

  /**
   * Campaign Creator - Main
   *
   */
  if ($('#campaign_expiration_mode').length) {
    $('#campaign_expiration_mode').on('change', expirationChange);
    $('#set-expiration').on('click', expirationSet);
  }

  function expirationChange(e) {
    const { checked } = e.target;
    $('#campaign_expiration_mode_settings')
      .toggleClass('hide');

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_expiration_mode',
        value: checked
      },
      beforeSend() {},
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        const hasExpirationDate = $('#campaign_expiration_mode').prop('checked');
        if (hasExpirationDate === false) {
          $('#expiration-date-value').val('');
          $('#expiration-time-value').val('');
          $('#selected-expiration-date').text('');
          $('#selected-expiration-time').text('');
          $('#selected-expiration-tz').hide();
        }

        M.toast({
          html: 'Expiration Mode Updated',
          classes: 'success-toast'
        });
      },
      complete() {}
    });
  }

  function expirationSet() {
    const $dateInput = $('#expiration-date-value');
    const $timeInput = $('#expiration-time-value');
    const $selectedDate = $('#selected-expiration-date');
    const $selectedTime = $('#selected-expiration-time');
    const $timezone = $('#selected-expiration-tz');
    const curTz = $('#campaign_timezone')
    .find('option:selected')
    .val();

    if (!curTz) {
      M.toast({
        html: 'Timezone needs to set',
        classes: 'error-toast'
      });

      return false;
    }

    const $datepickerTimezone = $('<span />', {
      id: 'datepicker-timezone',
      html: $('#select2-campaign_timezone-container').text()
    });

    const selectedDateValue = $dateInput.val();
    const now = new Date();
    const today = selectedDateValue.length ? new Date(selectedDateValue) : now;

    const getTimezoneValue = date => {
      const $container = $('#datepicker-timezone');
      const offset = moment(date)
        .tz(curTz)
        .format('Z');

        const value = $container
        .text()
        .replace(/\(.*?\)/, '(UTC ' + offset + ')');

      $container.text(value);
    };

    const datepickerOptions = {
      format: 'mmm dd, yyyy',
      minDate: now,
      defaultDate: today,
      setDefaultDate: true,
      onOpen() {
        this.$modalEl
          .find('.datepicker-date-display')
          .append($datepickerTimezone);

        getTimezoneValue(today);
      },
      onSelect(date) {
        getTimezoneValue(date);
      }
    };

    const timepickerOptions = {
      twelveHour: true,
      onOpenStart() {
        this.$el
          .find('.timepicker-text-container')
          .append($datepickerTimezone);
      }
    };

    M.Datepicker.init($dateInput[0], datepickerOptions);
    M.Timepicker.init($timeInput[0], timepickerOptions);

    const datepickerInstance = M.Datepicker.getInstance($dateInput[0]);
    const timepickerInstance = M.Timepicker.getInstance($timeInput[0]);

    const setDate = function () {
      const value = datepickerInstance.toString();

      $dateInput.val(value);
      $selectedDate.text(value);
      $timezone.show();

      timepickerInstance.open();
    };

    const setTime = function () {
      let time = timepickerInstance.time;
      if (timepickerInstance.amOrPm !== undefined && timepickerInstance.amOrPm !== '') {
        time = time + ' ' + timepickerInstance.amOrPm;
      }
      $timeInput.val(time);
      $selectedTime.text(time);
    };


    $(datepickerInstance.doneBtn).on('click', setDate);
    $(timepickerInstance.footer).find('button').last().on('click', setTime);

    datepickerInstance.open();
  }

  const $campExpirationFanMode = $('#campaign_expiration_by_fan_mode');
  const $campExpirationFanModeBlock = $('#camp-expiration-by-fan-block');
  const $campExpirationFanModeNumber = $('#campaign_expiration_by_fan_number');
  if ($campExpirationFanMode.length) {
    $campExpirationFanMode.on('change', expirationFanChange);
  }

  function expirationFanChange(e) {
    const { checked } = e.target;
    $campExpirationFanModeBlock.toggleClass('hide');

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_expiration_fan_mode',
        value: checked
      },
      beforeSend() {},
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        if (checked === false) {
          $campExpirationFanModeNumber.val('').change();
        }

        M.toast({
          html: 'Expiration Mode Updated',
          classes: 'success-toast'
        });
      },
      complete() {}
    });
  }

  $('body').on('click', '#campaign_geo_status_allow', () => {
    $('#campaign-step-reload #geo_allow_container').removeClass('hide');
    $('#campaign-step-reload #geo_block_container').addClass('hide');
  });
  $('body').on('click', '#campaign_geo_status_block', () => {
    $('#campaign-step-reload #geo_allow_container').addClass('hide');
    $('#campaign-step-reload #geo_block_container').removeClass('hide');
  });

  $('textarea#campaign_copyright_text').froalaEditor({
    key: wp.froala_api,
    toolbarButtons: [
      'bold',
      'italic',
      'underline',
      'insertLink',
      'specialCharacters',
      'selectAll',
      'clearFormatting',
      'html'
    ],
    toolbarBottom: true,
    spellcheck: false
  });

  $('textarea.ppa-checkbox-text').froalaEditor({
    key: wp.froala_api,
    toolbarButtons: [
      'bold',
      'italic',
      'underline',
      'insertLink',
      'specialCharacters',
      'selectAll',
      'clearFormatting',
      'html'
    ],
    toolbarBottom: true,
    spellcheck: false
  });

  $('textarea#campaign_expiration_message').froalaEditor({
    key: wp.froala_api,
    toolbarButtons: [
      'bold',
      'italic',
      'underline',
      'insertLink',
      'specialCharacters',
      'selectAll',
      'clearFormatting',
      'html'
    ],
    toolbarBottom: true,
    spellcheck: false
  });

  // Logic for turning off and on 3rd party integrations
  // and toggling the selector for presave form and
  // return URL for self hosted.
  $('.integration-mode-selector')
    .off()
    .on('click', function() {
      console.log('integration-mode-selector clicked');
      const field = $(this).data('field');
      const campType = $('.camp-type-badge').data('campaign-type');
      const currentValue = $(`#${field}`).val();
      console.log(campType);
      if (currentValue === 'true') {
        $(`#${field}`).val('false');
      } else {
        $(`#${field}`).val('true');
      }
      if("soc_sign_form_combo" === campType) {
        console.log($('.integration-mode-selector:checked').length);
        if($('.integration-mode-selector:checked').length === 0) {
          $("#campaign_spotify_mode_selector").prop('checked', true);
          $(`#campaign_spotify_mode`).val('true');
        }
      } else {
        let presave = false;
        const spotify = $('#campaign_spotify_mode').val();
        const amazon = $('#campaign_amazon_mode').val();
        const deezer = $('#campaign_deezer_mode').val();
        const apple = $('#campaign_apple_mode').val();
        const youtube = $('#campaign_youtube_mode').val();
        if (spotify === 'true') {
          presave = true;
        }
        if (amazon === 'true') {
          presave = true;
        }
        if (deezer === 'true') {
          presave = true;
        }
        if (apple === 'true') {
          presave = true;
        }
        if (presave === true) {
          $('#presave-form').removeClass('hide');
          $('#presave-return-url').removeClass('hide');
        } else {
          $('#presave-form').addClass('hide');
          $('#presave-return-url').addClass('hide');
        }
      }
    });

  $('.ppa-enable-checkbox').on('change', function () {
    const $termsCheckbox = $(this).parents('.ppa-block').find('.terms-checkbox');
    if ($termsCheckbox.hasClass('hide')) {
      $termsCheckbox.removeClass('hide');
    } else {
      $termsCheckbox.addClass('hide');
    }
  });

  /**
   * Campaign Creator - Integrations
   *
   */
  if ($('#campaign-integrations-step').length) {
    console.log("app.js init integration step ");
    initIntegrationStep();
  }
  /**
   * Campaign Creator - Form Fields
   *
   */
  if ($('#campaign-form-step').length) {
    console.log("app.js init form step ");
    if ($("#ss-form-combo-switches").length) {
      $('.ss-form-combo-selector')
        .off()
        .on('click', function () {
          const field = $(this).data('field');
          const fieldEl = $(`#${field}`);
          const currentValue = fieldEl.val();
          if (currentValue === 'true') {
            fieldEl.val('false');
          } else {
            fieldEl.val('true');
          }
          $('#dashboard-reload .campaign-creator-form').submit();
        });

      $('#campaign-step-reload .form-tab-header')
        .off()
        .on('click', function () {
          $('#campaign-step-reload .form-tab-header').removeClass('active');
          $(this).addClass('active');
          const showID = $(this).data('show');
          $('#campaign-step-reload .form-tab-container').removeClass(
            'active'
          );
          const $activeTabContainer = $(`#campaign-step-reload #${showID}`);
          $activeTabContainer.addClass('active');
          $(".fr-toolbar").hide();// hide toolbars of rich text editors (SMF-503)
          $activeTabContainer.find(".fr-toolbar").show();// show toolbars of rich text editors for active tab (SMF-503)

        });

      hideFormFieldsForComboFrom();
    }
  }

  function hideFormFieldsForComboFrom() {
    const $landingFromCont = $("#landing-form-container");
    const $secondaryFormCont = $("#secondary-form-container");
    const $checkedLFFields = $landingFromCont.find('input[name="avail_fields[]"]');
    const $checkedSFFields = $secondaryFormCont.find('input[name="avail_fields[]"]');

    $checkedLFFields.each(function(e) {
      const $elemForHide = $secondaryFormCont.find(`input[value="${$(this).val()}"]`);
      if ($elemForHide.length === 1) {
        if ($(this).is(":checked")) {
          $elemForHide.parents("p").hide();
        } else {
          $elemForHide.parents("p").show();
        }
      }
    });

    $checkedSFFields.each(function() {
      const $elemForHide = $landingFromCont.find(`input[value="${$( this ).val()}"]`);
      if ($elemForHide.length === 1) {
        if ($(this).is(":checked")) {
          $elemForHide.parents("p").hide();
        } else {
          $elemForHide.parents("p").show();
        }
      }
    });
  }


  $('#dashboard-reload input[name=campaign_default_mailinglist_optin]')
    .off()
    .on('change', () => {
      if (
        $('#campaign_default_mailinglist_optin_hide_no_opt[type=radio]').prop(
          'checked'
        )
      ) {
        $('#campaign_default_mailinglist_required').val('false');
        $('#default-mailing-list').addClass('hide');
        $('#default-mailing-list-req').addClass('hide');
        $('#default-mailing-list-text').addClass('hide');
        $('#default-mailing-list-conf').addClass('hide');
      }
      if (
        $('#campaign_default_mailinglist_optin_hide_opt[type=radio]').prop(
          'checked'
        )
      ) {
        $('#campaign_default_mailinglist_required').val('false');
        $('#default-mailing-list').removeClass('hide');
        $('#default-mailing-list-req').addClass('hide');
        $('#default-mailing-list-text').addClass('hide');
        $('#default-mailing-list-conf').removeClass('hide');
      }
      if (
        $('#campaign_default_mailinglist_optin_show_chk[type=radio]').prop(
          'checked'
        )
      ) {
        $('#default-mailing-list').removeClass('hide');
        $('#default-mailing-list-req').removeClass('hide');
        $('#default-mailing-list-text').removeClass('hide');
        $('#default-mailing-list-conf').removeClass('hide');
      }
      if (
        $('#campaign_default_mailinglist_optin_show_unchk[type=radio]').prop(
          'checked'
        )
      ) {
        $('#default-mailing-list').removeClass('hide');
        $('#default-mailing-list-req').removeClass('hide');
        $('#default-mailing-list-text').removeClass('hide');
        $('#default-mailing-list-conf').removeClass('hide');
      }
    });

  $('#ml-add-additional').on('select2:select', e => {
    const sfId = e.params.data.id;
    const name = e.params.data.text;
    const artist = e.params.data.element.attributes[1].value;
    let confEmails;
    const html = `
		<td class="drag-handle"><i class="material-icons">drag_handle</i></td><td><span class="title">${name}</span><p><input id="mailing-list-name" type="text" class="browser-default" value="I'd like to subscribe to the ${artist} newsletter" style="width:80%"></p><p style="margin-top:5px"><select id="mailing-list-required"><option value="required">Required</option><option value="not_required" selected="selected">Not Required</option></select><select id="mailing-list-optin"><option value="hide_opt">Hide Checkbox (Opt-In On Form Submit)</option><option value="show_chk" selected="selected">Show Checkbox - Checked</option><option value="show_unchk">Show Checkbox - Unchecked</option></select></p><p style="margin-top:5px;font-weight:900">Welcome / Confirmation Email</p><p><select id="mailing-list-conf" style="width:80%"><option value="" selected="selected">Select Your Confirmation Email</option></select></p></td><td class="remove-field"><i class="material-icons">delete_forever</i></td>
		`;
    const container = document.querySelector('#additional-mailing-lists tbody');
    const d = document.createElement('tr');
    d.setAttribute('data-sfid', sfId);
    d.setAttribute('data-req', 'true');
    d.setAttribute('data-optin', 'show_chk');
    d.setAttribute('data-name', name);
    d.setAttribute('data-text', `Subscribe to the ${name} mailing list`);
    d.innerHTML = html;
    container.appendChild(d);
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'artist_confirmation_emails'
      },
      success(data) {
        confEmails = data.data;
        let email;
        // eslint-disable-next-line guard-for-in
        confEmails.forEach(element => {
          email = element;
          const selectContainer = document.querySelector(
            `#additional-mailing-lists tbody tr[data-sfid=${sfId}] #mailing-list-conf`
          );
          const o = document.createElement('option');
          o.setAttribute('value', email.Triggered_Send_Definition_Key);
          o.innerHTML = email.Triggered_Send_Definition_Name;
          selectContainer.appendChild(o);
        });
      }
    });
    M.toast({
      html: 'Mailing List Added',
      classes: 'success-toast'
    });
    removeFieldInit();
  });

  $('#campaign_minimum_optins').on('select2:select', e => {
    const value = e.params.data.id;
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_minimum_optins',
        value
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
  });

  $('#campaign_default_mailinglist_conf').on('select2:select', e => {
    const value = e.params.data.id;
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'default_confirmation_email',
        value
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
  });




  const today = new Date();

  // Add Presave Custom Playlist Tasks
  $('#campaign-step-reload .add-custom-playlist-task')
    .off()
    .on('click', function(e) {
      e.preventDefault();
      let taskDate;
      const campaign = $(this).data('campaignid');
      const provider = $(this).data('provider');
      let saveType;
      if ( provider == 'spotify' || provider == 'amazon' ) {
        switch ( $(this).parents('.designer-tab').attr('id') ) {
          case 'custom-tab':
            saveType = 'custom';
            break;
          case 'add-tracks-tab':
            saveType = 'library';
            break;
        }
      }
      const datePicker = document.querySelector(
        '#integrations-tabs-container .add-custom-task-datepicker'
      );
      const instance = M.Datepicker.init(datePicker, {
        minDate: today,
        onSelect(date) {
          taskDate = moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), $(datePicker).data('tz'));
        },
        onClose() {
          instance.destroy();
          addCustomPlaylistTask(taskDate, campaign, provider, saveType);
        }
      });
      instance.open();
    });

  function addCustomPlaylistTask(dateObject, campaign, provider, saveType = false) {
    let div;
    if (provider == 'spotify'  || provider == 'amazon') {
      if ( saveType === 'custom' ) {
        div = $(`#custom-tab .${provider}-tasks-reload`);
      } else {
        div = $(`#add-tracks-tab .${provider}-tasks-reload`);
      }
    } else {
      div = $(`.${provider}-tasks-reload`);
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'create_task',
        date: dateObject.format(),
        provider,
        campaign,
        savetype: saveType
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        div.html(data);
        M.toast({
          html: 'Presave Task Added',
          classes: 'success-toast'
        });
      }
    });
  }

  $(".tasks-reload-block")
    .off()
    .on('click', '.edit-task', function(e) {
      const provider = $(this).data('provider');
      const postID = $(this).data('postid');
      const editType = $(this).data('task');
      const taskIndex = $(this).data('index');
      let dateTime = $(this).data('datetime');
      let saveType;
      if ( provider == 'spotify' || provider == 'amazon' ) {
        switch ( $(this).parents('.designer-tab').attr('id') ) {
          case 'custom-tab':
            saveType = 'custom';
            break;
          case 'add-tracks-tab':
            saveType = 'library';
            break;
        }
      }

      const datePicker = document.querySelector(
        '#integrations-tabs-container .add-custom-task-datepicker'
      );
      const timePicker = document.querySelector(
        '#integrations-tabs-container .add-custom-task-timepicker'
      );
      const tracksModal = document.querySelector(
        '#integrations-tabs-container #modal-playlist-add-task'
      );
      let modalState;
      let dateInstance;
      let timeInstance;
      let tracksInstance;
      let taskObj;

      if (editType === 'date') {
        dateInstance = M.Datepicker.init(datePicker, {
          minDate: today,
          onSelect(date) {
            taskObj = moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), $(datePicker).data('tz'));
          },
          onClose() {
            dateInstance.destroy();
            if (typeof taskObj !== 'undefined') {
              const hours = moment(dateTime).tz($(datePicker).data('tz')).hour();
              const minutes = moment(dateTime).tz($(datePicker).data('tz')).minute();
              taskObj.hour(hours);
              taskObj.minute(minutes);
              editCustomPlaylistTask(
                taskObj,
                provider,
                editType,
                taskIndex,
                postID,
                saveType
              );
            }
          }
        });
        dateInstance.open();
      } else if (editType === 'time') {
        timeInstance = M.Timepicker.init(timePicker, {
          twelveHour: true
        });
        timeInstance.open();
        $('#integrations-tabs-container .add-custom-task-timepicker')
          .off()
          .on('change', function(e) {
            taskObj = e.target.value;
            const time24Hour = convertAmPmTo24Hour(taskObj) ;
            const timeObj = time24Hour.split(':');

            timeInstance.destroy();
            dateTime = moment(dateTime).tz($(this).data('tz'));
            dateTime.hour(parseInt(timeObj[0]));
            dateTime.minute(parseInt(timeObj[1]));

            editCustomPlaylistTask(
              dateTime,
              provider,
              editType,
              taskIndex,
              postID,
              saveType
            );
          });
      } else if (editType === 'delete' || editType === 'clone') {
        taskObj = false;
        editCustomPlaylistTask(taskObj, provider, editType, taskIndex, postID, saveType);
      } else if (editType === 'tracks') {
        tracksInstance = M.Modal.init(tracksModal, {
          opacity: '0.8',
          onOpenStart() {
            modalState = 'open';
            editCustomPlaylistTracks(provider, modalState, taskIndex, postID);
          },
          onCloseEnd() {
            modalState = 'closed';
            editCustomPlaylistTracks(provider, modalState, taskIndex, postID);
            tracksInstance.destroy();
          }
        });
        tracksInstance.open();
      }
    });

  $reloadWrap
    .off()
    .on('click', '.edit-sls-profile-tracks', function(e) {
      const postId = $(this).data('postid');
      const profileNum = $(this).data('profile-num');
      const tracksModal = document.querySelector('#modal-playlist-add-task');

      const tracksInstance = M.Modal.init(tracksModal, {
        opacity: '0.8',
        onOpenStart() {
          editSlsProfileTracks('open', profileNum, postId)
        },
        onCloseEnd() {
          editSlsProfileTracks('closed', profileNum, postId);
          tracksInstance.destroy();
        }
      });
      tracksInstance.open();
    });

  function editSlsProfileTracks(modalState, profileNum, postId) {
    const div = $('#modal-playlist-add-task-refresh');
    let saveTracksCollection = false;

    if (modalState === 'closed') {
      const $trackLines = $('#modal-playlist-add-task tbody tr');
      const tracksAddUpSel = $(`#${postId}_${profileNum} .tracks-added`);
      saveTracksCollection = getTracksCollection($trackLines);
      showTrackInfoLine(tracksAddUpSel, Object.keys(saveTracksCollection).length);
    }

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        modal_state: modalState,
        action: 'edit_sls_profile_tracks',
        profile_num: profileNum,
        post_id: postId,
        tracks: saveTracksCollection
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        if (modalState === 'open') {
          div.html(data);
          removeFieldInit();
        } else {
          div.html('');
          M.toast({
            html: 'Presave Tracks Updated',
            classes: 'success-toast'
          });
        }
      }
    });
  }

  function getTracksCollection($trackLines) {
    const saveTracksCollection = {};

    if ($trackLines.length) {
      $trackLines.each(function (index, elem) {
        saveTracksCollection[index] = {
          name: $(elem).find('.task-name-field').data('value'),
          link: $(elem).find('.task-link-field').data('value')
        };
      });
    }

    return saveTracksCollection;
  }

  function showTrackInfoLine($infoLine, count) {
    let text;
    if (count === 1) {
      text = 'Item';
    } else {
      text = 'Items';
    }
    $infoLine.html(`${count} ${text} Added`);
  }

  function editCustomPlaylistTask(
    taskObj,
    provider,
    editType,
    taskIndex,
    postID,
    saveType = false
  ) {
    let div;
    if (provider == 'spotify' || provider == 'amazon') {
      if ( saveType == 'custom' ) {
        div = $(`#custom-tab .${provider}-tasks-reload`);
      } else {
        div = $(`#add-tracks-tab .${provider}-tasks-reload`);
      }
    } else {
      div = $(`.${provider}-tasks-reload`);
    }
    let obj;
    if (editType === 'date' || editType === 'time') {
      obj = taskObj.format();
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'edit_task',
        task_data: obj,
        provider,
        edit_type: editType,
        task_index: taskIndex,
        post_id: postID,
        savetype: saveType
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        div.html(data);
        M.toast({
          html: 'Presave Task Updated',
          classes: 'success-toast'
        });
      }
    });
  }

  function editCustomPlaylistTracks(provider, modalState, taskIndex, postID) {
    const div = $('#modal-playlist-add-task-refresh');
    let saveTracksCollection = false;
    let tracksAddUpSel = `#${postID}_${taskIndex} .tracks-added`;

    const plMode = $(`#campaign_${provider}_playlist_mode`).val();
    if (plMode === 'library') {
      tracksAddUpSel = `#campaign_${provider}_library_settings #${postID}_${taskIndex} #tracks-added-update`;
    }

    $(tracksAddUpSel).html('0 Items Added');

    if (modalState === 'closed') {
      const $trackLines = $('#modal-playlist-add-task tbody tr');
      saveTracksCollection = getTracksCollection($trackLines);
      showTrackInfoLine($(tracksAddUpSel), Object.keys(saveTracksCollection).length);
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'edit_tracks',
        provider,
        modal_state: modalState,
        task_index: taskIndex,
        post_id: postID,
        tracks: saveTracksCollection
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        if (modalState === 'open') {
          div.html(data);
        } else {
          div.html('');
          M.toast({
            html: 'Presave Tracks Updated',
            classes: 'success-toast'
          });
        }
      }
    });
  }

  $('#modal-playlist-add-task')
    .off()
    .on('click', '#add-new-task', function() {
      const provider = $(this).data('provider');
      const name = $('#modal-playlist-add-task #add-new-task-name').val();
      const uri = $('#modal-playlist-add-task #add-new-task-link').val();
      let result;
      let text;

      switch (provider) {
        case 'spotify':
          result = validateSingleSpotify(uri, ($("#campaign-sls-step").length === 1));
          text = 'Please enter a valid Spotify URI';
          break;
        case 'amazon':
          result = validateSingleAmazon(uri, ($("#campaign-sls-step").length === 1));
          text = 'Please enter a valid Amazon URL';
          break;
        case 'deezer':
          result = validateSingleDeezer(uri);
          text = 'Please enter a valid Deezer link';
          break;
        case 'apple':
        default:
          result = validateSingleApple(uri);
          text = 'Please enter a valid Apple link';
          break;
      }

      if (!result) {
        M.toast({
          html: text,
          classes: 'error-toast'
        });
        return;
      }
      const element = `
			<tr>
				<td class="drag-handle">
					<i class="material-icons">drag_handle</i>
				</td>
        <td class="formfield-label edit-entry">
          <input type="text" class="browser-default task-name-field" data-value="${name}" style="width:95%" readonly value="${name}">
				</td>
        <td class="formfield-label edit-entry">
          <input type="text" class="browser-default task-link-field" data-value="${uri}" style="width:95%" readonly value="${uri}">
				</td>
				<td class="remove-field">
					<i class="material-icons">delete_forever</i>
				</td>
			</tr>`;
      if (name.length && uri.length) {
        $('#add-new-task-name').val('');
        $('#add-new-task-link').val('');
        $('#add-new-task-name').removeClass('valid');
        $('#add-new-task-link').removeClass('valid');
        M.updateTextFields();
        $('#modal-playlist-add-task .sortable').append(element);
      }
      removeFieldInit();
    });

  function validateSingleSpotify(value, sls = false) {
    const slsPattern = /^spotify:(artist|track|album|playlist):\b\w+$/i;
    const defPattern = /^spotify:(track|album):\b\w+$/i;
    const pattern = (sls) ? slsPattern : defPattern;

    return pattern.test(value);
  }

  function validateSingleAmazon(value, sls = false) {
    return /^https:\/\/music.amazon.com\/albums\/.*$/i.test(value) || /^https:\/\/music.amazon.com\/tracks\/.*$/i.test(value);
  }

  function validateSingleDeezer(value) {
    return /^(http|https):\/\/www.deezer.com.*\/(track|album)\/\d+\/?$/i.test(
      value
    );
  }

  function validateSingleApple(value) {
    return /^\d{8,12}$/g.test(
      value
    );
  }

  $('#spotify-custom-mode').on('select2:select', e => {
    const { data } = e.params;
    const value = data.id;
    if (value === 'auto') {
      $('#dashboard-reload #spotify-custom-options').addClass('hide');
      $('#dashboard-reload #spotify-artist-autofollow-selector').removeClass(
        'hide'
      );
    } else {
      $('#dashboard-reload #spotify-custom-options').removeClass('hide');
      $('#dashboard-reload #spotify-artist-autofollow-selector').addClass(
        'hide'
      );
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_presave_state',
        value,
        provider: 'spotify'
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
  });

  $('#spotify-library-mode').on('select2:select', e => {
    const { data } = e.params;
    const value = data.id;
    if (value === 'auto') {
      $('#dashboard-reload #spotify-library-options').addClass('hide');
      $(
        '#dashboard-reload #campaign_spotify_library_mode_options_settings'
      ).addClass('hide');
    } else {
      $('#dashboard-reload #spotify-library-options').removeClass('hide');
      $(
        '#dashboard-reload #campaign_spotify_library_mode_options_settings'
      ).removeClass('hide');
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_presave_library_state',
        value,
        provider: 'spotify'
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
  });

  // $('#campaign_spotify_playlist_mode').select2();
  $('#campaign_spotify_playlist_mode').on('select2:select', e => {
    const value = e.params.data.id;
    if (value === 'custom') {
      $('#campaign_spotify_playlist_existing_settings').addClass('hide');
      $('#campaign_spotify_library_mode_options_settings').addClass('hide');
      $('#campaign_spotify_playlist_custom_settings').removeClass('hide');
      const mode = $('#spotify-custom-mode').select2('data');
    } else if (value === 'existing') {
      $('#campaign_spotify_playlist_existing_settings').removeClass('hide');
      $('#campaign_spotify_library_mode_options_settings').addClass('hide');
      $('#campaign_spotify_playlist_custom_settings').addClass('hide');
    } else if (value === 'library') {
      $('#campaign_spotify_playlist_existing_settings').addClass('hide');
      $('#campaign_spotify_library_mode_options_settings').addClass('hide');
      $('#campaign_spotify_playlist_custom_settings').addClass('hide');
      const mode = $('#spotify-library-mode').select2('data');
      if (mode[0].id === 'options') {
        $('#campaign_spotify_library_mode_options_settings').removeClass('hide');
      }
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_playlist_mode',
        value,
        provider: 'spotify'
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
    eventAddExistingPl();
  });

  $('#spotify-follow-artist-options').on('select2:select', e => {
    const { data } = e.params;
    const value = data.id;
    if (value === 'autofollow') {
      $('#dashboard-reload #spotify-follow-text').addClass('hide');
    } else {
      $('#dashboard-reload #spotify-follow-text').removeClass('hide');
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_follow_options',
        value,
        provider: 'spotify'
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
    eventAddExistingPl();
  });

  $('#amazon-follow-artist-options').on('select2:select', e => {
    const { data } = e.params;
    const value = data.id;
    if (value === 'autofollow') {
      $('#dashboard-reload #amazon-follow-text').addClass('hide');
    } else {
      $('#dashboard-reload #amazon-follow-text').removeClass('hide');
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_follow_options',
        value,
        provider: 'amazon'
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
    eventAddExistingPl();
  });

  $('#deezer-custom-mode').on('select2:select', e => {
    const { data } = e.params;
    const value = data.id;
    if (value === 'auto') {
      $('#dashboard-reload #deezer-custom-options').addClass('hide');
      $(
        '#dashboard-reload #campaign_deezer_custom_mode_options_settings'
      ).addClass('hide');
      $('#dashboard-reload #deezer-artist-autofollow-selector').removeClass(
        'hide'
      );
    } else {
      $('#dashboard-reload #deezer-custom-options').removeClass('hide');
      $(
        '#dashboard-reload #campaign_deezer_custom_mode_options_settings'
      ).removeClass('hide');
      $('#dashboard-reload #deezer-artist-autofollow-selector').addClass(
        'hide'
      );
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_presave_state',
        value,
        provider: 'deezer'
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
  });

  // $('#campaign_deezer_playlist_mode').select2();
  $('#campaign_deezer_playlist_mode').on('select2:select', e => {
    const value = e.params.data.id;
    if (value === 'custom') {
      $('#campaign_deezer_playlist_existing_settings').addClass('hide');
      $('#campaign_deezer_custom_mode_options_settings').addClass('hide');
      $('#campaign_deezer_playlist_custom_settings').removeClass('hide');
      const mode = $('#deezer-custom-mode').select2('data');
      if (mode[0].id === 'options') {
        $('#campaign_deezer_custom_mode_options_settings').removeClass('hide');
      }
    } else if (value === 'existing') {
      $('#campaign_deezer_playlist_existing_settings').removeClass('hide');
      $('#campaign_deezer_custom_mode_options_settings').addClass('hide');
      $('#campaign_deezer_playlist_custom_settings').addClass('hide');
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_playlist_mode',
        value,
        provider: 'deezer'
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
    eventAddExistingPl();
  });

  $('#deezer-follow-artist-options').on('select2:select', e => {
    const { data } = e.params;
    const value = data.id;
    if (value === 'autofollow') {
      $('#dashboard-reload #deezer-follow-text').addClass('hide');
    } else {
      $('#dashboard-reload #deezer-follow-text').removeClass('hide');
    }
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'save_follow_options',
        value,
        provider: 'deezer'
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success() {
        M.toast({
          html: 'Saved',
          classes: 'success-toast'
        });
      }
    });
    eventAddExistingPl();
  });
  // END Deezer integration.

  $('#dashboard-reload .apple-add')
    .off()
    .on('click', function() {
      const nameField = $(this).data('name');
      const idField = $(this).data('id');
      const nameVal = $(`#${nameField}`).val();
      const idVal = $(`#${idField}`).val();
      const regExp = ("apple-add-playlist-name-existing" === nameField) ? /^pl\.[\w\d]{16,36}$/ : /^[\d]+$/;

      if (empty(nameVal) || empty(idVal) || !regExp.test(idVal)) {
        errorToast('Enter a valid name and ID');
        return;
      }
      const appleSection = $(this).data('section');
      const appleTableBody = $(`#${appleSection} .apple-collection tbody`);
      const fieldPrefix = $(this).data('prefix');
      const htmlElem = $(
        `<tr><td class="drag-handle"><i class="material-icons">drag_handle</i></td><td class="edit-entry"><input type="text" class="browser-default ${fieldPrefix}-name" value="${nameVal}"></td><td class="edit-entry"><input type="text" class="browser-default ${fieldPrefix}-id" value="${idVal}"></td><td class="remove-field"><i class="material-icons">delete_forever</i></td></tr>`
      );
      appleTableBody.append(htmlElem);
      $(`#${nameField}`).val('');
      $(`#${idField}`).val('');
      M.updateTextFields();
      removeFieldInit();
    });

  function initPlaylists() {
    setTimeout(() => {
      $('.playlists-sortable').sortable({
        axis: 'y',
        containment: 'parent',
        distance: 10,
        tolerance: 'pointer',
        helper(e, row) {
          row.children().each(function() {
            $(this).width($(this).width());
          });
          return row;
        },
        start(e, ui) {
          const sort = $(this).sortable('instance');
          sort.containment[3] += ui.helper.height();
        },
        stop(e, ui) {
          if (
            ui.item[0].className.indexOf(
              'spotify-playlist-generator-playlists-wrap'
            ) !== -1
          ) {
            const oldIndex = ui.item[0].dataset.index;
            const newIndex = $(ui.item[0]).index();
            reindexPlaylists(
              ui.item[0].dataset.postid,
              'reorder',
              oldIndex,
              newIndex
            );
          }
        }
      });
    }, 1000);

    // Playlist Generator
    $('.add-playlist-generator-playlist')
      .off()
      .on('click', function(e) {
        e.preventDefault();
        const playlists = $(
          '#spotify-playlist-generator-playlists-collection > li'
        ).length;
        if (playlists < 10) {
          const postId = $(this).data('campaignid');
          reindexPlaylists(postId, 'new', false, false);
        } else {
          M.toast({
            html: '10 playlists is the maximum',
            classes: 'error-toast'
          });
        }
      });

    $('#dashboard-reload')
      .off()
      .on('click', '.clone-playlist', function(e) {
        e.preventDefault();
        const playlists = $(
          '#spotify-playlist-generator-playlists-collection > li'
        ).length;
        if (playlists < 4) {
          // clone
          const postId = $(this).data('postid');
          const index = $(this).data('index');
          reindexPlaylists(postId, 'clone', index, false);
        } else {
          M.toast({
            html: '4 playlists is the maximum',
            classes: 'error-toast'
          });
        }
      });

    $('.spotify-playlist-generator-playlists-reload')
      .off()
      .on('click', '.delete-playlist', function(e) {
        e.preventDefault();
        const playlists = $(
          '#spotify-playlist-generator-playlists-collection > li'
        ).length;
        if (playlists > 2) {
          const postId = $(this).data('postid');
          const index = $(this).data('index');
          reindexPlaylists(postId, 'delete', index, false);
        } else {
          M.toast({
            html: '2 playlists is the minimum',
            classes: 'error-toast'
          });
        }
      })
      .on('change', '.playlist-generator-mode', function() {
        const parent = $(this).closest('li');
        const val = $(this).val();

        if (val === 'existing') {
          parent.find('#tracks-added-update').hide();
          parent.find('.playlist-generator-playlist-existing-uri-wrap').show();
          parent.find('.edit-playlist-tracks').hide();
        } else {
          parent.find('#tracks-added-update').show();
          parent.find('.playlist-generator-playlist-existing-uri-wrap').hide();
          parent.find('.edit-playlist-tracks').show();
        }
      });
  }

  initPlaylists();

  function reindexPlaylists(campaignId, action, index, newIndex) {
    const $reload = $('.spotify-playlist-generator-playlists-reload');
    if (!$reload.length) {
      return;
    }
    const titles = [];
    const modes = [];
    const uris = [];
    $('#spotify-playlist-generator-playlists-collection>li').each(function() {
      const title = $(this)
        .find('.playlist-generator-playlist-name')
        .val();
      const mode = $(this)
        .find('.playlist-generator-mode')
        .val();
      const uri = $(this)
        .find('.playlist-generator-playlist-existing-uri-wrap input')
        .val();
      titles.push(title);
      modes.push(mode);
      uris.push(uri);
    });
    // replace array indexes
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'reindex_playlists',
        provider: 'spotify',
        post_id: campaignId,
        playlist_action: action,
        index,
        new_index: newIndex,
        titles,
        modes,
        uris
      },
      error() {
        M.toast({
          html: 'Error',
          classes: 'error-toast'
        });
      },
      success(data) {
        $reload
          .empty()
          .append(data);
      }
    });
    initPlaylists();
  }

  $('#modal-playlist-generator-add-playlist')
    .off()
    .on('click', '#add-new-task', function() {
      const provider = 'spotify';
      const name = $(
        '#modal-playlist-generator-add-playlist #add-new-task-name'
      ).val();
      const uri = $(
        '#modal-playlist-generator-add-playlist #add-new-task-link'
      ).val();
      let result;
      let text;
      if (provider === 'spotify') {
        result = validateSingleSpotify(uri);
        text = 'Please enter a valid Spotify URI';
      } else if (provider === 'amazon') {
        result = validateSingleAmazon(uri);
        text = 'Please enter a valid Amazon URL';
      } else {
        result = validateSingleDeezer(uri);
        text = 'Please enter a valid Deezer link';
      }
      if (!result) {
        M.toast({
          html: text,
          classes: 'error-toast'
        });
        return;
      }
      const element = `
			<tr>
				<td class="drag-handle">
					<i class="material-icons">drag_handle</i>
				</td>
				<td class="formfield-label edit-entry">
					<span class="task-name-field" data-value="${name}" style="width:95%">${name}</span>
				</td>
				<td class="formfield-label edit-entry">
					<span class="task-link-field" data-value="${uri}" style="width:95%">${uri}</span>
				</td>
				<td class="remove-field">
					<i class="material-icons">delete_forever</i>
				</td>
			</tr>`;
      if (name.length && uri.length) {
        $('#modal-playlist-generator-add-playlist #add-new-task-name').val('');
        $('#modal-playlist-generator-add-playlist #add-new-task-link').val('');
        $(
          '#modal-playlist-generator-add-playlist #add-new-task-name'
        ).removeClass('valid');
        $(
          '#modal-playlist-generator-add-playlist #add-new-task-link'
        ).removeClass('valid');
        M.updateTextFields();
        $('#modal-playlist-generator-add-playlist .sortable').append(element);
      }
      removeFieldInit();
    });

  if ($('#modal-playlist-generator-add-playlist').length > 0) {
    $reloadWrap
      .off()
      .on('click', '.edit-playlist-tracks', function() {
        const provider = 'spotify';
        const postID = $(this).data('postid');
        const taskIndex = $(this).data('index');
        const plGenModal = document.querySelector(
          '#integrations-tabs-container #modal-playlist-generator-add-playlist'
        );
        let modalState;
        const plGenInstance = M.Modal.init(plGenModal, {
          opacity: '0.8',
          onOpenStart() {
            modalState = 'open';
            editPlaylistGenTracks(provider, modalState, taskIndex, postID);
          },
          onCloseEnd() {
            modalState = 'closed';
            editPlaylistGenTracks(provider, modalState, taskIndex, postID);
            plGenInstance.destroy();
          }
        });
        plGenInstance.open();
      });
  }

  function editPlaylistGenTracks(provider, modalState, taskIndex, postID) {
    $(`#${postID}_${taskIndex} #tracks-added-update`).html('0 Items Added');
    const div = $('#modal-playlist-generator-add-playlist-refresh');
    let savePlaylistCollection = {};

    if (modalState === 'closed') {
      const $trackLines = $('#modal-playlist-generator-add-playlist tbody tr');
      savePlaylistCollection = getTracksCollection($trackLines);
      showTrackInfoLine($(`#${postID}_${taskIndex} #tracks-added-update`), Object.keys(savePlaylistCollection).length);
    }

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'edit_playlists',
        provider,
        modal_state: modalState,
        task_index: taskIndex,
        post_id: postID,
        playlist: savePlaylistCollection
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        if (modalState === 'open') {
          div.html(data);
        } else {
          div.html('');
          checkEmptyPlaylists($('input[name=campaign_id]').val());
          M.toast({
            html: 'Playlists Updated',
            classes: 'success-toast'
          });
        }
      }
    });
  }

  /**
   * Campaign Creator - Social
   *
   */
  $('#campaign-step-reload .open-media-library').off();

  $('#campaign-step-reload')
    .off()
    .on('click', '.media-edit', function(e) {
      e.preventDefault();
      const imgid = $(this).data('imgid');
      const originalImage = $(`#${imgid}`);
      const originalImageSrc = $(`#${imgid}`).attr('src');
      const imgEditor = Pixie.setOptions({
        onSaveButtonClick() {
          imgEditor.save();
        },
        onSave(data, img) {
          const base64 = data;
          const meta = img.attr('data-displaymeta');
          $.ajax({
            url: wp.ajaxurl,
            type: 'POST',
            data: {
              action: 'media_save_edited',
              meta,
              image: base64
            },
            beforeSend() {
              startLoading();
            },
            error() {
              errorToast();
            },
            success() {
              M.toast({
                html: 'Edited Image Saved',
                classes: 'success-toast'
              });
            },
            complete() {
              $('#dashboard-reload .campaign-creator-form').submit();
            }
          });
        },
        onError() {
          errorToast();
        }
      });
      imgEditor.open({
        url: originalImageSrc,
        image: originalImage
      });
    });

  $('#campaign-step-reload').on('click', '.open-media-library', function(e) {
    e.preventDefault();
    const postID = $(this).data('postid');
    const meta = $(this).data('displaymeta');
    const mediaLib = document.querySelector('#modal-media-library');
    M.Modal.init(mediaLib, {
      dismissible: false,
      opacity: 0.8,
      onOpenEnd() {
        const div = $('#media-library-reload');
        $.ajax({
          url: wp.ajaxurl,
          method: 'POST',
          data: {
            action: 'media_library_load',
            media_show: 'campaign',
            campaign: postID,
            meta
          },
          beforeSend() {
            startContentLoading();
          },
          error() {
            errorToast();
          },
          success(data) {
            div.html(data);
          },
          complete() {
            stopContentLoading();
          }
        });
      },
      onCloseEnd() {
        const mediaModal = M.Modal.getInstance(mediaLib);
        mediaModal.destroy();
      }
    });
    const mediaModal = M.Modal.getInstance(mediaLib);
    mediaModal.open();
  });

  $('body .remove-img').on('click', function(e) {
    e.preventDefault();
    const meta = $(this).data('displaymeta');
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'media_unset',
        meta
      },
      error() {
        errorToast();
      },
      success() {
        M.toast({
          html: 'Image Removed From Campaign',
          classes: 'success-toast'
        });
      },
      complete() {
        $('#dashboard-reload .campaign-creator-form').submit();
      }
    });
  });

  $('#modal-media-library #media-library-upload').on('submit', function(e) {
    e.preventDefault();
    const fileData = $('#modal-media-library #upload_media_file').prop(
      'files'
    )[0];
    const meta = $('#modal-media-library #media-library-selected').data('meta');
    const data = $(this).serialize();
    const formdata = new FormData();
    formdata.append('upload_media_file', fileData);
    formdata.append('data', data);
    formdata.append('action', 'upload_media');
    formdata.append('meta', meta);
    const div = $('#modal-media-library #media-library-reload');
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: formdata,
      processData: false,
      contentType: false,
      beforeSend() {
        startContentLoading();
      },
      error() {
        errorToast();
      },
      success(response) {
        div.html(response);
      },
      complete() {
        stopContentLoading();
        $('#media-library-reload input[type=radio]:first').attr(
          'checked',
          true
        );
      }
    });
  });

  $('#modal-media-library input[type=file]').on('change', () => {
    $('#modal-media-library #media-library-upload').submit();
  });

  $('#modal-media-library')
    .off()
    .on('submit', '#media-library-selected', function(e) {
      e.preventDefault();
      const data = $(this).serialize();
      const meta = $('#modal-media-library #media-library-selected').data(
        'meta'
      );
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'media_selected',
          data,
          meta
        },
        beforeSend() {
          startContentLoading();
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Image Selected',
            classes: 'success-toast'
          });
        },
        complete() {
          const mediaLib = document.querySelector('#modal-media-library');
          const mediaModal = M.Modal.getInstance(mediaLib);
          mediaModal.close();
          stopContentLoading();
          $('#dashboard-reload .campaign-creator-form').submit();
        }
      });
    });

  $('#modal-media-library #submit-media-selected').on('click', () => {
    $('#modal-media-library #media-library-selected').submit();
  });

  $('#campaign_social_share_active[name="campaign_social_share_active[]"]')
    .unbind('change')
    .change(function() {
      const value = $(this).val();
      let checked;
      if ($(this).prop('checked')) {
        checked = 'true';
      } else {
        checked = 'false';
      }
      if (value === 'facebook') {
        if (checked === 'true') {
          $('#share-facebook').removeClass('hide');
          M.updateTextFields();
        } else {
          const tumblrChecked = $(
            'input[name="campaign_social_share_active[]"]'
          )[2].checked;
          const googleChecked = $(
            'input[name="campaign_social_share_active[]"]'
          )[3].checked;
          if (!tumblrChecked && !googleChecked) {
            $('#share-facebook').addClass('hide');
          }
        }
      }
      if (value === 'twitter') {
        if (checked === 'true') {
          $('#share-twitter').removeClass('hide');
          M.updateTextFields();
        } else {
          $('#share-twitter').addClass('hide');
        }
      }
      if (value === 'tumblr') {
        if (checked === 'true') {
          $('#share-facebook').removeClass('hide');
          M.updateTextFields();
        } else {
          const facebookChecked = $(
            'input[name="campaign_social_share_active[]"]'
          )[0].checked;
          const googleChecked = $(
            'input[name="campaign_social_share_active[]"]'
          )[3].checked;
          if (!facebookChecked && !googleChecked) {
            $('#share-facebook').addClass('hide');
          }
        }
      }
      if (value === 'google') {
        if (checked === 'true') {
          $('#share-facebook').removeClass('hide');
          M.updateTextFields();
        } else {
          const facebookChecked = $(
            'input[name="campaign_social_share_active[]"]'
          )[0].checked;
          const tumblrChecked = $(
            'input[name="campaign_social_share_active[]"]'
          )[2].checked;
          if (!facebookChecked && !tumblrChecked) {
            $('#share-facebook').addClass('hide');
          }
        }
      }
    });

  // Twitter chr limit (280)
  if ($('#campaign_social_share_tw_text').length) {
    $('#campaign_social_share_tw_text').characterCounter();
    M.updateTextFields();
  }

  if ($('#campaign_social_share_tw_tweet_text').length) {
    $('#campaign_social_share_tw_tweet_text').characterCounter();
    M.updateTextFields();
  }

  const $ssButtnsSortable = $('#social_sign_in_buttons_sortable');
  if ($ssButtnsSortable.length) {

    $ssButtnsSortable.sortable({
      axis: 'y',
      update: function (event, ui) {
        const data = $(this).sortable('serialize');
        console.log(data);
        $.ajax({
          url: wp.ajaxurl,
          method: 'POST',
          data: {
            action: 'social_reorder_ss_buttons',
            data: data,
          },
          error() {
            errorToast();
          },
          success(html) {
            $(".ss-buttons-preview-block-reload").html(html);
          }
        });
      }
    });

    $(".campaign_social_signin_active").on('click', function () {

      const data = $('#social_sign_in_buttons_sortable input').serialize();

      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'social_update_social_signin_active',
          data: data,
        },
        error() {
          errorToast();
        },
        success(html) {
          $(".ss-buttons-preview-block-reload").html(html);
        }
      });
    });
  }

  /**
   * Campaign Creator - Form
   */
  $('input[name="avail_fields[]"]')
    .unbind('change')
    .change(function() {
      console.log("avail_fields changed 1");
      const value = $(this).val();
      const input = $(this).data('input');
      const label = $(this).data('label');
      const validation = $(this).data('validation');
      const pclass = $(this).data('public');
      const fieldtype = $(this).data('fieldtype');

      let optionCell = `<td id="custom-options" class="edit-entry disabled"><button class="btn btn-flat waves-effect waves-red" disabled>Edit Options</button></td>`;

      if ("gender" === value) {
        optionCell = `<td id="gender-options" class="edit-entry enabled"><button data-type="gender-options" class="btn btn-flat waves-effect waves-red">Edit Options</button><input class="options-value" type="hidden" value=""></td>`;
      } else if ("genres" === value) {
        optionCell = `<td id="genres-options" class="edit-entry enabled"><button data-type="genres-options" class="btn btn-flat waves-effect waves-red">Edit Options</button><input class="options-value" type="hidden" value=""></td>`;
      }
      let tooltip_old = '<span class="img-size-hover google-tooltip"><span class="card-panel-block"><span class="card-panel hide" style="min-width:200px;"><span style="font-weight: 400; font-size: 12px; text-transform: none;">In order to select Address, City, State, Country or Postal Code, please deselect Search Address or Search Postal Code.</span></span></span></span>';
      let tooltip_new = '<span class="img-size-hover google-tooltip"><span class="card-panel-block"><span class="card-panel hide" style="min-width:200px;"><span style="font-weight: 400; font-size: 12px; text-transform: none;">In order to select Search Address or Search Postal Code, please deselect Address, City, State, Country, and Postal Code.</span></span></span></span>';
      if ("search_postal_code" === value
        || 'search_address' === value
        || 'address' === value
        || 'city' === value
        || 'country' === value
        || 'zip' === value
        || 'state' === value
      ) {
        let allow_google_search = true,
            allow_google_postal_code = true,
            allow_address_fields = true;
        $('input[data-public~="field_address"], ' +
          'input[data-public~="field_city"], ' +
          'input[data-public~="field_country"], ' +
          'input[data-public~="field_postal_code"], ' +
          'input[data-public~="field_state"]').each( function() {
            if ( $( this).prop('checked') ) {
              allow_google_postal_code = allow_google_search = false;
            }
        });
        $('input[data-public~="field_search_address"]').each(function() {
          if ( $( this ).prop('checked') ) {
            allow_google_postal_code = allow_address_fields = false;
          }
        });
        $('input[data-public~="field_search_postal_code"]').each(function() {
          if ( $( this ).prop('checked') ) {
            allow_address_fields = allow_google_search = false;
          }
        });
        if ( allow_google_search ) {
          $('input[data-public~="field_search_address"]').prop('disabled', false).siblings('.google-tooltip').remove();
        } else {
          $('input[data-public~="field_search_address"]').prop('disabled', true).after(tooltip_new);
        }
        if ( allow_google_postal_code ) {
          $('input[data-public~="field_search_postal_code"]').prop('disabled', false).siblings('.google-tooltip').remove();
        } else {
          $('input[data-public~="field_search_postal_code"]').prop('disabled', true).after(tooltip_new);
        }
        if ( allow_address_fields ) {
          $('input[data-public~="field_address"], ' +
            'input[data-public~="field_city"], ' +
            'input[data-public~="field_country"], ' +
            'input[data-public~="field_postal_code"], ' +
            'input[data-public~="field_state"]').prop('disabled', false).siblings('.google-tooltip').remove();
        } else {
          $('input[data-public~="field_address"], ' +
            'input[data-public~="field_city"], ' +
            'input[data-public~="field_country"], ' +
            'input[data-public~="field_postal_code"], ' +
            'input[data-public~="field_state"]').prop('disabled', true).siblings('.google-tooltip').remove();
          $('input[data-public~="field_address"], ' +
            'input[data-public~="field_city"], ' +
            'input[data-public~="field_country"], ' +
            'input[data-public~="field_postal_code"], ' +
            'input[data-public~="field_state"]').after(tooltip_old);
        }
        if ( allow_google_search || allow_google_postal_code || allow_address_fields ) {
          initCampaigns();
        }
      }

      const $displayedFormFields = $(this).parents(".form-fields-content").find('.displayed-form-fields tbody');

      if ($(this).prop('checked')) {
        const element = `
			<tr class="row_${value}" data-formfield="${value}" data-fieldtype="${fieldtype}" data-public="${pclass}">
			<td class="drag-handle"><i class="material-icons">drag_handle</i></td>
			<td id="formfield-name" class="non-editable">${input}</td>
			<td id="formfield-label" class="edit-entry"><input type="text" class="browser-default" value="${label}"></td>
			<td id="formfield-validation" class="edit-entry"><input type="text" class="browser-default" value="${validation}"></td>
			${optionCell}
			<td class="checkbox-entry"><label><select name="${value}_req"><option value="required">Required</option><option value="not_required" selected>Not Required</option></select><span></span></label></td>
			<td class="remove-field"><i class="material-icons">delete_forever</i></td>
			</tr>
			`;
        $displayedFormFields.append(element);
        removeFieldInit();
      } else {
        $(`.row_${value}`).remove();
      }
      if ($("#ss-form-combo-switches").length) {
        hideFormFieldsForComboFrom();
      }


      initGenderOptionsModal();
      initGenresOptionsFormModal()
      removeFieldInit();
    });

  initGenderOptionsModal();
  initGenresOptionsFormModal()
  removeFieldInit();

  $('.custom-form-fields')
    .off()
    .on('click', '.custom-field-add', function() {
      const fieldtype = $(this).data('fieldtype');
      const value = `custom_${fieldtype}`;
      const pclass = `custom_field_${fieldtype}`;
      const input = `Custom ${fieldtype}`;
      const label = 'Edit Label';
      const validation = 'Edit validation message';
      const $displayedFormFields = $(this).parents(".form-fields-content").find('.displayed-form-fields tbody');

      let element;
      if (
        fieldtype === 'select' ||
        fieldtype === 'radio' ||
        fieldtype === 'checkbox'
      ) {
        element = `
			<tr class="row_${value}" data-formfield="${value}" data-fieldtype="${fieldtype}" data-public="${pclass}">
			<td class="drag-handle"><i class="material-icons">drag_handle</i></td>
			<td id="formfield-name" class="non-editable">${input}</td>
			<td id="formfield-label" class="edit-entry"><input type="text" class="browser-default" value="${label}"></td>
			<td id="formfield-validation" class="edit-entry"><input type="text" class="browser-default" value="${validation}"></td>
			<td id="custom-options" class="edit-entry enabled"><button data-type="${fieldtype}" class="btn btn-flat waves-effect waves-red">Edit Options</button><input class="options-value" type="hidden" value=""></td>
			<td class="checkbox-entry"><label><select name="${value}_req"><option value="required">Required</option><option value="not_required" selected>Not Required</option></select><span></span></label></td>
			<td class="remove-field"><i class="material-icons">delete_forever</i></td>
			</tr>
			`;
      } else {
        element = `
			<tr class="row_${value}" data-formfield="${value}" data-fieldtype="${fieldtype}" data-public="${pclass}">
			<td class="drag-handle"><i class="material-icons">drag_handle</i></td>
			<td id="formfield-name" class="non-editable">${input}</td>
			<td id="formfield-label" class="edit-entry"><input type="text" class="browser-default" value="${label}"></td>
			<td id="formfield-validation" class="edit-entry"><input type="text" class="browser-default" value="${validation}"></td>
			<td id="custom-options" class="edit-entry disabled"><button class="btn btn-flat waves-effect waves-red" disabled>Edit Options</button></td>
			<td class="checkbox-entry"><label><select name="${value}_req"><option value="required">Required</option><option value="not_required" selected>Not Required</option></select><span></span></label></td>
			<td class="remove-field"><i class="material-icons">delete_forever</i></td>
			</tr>
			`;
      }
      $displayedFormFields.append(element);
      initCustomOptionsModal();
      removeFieldInit();
    });
  initCustomOptionsModal();
  removeFieldInit();



  /**
   * Campaign Creator - Design
   *
   */
  $('#choose-primary-font')
    .unbind('click')
    .click(() => {
      const fontPrimary = $('#primary-font').val();
      const div = $('#campaign-step-reload');
      const campaignLink = $(
        '#campaign-step-reload #campaign-menu a.active'
      ).data('step');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_save_fonts',
          font_primary: fontPrimary
        },
        beforeSend() {
          startLoading();
        },
        error() {
          errorToast();
        },
        success(data) {
          div.html(data);
        },
        complete() {
          stopLoading();
          $('.step-group').removeClass('active');
          $(`.step-${campaignLink} a`).addClass('active');
          initCampaigns();
          initDesign();
        }
      });
    });

  $('#choose-font-secondary')
    .unbind('click')
    .click(() => {
      const fontSecondary = $('#secondary-font').val();
      const div = $('#campaign-step-reload');
      const campaignLink = $(
        '#campaign-step-reload #campaign-menu a.active'
      ).data('step');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_save_fonts',
          font_secondary: fontSecondary
        },
        beforeSend() {
          startLoading();
        },
        error() {
          errorToast();
        },
        success(data) {
          div.html(data);
        },
        complete() {
          stopLoading();
          $('.step-group').removeClass('active');
          $(`.step-${campaignLink} a`).addClass('active');
          initCampaigns();
          initDesign();
        }
      });
    });

  $('#remove-primary-font')
    .unbind('click')
    .click(() => {
      const primary = 'primary';
      const div = $('#campaign-step-reload');
      const campaignLink = $(
        '#campaign-step-reload #campaign-menu a.active'
      ).data('step');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_remove_fonts',
          primary
        },
        beforeSend() {
          startLoading();
        },
        error() {
          errorToast();
        },
        success(data) {
          div.html(data);
          M.toast({
            html: 'Font Removed Successfully',
            classes: 'success-toast'
          });
        },
        complete() {
          stopLoading();
          $('.step-group').removeClass('active');
          $(`.step-${campaignLink} a`).addClass('active');
          initCampaigns();
          initDesign();
        }
      });
    });

  $('#remove-secondary-font')
    .unbind('click')
    .click(() => {
      const secondary = 'secondary';
      const div = $('#campaign-step-reload');
      const campaignLink = $(
        '#campaign-step-reload #campaign-menu a.active'
      ).data('step');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_remove_fonts',
          secondary
        },
        beforeSend() {
          startLoading();
        },
        error() {
          errorToast();
        },
        success(data) {
          div.html(data);
          M.toast({
            html: 'Font Removed Successfully',
            classes: 'success-toast'
          });
        },
        complete() {
          stopLoading();
          $('.step-group').removeClass('active');
          $(`.step-${campaignLink} a`).addClass('active');
          initCampaigns();
          initDesign();
        }
      });
    });

  $('#design-builder .tabs-top')
    .off()
    .on('click', 'a', function() {
      const tabLink = $(this).data('tablink');
      const div = $('#design-tab-reload');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_tab_reload',
          tab_link: tabLink
        },
        error() {
          errorToast();
        },
        success(data) {
          div.html(data);
        },
        complete() {
          initDesign();
        }
      });
    });

  /**
   * Campaign Creator - Status
   *
   */
  $('#campaign-step-reload input#campaign_status_published')
    .off()
    .on('click', e => {
      const unsaved = $('#campaign_status_published').hasClass(
        'unsaved-integrations'
      );
      const ppActive = $('#campaign_status_published').data('pp');
      const urisActive = $('#campaign_status_published').data('uris');
      if (unsaved) {
        e.preventDefault();
        $('#campaign_status_published').prop('checked', false);
        M.toast({
          html:
            'Please check and re-save the Integrations settings page before publishing this campaign.',
          classes: 'error-toast',
          displayLength: 8000
        });
      }
      if (ppActive == false) {
        e.preventDefault();
        $('#campaign_status_published').prop('checked', false);
        M.toast({
          html:
            "You'll need to fill in the Privacy Policy Link Name and Privacy Policy URL fields in the Main Settings screen in order to publish the form!",
          classes: 'error-toast',
          displayLength: 8000
        });
      }
      if (urisActive.length) {
        e.preventDefault();
        $('#campaign_status_published').prop('checked', false);
        M.toast({
          html: "You'll need to fill in brand social URLs before publishing!",
          classes: 'error-toast',
          displayLength: 5000
        });
        const activityModalElem = $('#modal-campaign-brand-validation');
        // Create modal instance
        M.Modal.init(activityModalElem, {
          opacity: 0.8,
          onCloseEnd() {
            const modal = M.Modal.getInstance(activityModalElem);
            modal.destroy();
          }
        });
        // Get the modal instance and open it
        const activityModal = M.Modal.getInstance(activityModalElem);
        activityModal.open();

        const smuValidate = {
          artistTwitterHandle(value) {
            return /^(http|https):\/\/(www\.|)(twitter|x)\.com\/[a-zA-Z0-9_]+/i.test(
              value
            );
          },

          artistFacebookUrl(value) {
            return /^(http|https):\/\/(www\.|)facebook\.com\/[a-zA-Z0-9_]+/i.test(
              value
            );
          },

          artistYoutubeUrl(value) {
            return /^(http|https):\/\/(www\.|)youtube\.com\/[a-zA-Z0-9_]+/i.test(
              value
            );
          },

          artistSpotifyUrl(value) {
            return /^(?:https?:\/\/(?:open\.)?spotify\.com\/artist\/[a-z0-9_.]+\/?|spotify:artist:[a-z0-9_.]+)$/i.test(
              value
            );
          },

          artistAmazonUrl(value) {
            return /^https:\/\/music.amazon.com\/artists\/B0.*$/i.test(
              value
            );
          },

          artistDeezerUrl(value) {
            return /^(?:http|https):\/\/www.deezer.com\/[a-z0-9_.]+\/artist\/.*\/?$/i.test(
              value
            );
          },

          artistAppleMusicUrl(value) {
            return /^(?:http|https):\/\/(itunes|music)\.apple\.com\/[a-z0-9_.]+\/artist\/.*\/?$/i.test(
              value
            );
          }
        };

        window.smuValidate = smuValidate;

        const $brandValInput = $('#modal-campaign-brand-validation input');
        $brandValInput.on('keyup blur input', function() {
          const func = $(this).data('validate');
          let val = $(this).val();

          if (
            val.indexOf('spotify') !== -1 ||
            val.indexOf('amazon') !== -1 ||
            val.indexOf('deezer') !== -1 ||
            val.indexOf('apple') !== -1
          ) {
            val = val.split('?')[0];
            $(this).val(val);
          }

          const valid = smuValidate[func](val);

          if (!valid) {
            $(this).addClass('invalid');
            return;
          }

          $(this)
            .addClass('valid')
            .removeClass('invalid');

          if ($('#modal-campaign-brand-validation .invalid').length) {
            return;
          }

          $('#modal-campaign-brand-validation button').removeAttr('disabled');

          if (val.indexOf('spotify:') !== -1) {
            val = val.split(':');
            val = `https://open.spotify.com/artist/${last(val)}`;
          } else {
            val = trim(val, '/');
            val = val.split('?')[0];
          }
          $(this).val(val);
        });

        $('#campaign-brand-validation').on('submit', function(e) {
          e.preventDefault();
          const data = $(this).serialize();
          const valModalElem = $('body #modal-campaign-brand-validation');
          const $buttonElem = $(
            'body #modal-campaign-brand-validation .brand-validation-submit'
          );
          $buttonElem.text('Saving...');
          $buttonElem.attr('disabled', 'disabled');
          const valModal = M.Modal.getInstance(valModalElem);
          $.ajax({
            url: wp.ajaxurl,
            method: 'POST',
            data: {
              action: 'artist_post_data',
              data
            },
            error() {
              errorToast();
            },
            success() {
              valModal.close();
              valModal.destroy();
              window.location.reload();
            }
          });
        });
      }
    });

  $('#qrcode-email')
    .off()
    .on('click', e => {
      e.preventDefault();
      const campaign = $(
        '#campaign-status-step input[name="campaign_id"]'
      ).val();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'send_qrcode_email',
          campaign
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Email Sent',
            classes: 'success-toast'
          });
        }
      });
    });

  $('#campaign-step-reload input[name="campaign_iframe_type"]')
    .unbind('change')
    .change(function() {
      const value = $(this).val();
      if (value === 'full') {
        const height = $('#iframe-full-height').val();
        const textstart = $(this).data('textstart');
        const textend = $(this).data('textend');
        $('#iframe-full').removeClass('hide');
        $('#iframe-fixed').addClass('hide');
        $('#campaign_iframe_code').val(textstart + height + textend);
      }
      if (value === 'fixed') {
        const height = $('#iframe-fixed-height').val();
        const width = $('#iframe-fixed-width').val();
        const textstart = $(this).data('textstart');
        const textmiddle = $(this).data('textmiddle');
        const textend = $(this).data('textend');
        $('#iframe-fixed').removeClass('hide');
        $('#iframe-full').addClass('hide');
        $('#campaign_iframe_code').val(
          textstart + width + textmiddle + height + textend
        );
      }
    });

  $('#campaign-step-reload #iframe-full-height').on('keyup', function() {
    const height = $(this).val();
    const textstart = $(this).data('textstart');
    const textend = $(this).data('textend');
    $('#campaign_iframe_code').val(textstart + height + textend);
  });

  $('#campaign-step-reload #iframe-fixed-height').on('keyup', function() {
    const height = $(this).val();
    const width = $('#iframe-fixed-width').val();
    const textstart = $(this).data('textstart');
    const textmiddle = $(this).data('textmiddle');
    const textend = $(this).data('textend');
    $('#campaign_iframe_code').val(
      textstart + width + textmiddle + height + textend
    );
  });

  $('#campaign-step-reload #iframe-fixed-width').on('keyup', function() {
    const height = $('#iframe-fixed-height').val();
    const width = $(this).val();
    const textstart = $(this).data('textstart');
    const textmiddle = $(this).data('textmiddle');
    const textend = $(this).data('textend');
    $('#campaign_iframe_code').val(
      textstart + width + textmiddle + height + textend
    );
  });

  $('body #spotify-container #tabs-container #tabs-sticky a').on('click', function(e) {
    e.preventDefault();
    const tabID = $(this).data('tablink');
    const tabContainer = $('#spotify-container #tabs-container');
    tabContainer.find('#tabs .designer-tab').each( function () {
      $(this).children('div').addClass('hide');
    });
    validPresaveSpotifyTabs(tabContainer);
    tabContainer.find('#tabs #' + tabID + '-tab > div').removeClass('hide')
    eventAddExistingPl(tabID);
  });

  function validPresaveSpotifyTabs( tabContainer ) {
    const plNameValue = $('#campaign_spotify_custom_auto_playlist_name').val();
    $('body #spotify-container #tabs-container #tabs-sticky a').each( function () {
      const tabID = $(this).data('tablink');
      $(this).find('.material-icons').remove();
      if (
        tabContainer.find('#tabs #' + tabID + '-tab .responsive-table tbody tr').length ||
        tabContainer.find('#tabs #' + tabID + '-tab #spotify-collection > li').length
      ) {
        if ( tabID == 'custom' && (plNameValue == null || plNameValue == '' ) ) {
          $(this).append('<span class="material-icons" style="color:red;font-size: 18px;top: -6px;position: relative;">error_outline</span>');
        } else {
          $(this).append('<span class="material-icons">playlist_add_check</span>');
        }
      }
    });
  }


  $('body #amazon-container #tabs-container #tabs-sticky a').on('click', function(e) {
    e.preventDefault();
    const tabID = $(this).data('tablink');
    const tabContainer = $('#amazon-container #tabs-container');
    tabContainer.find('#tabs .designer-tab').each( function () {
      $(this).children('div').addClass('hide');
    });
    validPresaveAmazonTabs(tabContainer);
    if ( tabID == 'add-tracks' || tabID == 'custom' ) {
      tabContainer.find('#tabs #' + tabID +'-tab > div:first-child').removeClass('hide')
    } else {
      tabContainer.find('#tabs #' + tabID + '-tab > div').removeClass('hide')
    }
    eventAddExistingPl();
  });

  function validPresaveAmazonTabs( tabContainer ) {
    const plNameValue = $('#campaign_amazon_custom_auto_playlist_name').val();
    $('body #amazon-container #tabs-container #tabs-sticky a').each( function () {
      const tabID = $(this).data('tablink');
      $(this).find('.material-icons').remove();
      if (
        tabContainer.find('#tabs #' + tabID + '-tab .responsive-table tbody tr').length ||
        tabContainer.find('#tabs #' + tabID + '-tab #amazon-collection > li').length
      ) {
        if ( tabID == 'custom' && (plNameValue == null || plNameValue == '' ) ) {
          $(this).append('<span class="material-icons" style="color:red;font-size: 18px;top: -6px;position: relative;">error_outline</span>');
        } else {
          $(this).append('<span class="material-icons">playlist_add_check</span>');
        }
      }
    });
  }

  $('.copy-link')
    .unbind('click')
    .click(() => {
      M.toast({
        html: '<i class="material-icons">content_copy</i> Copied',
        classes: 'copy-toast'
      });
    });

  if (typeof clipboard === 'undefined') {
    clipboard = new Clipboard('.copy-link');
  }

  if ($('#spotify-html-code').length) {
    const code = document.querySelector('#spotify-html-code');
    CodeMirror.fromTextArea(code, {
      lineNumbers: true,
      styleActiveLine: true,
      matchBrackets: true,
      readOnly: true,
      theme: 'material',
      smartIndent: true,
      mode: 'htmlmixed'
    });
  }

  if ($('#amazon-html-code').length) {
    const code = document.querySelector('#amazon-html-code');
    CodeMirror.fromTextArea(code, {
      lineNumbers: true,
      styleActiveLine: true,
      matchBrackets: true,
      readOnly: true,
      theme: 'material',
      smartIndent: true,
      mode: 'htmlmixed'
    });
  }

  if ($('#deezer-html-code').length) {
    const code = document.querySelector('#deezer-html-code');
    CodeMirror.fromTextArea(code, {
      lineNumbers: true,
      styleActiveLine: true,
      matchBrackets: true,
      readOnly: true,
      theme: 'material',
      smartIndent: true,
      mode: 'htmlmixed'
    });
  }

  if ($('#apple-html-code').length) {
    const code = document.querySelector('#apple-html-code');
    CodeMirror.fromTextArea(code, {
      lineNumbers: true,
      styleActiveLine: true,
      matchBrackets: true,
      readOnly: true,
      theme: 'material',
      smartIndent: true,
      mode: 'htmlmixed'
    });
  }

  if ($('#youtube-html-code').length) {
    const code = document.querySelector('#youtube-html-code');
    CodeMirror.fromTextArea(code, {
      lineNumbers: true,
      styleActiveLine: true,
      matchBrackets: true,
      readOnly: true,
      theme: 'material',
      smartIndent: true,
      mode: 'htmlmixed'
    });
  }

  if ($('#form-html-code').length) {
    const code = document.querySelector('#form-html-code');
    CodeMirror.fromTextArea(code, {
      lineNumbers: true,
      styleActiveLine: true,
      matchBrackets: true,
      readOnly: true,
      theme: 'material',
      smartIndent: true,
      mode: 'htmlmixed'
    });
  }

  $('.img-size-hover, .hint-hover').hover(
    function () {
      $(this).find('.card-panel').removeClass('hide');
    },
    function () {
      $(this).find(`.card-panel`).addClass('hide');
    }
  );

  M.updateTextFields();

  initQuiz();
  initSls();

  if('social' === tabLink) {
    initSocialStep();
  }
};

window.initCampaigns = initCampaigns;

// 8. Init Business Units
// ==========================================================================
// Should be called anytime the business unit page is loaded.
// ==========================================================================
function initBU() {
  $('#dashboard-reload').on('click', '#set-light-colors', () => {
    $('#bg-color-cp').val('rgba(255,255,255,1)');
    $('#bg-color-cp').css('border-color', 'rgba(255,255,255,1)');
    $('#header-text-bg-cp').val('rgba(255,255,255,0)');
    $('#header-text-bg-cp').css('border-color', 'rgba(255,255,255,0)');
    $('#header-text-cp').val('rgba(68,68,68,1)');
    $('#header-text-cp').css('border-color', 'rgba(68,68,68,1)');
    $('#page-text-cp').val('rgba(68,68,68,1)');
    $('#page-text-cp').css('border-color', 'rgba(68,68,68,1)');
    $('#footer-bg-cp').val('rgba(68,68,68,1)');
    $('#footer-bg-cp').css('border-color', 'rgba(68,68,68,1)');
    $('#footer-text-cp').val('rgba(255,255,255,1)');
    $('#footer-text-cp').css('border-color', 'rgba(255,255,255,1)');

    $('#clickable-cp').val('rgba(45,150,221,1)');
    $('#clickable-hover-cp').val('rgba(33,104,163,1)');
    $('#form-label-cp').val('rgba(51,51,51,1)');
    $('#form-field-text-cp').val('rgba(51,51,51,1)');
    $('#form-field-bg-cp').val('rgba(255,255,255,1)');
    $('#form-cont-bg-cp').val('rgba(255,255,255,1)');
    $('#hover-dsp-button-cp').val('rgba(33,104,163,1)');
    $('#clickable-cp').css('border-color', 'rgba(45,150,221,1)');
    $('#clickable-hover-cp').css('border-color', 'rgba(33,104,163,1)');
    $('#form-label-cp').css('border-color', 'rgba(51,51,51,1)');
    $('#form-field-text-cp').css('border-color', 'rgba(51,51,51,1)');
    $('#form-field-bg-cp').css('border-color', 'rgba(255,255,255,1)');
    $('#form-cont-bg-cp').css('border-color', 'rgba(255,255,255,1)');
    $('#hover-dsp-button-cp').css('border-color', 'rgba(33,104,163,1)');
  });

  $('#dashboard-reload').on('click', '#set-dark-colors', () => {
    $('#bg-color-cp').val('rgba(0,0,0,1)');
    $('#bg-color-cp').css('border-color', 'rgba(0,0,0,1)');
    $('#header-text-bg-cp').val('rgba(0,0,0,0)');
    $('#header-text-bg-cp').css('border-color', 'rgba(0,0,0,0)');
    $('#header-text-cp').val('rgba(255,255,255,1)');
    $('#header-text-cp').css('border-color', 'rgba(255,255,255,1)');
    $('#page-text-cp').val('rgba(255,255,255,1)');
    $('#page-text-cp').css('border-color', 'rgba(255,255,255,1)');
    $('#footer-bg-cp').val('rgba(0,0,0,1)');
    $('#footer-bg-cp').css('border-color', 'rgba(0,0,0,1)');
    $('#footer-text-cp').val('rgba(255,255,255,1)');
    $('#footer-text-cp').css('border-color', 'rgba(255,255,255,1)');

    $('#clickable-cp').val('rgba(23,151,192,1)');
    $('#clickable-hover-cp').val('rgba(15,97,124,1)');
    $('#form-label-cp').val('rgba(255,255,255,1)');
    $('#form-field-text-cp').val('rgba(51,51,51,1)');
    $('#form-field-bg-cp').val('rgba(255,255,255,1)');
    $('#form-cont-bg-cp').val('rgba(0,0,0,1)');
    $('#hover-dsp-button-cp').val('rgba(15,97,124,1)');
    $('#clickable-cp').css('border-color', 'rgba(23,151,192,1)');
    $('#clickable-hover-cp').css('border-color', 'rgba(15,97,124,1)');
    $('#form-label-cp').css('border-color', 'rgba(255,255,255,1)');
    $('#form-field-text-cp').css('border-color', 'rgba(51,51,51,1)');
    $('#form-field-bg-cp').css('border-color', 'rgba(255,255,255,1)');
    $('#form-cont-bg-cp').css('border-color', 'rgba(0,0,0,1)');
    $('#hover-dsp-button-cp').css('border-color', 'rgba(15,97,124,1)');
  });

  /**
   * For territory birth year toggle
   */
  if( $("div#restrict_year_html").hasClass('hide_year') ){
    $('.birthday-date-field').hide();
  }
  $("#bu_restrict_year_global_selector")
    .on('click', function() {
      $("div#restrict_year_html").toggleClass('hide_year');
      $('.birthday-date-field').toggle();
    });

  $('#bu_label_override_defaults_selector')
    .off()
    .on('change', function(e) {
      const selected = e.target.checked;
      const id = $(this).data('id');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'save_label_override',
          bu_id: id,
          selected
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Label Override Saved',
            classes: 'success-toast'
          });
        }
      });
    });

  $('#bu_campaign_geo_mode')
    .off()
    .on('change', function(e) {
      const selected = e.target.checked;
      const id = $(this).data('id');

      $('#bu_campaign_geo_mode_settings').toggleClass('hide', !selected);

      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'save_geo_mode',
          bu_id: id,
          selected
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Geo Mode Saved',
            classes: 'success-toast'
          });
        }
      });
    });

  /**
   * AJAX save for the Business Units
   */
  $('#form-update-bu')
    .off()
    .on('submit', function(e) {
      e.preventDefault();

      const $form = $(this);

      const isGeoOn = $form.find('#bu_campaign_geo_mode').is(':checked');

      if (isGeoOn) {
        const geoAllowValues = $form.find('#bu_campaign_geo_allow').val();

        const geoBlockValues = $form.find('#bu_campaign_geo_block').val();

        if (!geoAllowValues && !geoBlockValues) {
          M.toast({
            html:
              'At least one country should be added to the allow/block list',
            classes: 'error-toast'
          });

          return false;
        }
      }

      const gtmCode = $form.find('#bu_gtm_code').val();

      if(gtmCode !== undefined && gtmCode !== '' && !/^GTM-[A-Z0-9]*$/.test(gtmCode)) {
        M.toast({
          html:
            'Google Tag Manager code has incorrect format (Ex.: GTM-A1B2C3)',
          classes: 'error-toast'
        });

        return false;
      }

      const data = $form.serialize();
      const div = $('#bu-reload-data');

      const formCollection = {};
      const formCollectionAll = document.querySelectorAll(
        '.displayed-form-fields tbody tr'
      );
      $.each(formCollectionAll, function (index, value) {

        const formFieldValue = $(value).data('formfield');
        const formFieldName = $(value)
          .find('#formfield-name')
          .text();
        const formFieldLabel = $(value)
          .find('#formfield-label input')
          .val();
        const formFieldValidation = $(value)
          .find('#formfield-validation input')
          .val();
        const formFieldPublic = $(value).data('public');
        const formFieldType = $(value).data('fieldtype');
        const formFieldReq = $(value)
          .find('select')
          .val();
        const formFieldOptions = $(value)
          .find('.options-value')
          .val();

        formCollection[index] = {
          value: formFieldValue,
          name: formFieldName,
          label: formFieldLabel,
          validation: formFieldValidation,
          req: formFieldReq,
          public: formFieldPublic,
          fieldtype: formFieldType,
          options: formFieldOptions,
        };
      });

      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'bu_post_data',
          data,
          form_collection: formCollection,
          userid: wp.userid
        },
        beforeSend: startLoading,
        error: errorToast,
        success(response) {
          div.html(response);
          M.toast({
            html: 'Saved Successfully',
            classes: 'success-toast'
          });
        },
        complete() {
          stopLoading();
          init();
          initBU();
        }
      });
    });

  $('#bu_campaign_geo_status_allow')
    .off()
    .on('click', () => {
      $('#geo_allow_container').removeClass('hide');
      $('#geo_block_container').addClass('hide');
    });
  $('#bu_campaign_geo_status_block')
    .off()
    .on('click', () => {
      $('#geo_allow_container').addClass('hide');
      $('#geo_block_container').removeClass('hide');
    });

  $('#bu-reload-data .mselect').select2();

  $('#bu_campaign_ccpa_links_mode')
    .off()
    .on('change', function (e) {
      const selected = e.target.checked;
      const id = $(this).data('id');

      $('.ccpa-param-field').toggleClass('hide', !selected);

      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'save_ccpa_mode',
          bu_id: id,
          selected
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'CCPA Mode Saved',
            classes: 'success-toast'
          });
        }
      });
    });

  // Form fields
  $('input[name="avail_fields[]"]').unbind('change').change(function() {
    console.log("avail_fields changed 2");
    const value = $(this).val();
    const input = $(this).data('input');
    const label = $(this).data('label');
    const validation = $(this).data('validation');
    const pclass = $(this).data('public');
    const fieldtype = $(this).data('fieldtype');
    if ($(this).prop('checked')) {
      let optionCell = `<td id="custom-options" class="edit-entry disabled"><button class="btn btn-flat waves-effect waves-red" disabled>Edit Options</button></td>`;

      if ("gender" === value) {
        optionCell = `<td id="gender-options" class="edit-entry enabled"><button data-type="gender-options" class="btn btn-flat waves-effect waves-red">Edit Options</button><input class="options-value" type="hidden" value=""></td>`;
      } else if ("genres" === value) {
        optionCell = `<td id="genres-options" class="edit-entry enabled"><button data-type="genres-options" class="btn btn-flat waves-effect waves-red">Edit Options</button><input class="options-value" type="hidden" value=""></td>`;
      }

      const element =
        `
			<tr class="row_${value}" data-formfield="${value}" data-fieldtype="${fieldtype}" data-public="${pclass}">
				<td class="drag-handle">
					<i class="material-icons">drag_handle</i>
				</td>
				<td id="formfield-name" class="non-editable">
					${input}
				</td>
				<td id="formfield-label" class="edit-entry">
					<input type="text" class="browser-default" value="${label}">
				</td>
				<td id="formfield-validation" class="edit-entry">
					<input type="text" class="browser-default" value="${validation}">
				</td>
				${optionCell}
				<td class="checkbox-entry">
					<select name="${value}_req">
						<option value="required">Required</option>
						<option value="not_required" selected>Not Required</option>
					</select>
				</td>
				<td class="remove-field">
					<i class="material-icons">delete_forever</i>
				</td>
			</tr>
			`;
      $('.displayed-form-fields tbody').append(element);
    } else {
      $('.row_' + value).remove();
    }

    initGenderOptionsModal();
    initGenresOptionsBuModal();
    removeFieldInit();
  });

  initGenderOptionsModal();
  initGenresOptionsBuModal();
  removeFieldInit();
}


/**
 * BU page ajax function responsible for running the selectors
 */
$('body').on('change', 'input:radio[name=bu_show]', () => {
  const type = $('input:radio[name=bu_show]:checked').val();
  const searchQuery = $('#artists_search').val();
  const div = $('#bu-reload');
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'bu_table_content',
      type,
      search_query: searchQuery
    },
    error() {
      errorToast();
    },
    success(data) {
      div.html(data);
    },
    complete() {}
  });
});

// 11. Page Linking
// ==========================================================================
/**
 * AJAX function run when a main page link is clicked from the
 * side navidation.
 */
$('body').on('click', 'a[data-link]', function() {
  let artist = $(this).data('artist');
  let bu = $(this).data('bu');
  const link = $(this).data('link');
  if (typeof artist === 'undefined') {
    artist = 0;
  }
  if (typeof bu === 'undefined') {
    bu = 0;
  }
  const div = $('#dashboard-reload');
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'dashboard_linking',
      link,
      artist,
      bu
    },
    beforeSend() {
      startLoading();
    },
    error() {
      errorToast();
    },
    success(data) {
      div.html(data);
    },
    complete() {
      stopLoading();
      $('body').removeClass('form');
      init();
      if (typeof bu !== 'undefined') {
        initBU();
      }
    }
  });
});

/**
 * AJAX function for when a link is clicked which should loaded
 * the Campaign Creator page
 */
$('body').on('click', 'a[data-campaign]', function() {
  const campaign = $(this).data('campaign');
  const div = $('#dashboard-reload');
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'dashboard_linking',
      campaign
    },
    beforeSend() {
      startLoading();
    },
    error() {
      errorToast();
    },
    success(data) {
      div.html(data);
    },
    complete() {
      stopLoading();
      $('body').addClass('form');
      initCampaigns();
    }
  });
});

/**
 * AJAX function for when a copy link is clicked which should
 * copy that campaign.
 */
$('body').on('click', 'a[data-copy]', e => {
  const id = e.target.attributes['data-copy'].value;
  const security = e.target.attributes['data-security'].value;
  const elem = document.querySelector('#modal-copy-campaign');
  M.Modal.init(elem, {
    dismissible: false,
    opacity: 0.8
  });
  const modal = M.Modal.getInstance(elem);
  modal.open();
  $('#modal-copy-campaign .smf-content-loading-animation').removeClass('hide');
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'copy_campaign_reload',
      campaign: id,
      security
    },
    success(data) {
      $('#copy-campaign-reload').html(data);
    },
    complete() {
      $('#modal-copy-campaign .smf-content-loading-animation').addClass('hide');
    }
  });
});

/**
 * On click starting the delete process
 */
$('body').on('click', 'a[data-delete]', function() {
  const id = $(this).data('delete');
  const security = $(this).data('security');
  const modalDeleteElem = document.querySelector('#modal-delete-campaign');
  const $messageContainer = $('#modal-delete-campaign #delete-message');
  const $campaignContainer = $('#modal-delete-campaign #delete-campaign');
  M.Modal.init(modalDeleteElem, {
    opacity: 0.8,
    onCloseStart() {
      $messageContainer.text(
        'Loading data please wait...'
      );
      $campaignContainer.text(
        'Yes, delete this campaign'
      );
      $campaignContainer.addClass('disabled');
    }
  });
  const modalDelete = M.Modal.getInstance(modalDeleteElem);
  $messageContainer.text(
    'Loading data please wait...'
  );
  $campaignContainer.addClass('disabled');
  modalDelete.open();
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'activity_check',
      campaign: id,
      security
    },
    success(data) {
      const { success } = data;
      if (success === false) {
        const { message } = data.data;
        errorToast(message);
        $messageContainer.text(
          'There was a problem trying to verify activity counts. Try again later or contact support.'
        );
        return;
      }
      const canDelete = data.data.delete;
      if (canDelete === 'true') {
        $messageContainer.text('');
        $campaignContainer.attr(
          'data-security',
          security
        );
        $campaignContainer.attr(
          'data-deleteid',
          id
        );
        $campaignContainer.removeClass(
          'disabled'
        );
      } else {
        $campaignContainer.attr(
          'data-security',
          security
        );
        $campaignContainer.attr(
          'data-inactiveid',
          id
        );
        $messageContainer.text(
          'There is already too much activity under this campaign to delete it, would you like to set it to Inactive instead?'
        );
        $campaignContainer.text(
          'Yes, make this campaign inactive'
        );
        $campaignContainer.removeClass(
          'disabled'
        );
      }
    }
  });
});

// AE Activity Popups
// ==========================================================================
// Activity Link Functionality
// ==========================================================================
$("html").on("mouseenter", "#select2-activity-assoc-camp-results li", function () {
    $(this).animate({'margin-left':-300},2500).animate({'margin-left':0},900);
});

$("html").on("mouseleave", "#select2-activity-assoc-camp-results li", function () {
    $(this).animate({'margin-left': 0}, 900);
});

function activityLinkInit() {
  // Only do stuff if we have activity links
  if ($('.activity-link').length) {
    // On click of activity link
    const $activityLength = $('#activity-length');
    const $activityAssocCamp = $('#activity-assoc-camp');
    const $activityAssocCampSpan = $('#activity-assoc-camp-span');
    $('.activity-link')
      .off()
      .on('click', function() {
        // Set variables
        const brandId = $(this).data('brandid');
        const segmentId = $(this).data('segmentid');
        const campaignId = $(this).data('campaignid');
        const hasAssocCamp = $(this).data('hasassociatedcampaign');
        const aespotifyId = $(this).data('aespotifyid');
        const aeamazonId = $(this).data('aeamazonid');
        const aeappleId = $(this).data('aeappleid');
        const aedeezerId = $(this).data('aedeezerid');
        const aeyoutubeId = $(this).data('aeyoutubeid');
        const aeformId = $(this).data('aeformid');
        const aesecondaryformId = $(this).data('aesecondaryformid');

        const aefacebook_so_id = $(this).data('aefacebook_so_id');
        const aetwitter_so_id = $(this).data('aetwitter_so_id');
        const aespotify_so_id = $(this).data('aespotify_so_id');
        const aeyoutube_so_id = $(this).data('aeyoutube_so_id');
        const aedeezer_so_id = $(this).data('aedeezer_so_id');

        const activityModalElem = $('#modal-campaign-activity');
        const activityReloadDiv = $(
          '#modal-campaign-activity #activity-reload'
        );
        let activityModal;
        // Markup the modal div
        activityModalElem.attr('data-brandid', brandId);
        activityModalElem.attr('data-segmentid', segmentId);
        activityModalElem.attr('data-campaignid', campaignId);
        activityModalElem.attr('data-aespotifyid', aespotifyId);
        activityModalElem.attr('data-aeamazonid', aeamazonId);
        activityModalElem.attr('data-aeappleid', aeappleId);
        activityModalElem.attr('data-aedeezerid', aedeezerId);
        activityModalElem.attr('data-aeyoutubeid', aeyoutubeId);
        activityModalElem.attr('data-aeformid', aeformId);
        activityModalElem.attr('data-aesecondaryformid', aesecondaryformId);

        activityModalElem.attr('data-aefacebook_so_id', aefacebook_so_id);
        activityModalElem.attr('data-aetwitter_so_id', aetwitter_so_id);
        activityModalElem.attr('data-aespotify_so_id', aespotify_so_id);
        activityModalElem.attr('data-aeyoutube_so_id', aeyoutube_so_id);
        activityModalElem.attr('data-aedeezer_so_id', aedeezer_so_id);

        $activityAssocCamp.html("");
        if (hasAssocCamp) {
          $.ajax({
            url: wp.ajaxurl,
            method: 'POST',
            dataType: "html",
            data: {
              action: 'campaign_activity_assoc_camp',
              campaign_id: campaignId,
            },
            error() {
              errorToast();
            },
            success(data) {
              $activityAssocCamp.html(data);
            },
          });
          $activityAssocCampSpan.show();
        } else {
          $activityAssocCampSpan.hide();
        }

        // Create modal instance
        activityModal = M.Modal.init(activityModalElem, {
          opacity: 0.8,
          onCloseEnd() {
            const modal = M.Modal.getInstance(activityModalElem);
            modal.destroy();
            activityReloadDiv.html('');
          },
          onOpenEnd() {
            // keep all activity selected by default when the modal opens
            if( $activityLength.val() !== 'all' ) {
              const resetValue = $activityLength.children().first().val();
              $activityLength.val(resetValue).change();
            }

            if( $activityAssocCamp.val() !== 'primary' ) {
              const resetValue = $activityAssocCamp.children().first().val();
              $activityAssocCamp.val(resetValue).change();
            }

            $.ajax({
              url: wp.ajaxurl,
              method: 'POST',
              data: {
                action: 'campaign_activity_reload',
                brand_id: brandId,
                segment_id: segmentId,
                campaign_id: campaignId,
                aespotify_id: aespotifyId,
                aeapple_id: aeappleId,
                aeamazon_id: aeamazonId,
                aedeezer_id: aedeezerId,
                aeyoutube_id: aeyoutubeId,
                aeform_id: aeformId,
                aesecondaryform_id: aesecondaryformId,

                aefacebook_so_id: aefacebook_so_id,
                aetwitter_so_id: aetwitter_so_id,
                aespotify_so_id: aespotify_so_id,
                aeyoutube_so_id: aeyoutube_so_id,
                aedeezer_so_id: aedeezer_so_id,

              },
              beforeSend() {
                startContentLoading();
                activityReloadDiv.html('');
              },
              error() {
                errorToast();
              },
              success(data) {
                activityReloadDiv.html(data);
                showToast('Activity Loaded');

                $('.campaign-name.tooltipped').tooltip();
                $('.ae-not-available.tooltipped').tooltip();
              },
              complete() {
                stopContentLoading();
              }
            });
          }
        });
        // Get the modal instance and open it
        activityModal = M.Modal.getInstance(activityModalElem);
        activityModal.open();
      });
    $activityLength.off();
    $activityAssocCamp.off();
    // Init select2 element
    $activityLength.select2();
    $activityAssocCamp.select2();

    // On select2 change
    $('.activity-filter').on('select2:select', e => {
      const length = $activityLength.val();
      const assocCampaignId = $activityAssocCamp.val();
      const elem = document.querySelector('#modal-campaign-activity');
      const brandId = elem.getAttribute('data-brandid');
      const segmentId = elem.getAttribute('data-segmentid');
      const campaignId = (assocCampaignId === 'primary' || !assocCampaignId)
        ? elem.getAttribute('data-campaignid')
        : assocCampaignId;
      const div = document.querySelector(
        '#modal-campaign-activity #activity-reload'
      );
      const aespotifyId = elem.getAttribute('data-aespotifyid');
      const aeamazonId = elem.getAttribute('data-aeamazonid');
      const aeappleId = elem.getAttribute('data-aeappleid');
      const aedeezerId = elem.getAttribute('data-aedeezerid');
      const aeyoutubeId = elem.getAttribute('data-aeyoutubeid');
      const aeformId = elem.getAttribute('data-aeformid');
      const aesecondaryformId = elem.getAttribute('data-aesecondaryformid');

      const aefacebook_so_id = elem.getAttribute('data-aefacebook_so_id');
      const aetwitter_so_id = elem.getAttribute('data-aetwitter_so_id');
      const aespotify_so_id = elem.getAttribute('data-aespotify_so_id');
      const aeyoutube_so_id = elem.getAttribute('data-aeyoutube_so_id');
      const aedeezer_so_id = elem.getAttribute('data-aedeezer_so_id');

      // Run ajax function to reload activity
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'campaign_activity_reload',
          brand_id: brandId,
          segment_id: segmentId,
          length,
          campaign_id: campaignId,
          aespotify_id: aespotifyId,
          aeapple_id: aeappleId,
          aeamazon_id: aeamazonId,
          aedeezer_id: aedeezerId,
          aeyoutube_id: aeyoutubeId,
          aeform_id: aeformId,
          aesecondaryform_id: aesecondaryformId,

          aefacebook_so_id: aefacebook_so_id,
          aetwitter_so_id: aetwitter_so_id,
          aespotify_so_id: aespotify_so_id,
          aeyoutube_so_id: aeyoutube_so_id,
          aedeezer_so_id: aedeezer_so_id,

        },
        beforeSend() {
          startContentLoading();
          div.innerHTML = '';
        },
        error() {
          errorToast();
        },
        success(data) {
          div.innerHTML = data;
          showToast('Activity Loaded');
          $('.ae-not-available.tooltipped').tooltip();
        },
        complete() {
          stopContentLoading();
        }
      });
    });
  } // END if
}

// 14. Artists Page
// ==========================================================================

// Artists page ajax function responsible for running the posts per page
// radio buttons. Filtering the amount of records show in the table by the
// choice and setting up the pagination.
$('#dashboard-reload').on('change', 'input[name="artist_per_page"]', () => {
  const postsPerPage = $('input:radio[name=artist_per_page]:checked').val();
  const searchQuery = $('#artists_search').val();
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'artists_table_content',
      posts_per_page: postsPerPage,
      page: '1',
      search_query: searchQuery
    },
    error() {
      errorToast();
    },
    success(data) {
      $('#artists-reload').html(data);
    }
  });
});

// On click function for the pagination links on the Artists table page.
$(document).click(event => {
  $(event.target)
    .closest('.clickable a')
    .each(function() {
      const page = $(this).data('page');
      const postsPerPage = $('input:radio[name=artist_per_page]:checked').val();
      const searchQuery = $('#artists_search').val();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'artists_table_content',
          page,
          posts_per_page: postsPerPage,
          search_query: searchQuery
        },
        error() {
          errorToast();
        },
        success(data) {
          $('#artists-reload').html(data);
        },
        complete() {}
      });
    });
});

// AJAX search for the Artists
$('body').on('keyup', '.search-field', searchArtists);
$('body').on('change', 'input:radio[name=search_type]', searchArtists);

function searchArtists() {
  const actionFunction = $('.search-area').data('action');
  const searchSecurity = $('.search-security').val();
  const searchQuery = $('.search-field').val();
  const searchType = $('input:radio[name=search_type]:checked').val();
  const type = $('input[type=radio]:checked').val();
  const reloadId = $('.search-area').data('reload');
  const reloadDiv = $(`#${reloadId}`);
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: actionFunction,
      search_security: searchSecurity,
      search_query: searchQuery,
      search_type: searchType,
      type
    },
    error() {
      errorToast();
    },
    success(data) {
      reloadDiv.html(data);
    },
    complete() {
      init();
    }
  });
}

// AJAX save for the Artist data
$('body').on('submit', '#form-update-artist', function(e) {
  e.preventDefault();
  const formData = $(this).serialize();
  const reloadDiv = $('#reload-artist-fields');
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'artist_post_data',
      data: formData,
      userid: wp.userid
    },
    beforeSend() {
      startLoading();
    },
    error() {
      errorToast();
    },
    success(data) {
      reloadDiv.html(data);
      showToast('Saved');
    },
    complete() {
      stopLoading();
      init();
    }
  });
});

// 15. Admin Options Modal
// ==========================================================================

// AJAX function for the admin quick save modal
$('#form-admin-options').on('submit', function(e) {
  e.preventDefault();
  const data = $(this).serialize();
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'admin_options_form',
      data,
      userid: wp.userid
    },
    beforeSend() {
      startLoading();
    },
    error() {
      stopLoading();
      errorToast();
    },
    success() {
      showToast('changed');
      window.location.reload(true);
    }
  });
});

// AJAX reload the drop downs when the territory is changed to show
// the new options for labels under that territory
$('#modal-admin-options').on('change', '#admin_options_territory', function() {
  const terrValue = $(this).val();
  const adminOptionsReload = $('#admin-options-reload');
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'admin_options_select',
      terr_value: terrValue
    },
    beforeSend() {
      startLoading();
    },
    error() {
      errorToast();
    },
    success(data) {
      adminOptionsReload.html(data);
    },
    complete() {
      stopLoading();
      $(document).ready(() => {
        $('.mselect').select2();
      });
    }
  });
});

// 16. Extra
// ==========================================================================

/**
 * Forms page ajax function responsible for running the posts
 * per page radio buttons. Filtering the amount of records show
 * in the table by the choice and setting up the pagination.
 */
$('body').on('change', 'input:radio[name=form_per_page]', () => {
  const postsPerPage = $('input:radio[name=form_per_page]:checked').val();
  const searchQuery = $('#campaigns_search').val();
  const div = $('#forms-reload');
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'forms_table_content',
      posts_per_page: postsPerPage,
      page: '1',
      search_query: searchQuery
    },
    error() {
      errorToast();
    },
    success(updateContent) {
      div.html(updateContent);
    },
    complete() {
      activityLinkInit();
    }
  });
});

/**
 * AJAX function for restoring sessions
 * this will happen because on page-dashboard.php we are checking
 * if the load is not AJAX and echoing this div ID, then deleting it
 * TODO: Find a better way of doing this at some point, very low priority
 */
if ($('#ajax_get_session').length) {
  $('#ajax_get_session').remove();
  const div = $('#dashboard-reload');
  const { link } = wp;
  const campaignLink = wp.campaign_link;
  let ajaxcampaign = 'false';
  if (link === 'campaign') {
    $('body').addClass('form');
    ajaxcampaign = 'true';
  }
  $.ajax({
    url: wp.ajaxurl,
    method: 'POST',
    data: {
      action: 'dashboard_linking',
      link: wp.link,
      ajaxcampaign
    },
    beforeSend() {},
    error() {
      errorToast();
    },
    success(data) {
      div.html(data);
    },
    complete() {
      init();
      if ($('#dashboard-reload .bunit').length) {
        initBU();
      }
      if ($('#campaign-menu').length) {
        $('body').addClass('form');
        initCampaigns();
        if (campaignLink === 'design') {
          initDesign();
        }
      }
    }
  });
}

initTT();

/**
 * Remove Field Functionality
 */
removeFieldInit();

function initCampaignActivityIndication() {
  campaignActivityIndication();
  setInterval(() => campaignActivityIndication(), 20000);
  setInterval(() => campaignActivityIndRotate(), 1000);
}

function campaignActivityIndication() {
  const campId = $('input[name="campaign_id"]');
  if(campId.length) {
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'campaign_activity_indication',
        campaign_id: campId.val(),
      },
      error() {
        errorToast();
      },
      success(res) {
        const $indEl = $('.activity-indicator');
        const $listEl = $('.activity-indicator-popup-list');
        const $numEl = $('.inside-circle-content');
        if(res.data.length) {
          $indEl.css('display', 'inline-block');
          $numEl.html(res.data.length);
          $listEl.html('');
          $.each(res.data, function (i, name) {
            $listEl.append('<li>' + name + '</li>');
          })
        } else {
          $indEl.hide();
          $listEl.html('');
          $numEl.html('');
        }
      }
    });
  }
}

let cairStep = 0;
function campaignActivityIndRotate() {
  const $indEl = $('.activity-indicator');
  if ($indEl.length) {
    const $faceEl = $indEl.find('.outer-circle-content');
    const $backEl = $indEl.find('.outer-circle-icon');
    switch (cairStep) {
      case 0:
        $faceEl.removeClass('rotate5').addClass('rotate6');
        $backEl.hide().removeClass('rotate4').addClass('rotate2').show();
        cairStep++;
        break;
      case 1:
        $faceEl.hide().removeClass('rotate6').addClass('rotate4');
        $backEl.removeClass('rotate2').addClass('rotate3');
        cairStep++;
        break;
      case 2:
      case 3:
      case 6:
        cairStep++;
        break;
      case 4:
        $faceEl.hide().removeClass('rotate6').addClass('rotate4');
        $backEl.removeClass('rotate3').addClass('rotate4');
        cairStep++;
        break;
      case 5:
        $faceEl.show().removeClass('rotate4').addClass('rotate5');
        $backEl.hide().removeClass('rotate4').addClass('rotate2');
        cairStep++;
        break;
      case 7:
        cairStep = 0;
        break;
    }
  }
}

function convertAmPmTo24Hour(time) {
  let hours = Number(time.match(/^(\d+)/)[1]);
  const minutes = Number(time.match(/:(\d+)/)[1]);
  const AMPM = time.match(/\s(.*)$/)[1];
  if(AMPM === "PM" && hours<12) hours = hours+12;
  if(AMPM === "AM" && hours===12) hours = hours-12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if(hours<10) sHours = "0" + sHours;
  if(minutes<10) sMinutes = "0" + sMinutes;

  return sHours + ":" + sMinutes;
}

const intercomAppId = "hmfnwger";
intercom(intercomAppId);

