import 'jquery-validation';
import 'select2';
import {toSafeInteger, parseInt} from 'lodash-es';
import removeFieldInit from '../../utils/removeFieldInit';
import reloadPreview from '../../builder/design/reloadPreview';
import forEach from '../../utils/forEach';
import initDesign from '../../builder/design';
import {errorToast} from '../../utils/toasts';
import {
  startLoading,
  startContentLoading,
  stopLoading,
  stopContentLoading
} from '../../utils/loading';
import noUiSlider from 'materialize-css/extras/noUiSlider/nouislider';
import contentColorPickers from "./contentColorPickers";
import $ from "jquery";
import moment from "moment-timezone";

/* eslint-disable new-cap */
/* global wNumb, initCampaigns, wp, wp.ajaxulr */

let stickyCard;

function startWindow(open) {
  let previewWindow;
  let t;

  if (open === true) {
    const href = $('#campaign-step-reload #preview-fullscreen').attr('href');
    if (href) {
      previewWindow = window.open(href, '_blank', 'height=768,width=1366');
      resetTimer();
    }
  }

  function isWindow(obj) {
    if (typeof window.constructor === 'undefined') {
      return obj instanceof window.constructor;
    }
    if (typeof obj.window !== 'undefined') {
      return obj.window === obj;
    }
    return false;
  }

  function refreshWindow() {
    if (isWindow(previewWindow)) {
      if (previewWindow.closed) {
        return false;
      }
      previewWindow.location.reload();
      return true;
    }
    return false;
  }

  function alertFinished() {
    M.toast({
      html: 'Preview Window Closed... Ending',
      classes: 'success-toast'
    });
  }

  function stopTimer() {
    clearTimeout(t);
    t = setTimeout(alertFinished, 500);
  }

  function alertRefreshed() {
    const refreshed = refreshWindow();
    if (refreshed === true) {
      M.toast({
        html: 'Preview Refreshed',
        classes: 'success-toast'
      });
      resetTimer();
    } else {
      stopTimer();
    }
  }

  function resetTimer() {
    clearTimeout(t);
    t = setTimeout(alertRefreshed, 15000);
  }
}

export default function () {
  removeFieldInit();

  const $inputFileElem = $('#modal-media-library input[type=file]');
  const $libraryUploadElem = $('#media-library-upload');
  const $submitMediaSelectedElem = $('#submit-media-selected');

  $inputFileElem.off();
  $libraryUploadElem.off();
  $('#campaign-step-reload .media-edit').off();
  $submitMediaSelectedElem.off();
  $('#media-library-selected').off();
  $('#campaign-step-reload .open-media-library').off();

  if (typeof stickyCard !== 'undefined') {
    stickyCard.destroy();
  }

  M.updateTextFields();

  const $primaryFonts = $('#font-primary-selector');
  const $secondaryFonts = $('#font-secondary-selector');
  const $tabsStickyElem = $('#tabs-sticky');

  const $stepReload = $('#campaign-step-reload');
  const $bgColorCpElem = $('#bg-color-cp');
  const $headerTextBgCpElem = $('#header-text-bg-cp');
  const $headerTextCpElem = $('#header-text-cp');
  const $pageTextCpElem = $('#page-text-cp');
  const $footerBgCpElem = $('#footer-bg-cp');
  const $footerTextCpElem = $('#footer-text-cp');
  const $clickableCpElem = $('#clickable-cp');
  const $clickableHoverCpElem = $('#clickable-hover-cp');
  const $formLabelCpElem = $('#form-label-cp');
  const $formFieldTextCpElem = $('#form-field-text-cp');
  const $formFieldBgCpElem = $('#form-field-bg-cp');
  const $formContBgCpElem = $('#form-cont-bg-cp');
  const $hoverDspButtonElem = $('#hover-dsp-button-cp');

  $primaryFonts.select2();
  $secondaryFonts.select2();

  $primaryFonts.on('select2:select', e => {
    const {data: {text}} = e.params;
    $('head').append('<link rel="stylesheet" href="//cdn.sonymusicfans.com/googlefonts/' + text.replace(/\s+/g, '-').toLowerCase() + '/400.css" type="text/css" />');
    $('head').append('<link rel="stylesheet" href="//cdn.sonymusicfans.com/googlefonts/' + text.replace(/\s+/g, '-').toLowerCase() + '/700.css" type="text/css" />');
    $('#primary-demo').removeClass('hide');
    $('#primary-ff').css('font-family', `"${text}"`);
    $('#primary-font').val(text);
  });

  $secondaryFonts.on('select2:select', e => {
    const {data: {text}} = e.params;
    $('head').append('<link rel="stylesheet" href="//cdn.sonymusicfans.com/googlefonts/' + text.replace(/\s+/g, '-').toLowerCase() + '/400.css" type="text/css" />');
    $('head').append('<link rel="stylesheet" href="//cdn.sonymusicfans.com/googlefonts/' + text.replace(/\s+/g, '-').toLowerCase() + '/700.css" type="text/css" />');
    $('#secondary-demo').removeClass('hide');
    $('#secondary-ff').css('font-family', `"${text}"`);
    $('#secondary-font').val(text);
  });

  if ($tabsStickyElem.length) {
    const width = $('#design-builder').outerWidth();
    $tabsStickyElem.css('width', `${width}px`);
    const elem = document.querySelector('#tabs-sticky');
    M.Pushpin.init(elem, {
      top: $tabsStickyElem.offset().top
    });
  }

  $('#campaign-step-reload #preview-fullscreen')
    .off()
    .on('click', e => {
      e.preventDefault();
      startWindow(true);
      M.toast({
        html: 'Starting Window Auto Refresh',
        classes: 'success-toast'
      });
    });

  // Save CTA Expired text on focusout of input
  $('.integrations-button-text')
    .off()
    .on('focusout', function () {
      const buttonText = $(this).val();
      const metaField = $(this).attr('id');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_integrations_buttons',
          button_text: buttonText,
          meta_field: metaField
        },
        error() {
          errorToast();
        },
        complete() {
          reloadPreview();
        }
      });
    });

  // Save CTA Expired text on focusout of input
  $('#campaign_design_cta_expired')
    .off()
    .on('focusout', () => {
      const value = $('#campaign_design_cta_expired').val();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_expired_cta_text',
          value
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'CTA Button Text Changed',
            classes: 'success-toast'
          });
        },
        complete() {
          reloadPreview();
        }
      });
    });

  $('#campaign_design_cta_link_expired')
    .off()
    .on('focusout', () => {
      const value = $('#campaign_design_cta_link_expired').val();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_expired_cta_link',
          value
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'CTA Button Link Changed',
            classes: 'success-toast'
          });
        },
        complete() {
          reloadPreview();
        }
      });
    });

  $('#campaign-step-reload #minimize-preview')
    .off()
    .on('click', e => {
      e.preventDefault();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_preview_state',
          preview: 'minimize'
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Layout Changed',
            classes: 'success-toast'
          });
        },
        complete() {
          const tabLink = $('#design-builder .tabs-top a.active').data(
            'tablink'
          );
          const div = $('#campaign-step-reload #design-tab-reload');
          $.ajax({
            url: wp.ajaxurl,
            method: 'POST',
            data: {
              action: 'design_tab_reload',
              tab_link: tabLink
            },
            error() {
              errorToast();
            },
            success(data) {
              div.html(data);
            },
            complete() {
              initDesign();
              reloadPreview();
            }
          });
        }
      });
    });

  $('#campaign-step-reload #maximize-preview')
    .off()
    .on('click', e => {
      e.preventDefault();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_preview_state',
          preview: 'maximize'
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Layout Changed',
            classes: 'success-toast'
          });
        },
        complete() {
          const tabLink = $('#design-builder .tabs-top a.active').data(
            'tablink'
          );
          const div = $('#campaign-step-reload #design-tab-reload');
          $.ajax({
            url: wp.ajaxurl,
            method: 'POST',
            data: {
              action: 'design_tab_reload',
              tab_link: tabLink
            },
            error() {
              errorToast();
            },
            success(data) {
              div.html(data);
            },
            complete() {
              initDesign();
              reloadPreview();
            }
          });
        }
      });
    });

  $('#campaign-step-reload #layout-selector').on(
    'change',
    'input[type=radio]',
    function () {
      const meta = $('#campaign-step-reload #layout-selector').data('meta');
      const value = $(this).val();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_save_layout',
          meta,
          value
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Layout Change Saved',
            classes: 'success-toast'
          });
        },
        complete() {
          $('.reset-img-pos').trigger('click');
          reloadPreview();
          if (value === 'fullscreen') {
            $('.fullscreen-no').addClass('hide');
          } else {
            $('.fullscreen-no').removeClass('hide');
          }
        }
      });
    }
  );

  $stepReload
    .off()
    .on('click', '.media-edit', function (e) {
      e.preventDefault();
      const imgid = $(this).data('imgid');
      const originalImage = $(`#${imgid}`);
      const originalImageSrc = originalImage.attr('src');
      const imgEditor = Pixie.setOptions({
        onSaveButtonClick() {
          imgEditor.save();
        },
        onSave(data, img) {
          const base64 = data;
          const metaAttr = img.attr('data-displaymeta');
          $.ajax({
            url: wp.ajaxurl,
            type: 'POST',
            data: {
              action: 'media_save_edited',
              meta: metaAttr,
              image: base64
            },
            beforeSend() {
              startLoading();
            },
            error() {
              errorToast();
            },
            success() {
              M.toast({
                html: 'Edited Image Saved',
                classes: 'success-toast'
              });
            },
            complete() {
              const tabLink = $('#design-builder .tabs-top a.active').data(
                'tablink'
              );
              const div = $('#campaign-step-reload #design-tab-reload');
              $.ajax({
                url: wp.ajaxurl,
                method: 'POST',
                data: {
                  action: 'design_tab_reload',
                  tab_link: tabLink
                },
                error() {
                  errorToast();
                },
                success(response) {
                  div.html(response);
                },
                complete() {
                  initDesign();
                  stopLoading();
                }
              });
            }
          });
        },
        onError() {
          errorToast();
        }
      });
      imgEditor.open({
        url: originalImageSrc,
        image: originalImage
      });
    });

  $stepReload.on('click', '.open-media-library', function (e) {
    e.preventDefault();
    const postID = $(this).data('postid');
    const meta = $(this).data('displaymeta');
    const mediaLib = document.querySelector('#modal-media-library');
    M.Modal.init(mediaLib, {
      dismissible: false,
      opacity: 0.8,
      onOpenEnd() {
        const div = $('#media-library-reload');
        $.ajax({
          url: wp.ajaxurl,
          method: 'POST',
          data: {
            action: 'media_library_load',
            media_show: 'campaign',
            campaign: postID,
            meta
          },
          beforeSend() {
            startContentLoading();
          },
          error() {
            errorToast();
          },
          success(data) {
            div.html(data);
          },
          complete() {
            stopContentLoading();
          }
        });
      },
      onCloseEnd() {
        const mediaModal = M.Modal.getInstance(mediaLib);
        mediaModal.destroy();
      }
    });
    const mediaModal = M.Modal.getInstance(mediaLib);
    mediaModal.open();
  });

  $('body .remove-img').on('click', function (e) {
    e.preventDefault();
    const meta = $(this).data('displaymeta');
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'media_unset',
        meta
      },
      error() {
        errorToast();
      },
      success() {
        M.toast({
          html: 'Image Removed From Campaign',
          classes: 'success-toast'
        });
      },
      complete() {
        const tabLink = $('#design-builder .tabs-top a.active').data('tablink');
        const div = $('#campaign-step-reload #design-tab-reload');
        $.ajax({
          url: wp.ajaxurl,
          method: 'POST',
          data: {
            action: 'design_tab_reload',
            tab_link: tabLink
          },
          error() {
            errorToast();
          },
          success(data) {
            div.html(data);
          },
          complete() {
            initDesign();
          }
        });
      }
    });
  });

  $('#design-tab-reload .variable-music-tabs')
    .find('div[data-mptab]')
    .off()
    .on('click', function (e) {
      const $mpTab = $(this);
      variableMusicTabReload($mpTab);
    });

  variableMusicTabReload($('div[data-mptab].active'));

  function variableMusicTabReload($mpTab) {
    const tabLink = $mpTab.attr('data-mptab');
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'variable_music_tab_reload',
        player: tabLink
      },
      success() {
        reloadPreview();
      },
      error() {
        errorToast();
      }
    });
  }

  $libraryUploadElem.on('submit', function (e) {
    e.preventDefault();
    const fileData = $('#upload_media_file').prop(
      'files'
    )[0];
    const meta = $('#media-library-selected').data('meta');
    const data = $(this).serialize();
    const formdata = new FormData();
    formdata.append('upload_media_file', fileData);
    formdata.append('data', data);
    formdata.append('action', 'upload_media');
    formdata.append('meta', meta);
    const div = $('#media-library-reload');
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: formdata,
      processData: false,
      contentType: false,
      beforeSend() {
        startContentLoading();
      },
      error() {
        errorToast();
      },
      success(response) {
        div.html(response);
      },
      complete() {
        stopContentLoading();
        $('#media-library-reload input[type=radio]:first').attr(
          'checked',
          true
        );
      }
    });
  });

  $inputFileElem.on('change', () => {
    $libraryUploadElem.submit();
  });

  $('#modal-media-library')
    .off()
    .on('submit', '#media-library-selected', function (e) {
      e.preventDefault();
      const data = $(this).serialize();
      const $librarySelectedElem = $('#media-library-selected');
      const meta = $librarySelectedElem.data(
        'meta'
      );
      const div = $('#campaign-step-reload #design-tab-reload');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'media_selected',
          data,
          meta
        },
        beforeSend() {
          startContentLoading();
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Image Selected',
            classes: 'success-toast'
          });
        },
        complete() {
          const mediaLib = document.querySelector('#modal-media-library');
          const mediaModal = M.Modal.getInstance(mediaLib);
          mediaModal.close();
          stopContentLoading();
          const tabLink = $('#design-builder .tabs-top a.active').data(
            'tablink'
          );
          $.ajax({
            url: wp.ajaxurl,
            method: 'POST',
            data: {
              action: 'design_tab_reload',
              tab_link: tabLink
            },
            error() {
              errorToast();
            },
            success(dataResp) {
              div.html(dataResp);
            },
            complete() {
              $inputFileElem.off();
              $libraryUploadElem.off();
              $('#campaign-step-reload .media-edit').off();
              $submitMediaSelectedElem.off();
              $librarySelectedElem.off();
              $('#campaign-step-reload .open-media-library').off();
              initDesign();
            }
          });
        }
      });
    });

  $submitMediaSelectedElem.on('click', () => {
    $('#media-library-selected').submit();
  });

  $('.designer-img-positioning').each(function () {
    initSliderPanel($(this));
  });

  /**
   * Slider panel for image editor
   * @param $sliderPanel
   */
  function initSliderPanel($sliderPanel) {
    const $sliderX = $sliderPanel.find('.range-x');
    const $sliderY = $sliderPanel.find('.range-y');
    const $sliderScale = $sliderPanel.find('.range-scale');
    const sliderX = $sliderX[0];
    const sliderY = $sliderY[0];
    const sliderScale = $sliderScale[0];

    const pageType = $sliderPanel.data('pagetype');
    const settingsKey = $sliderPanel.data('settingskey');

    const scaleStep = 5;
    const yStep = 5;
    const xStep = 5;

    initSliderScale();
    initSliderX();
    initSliderY();

    function initSliderScale() {
      const isInit = $sliderScale.hasClass('noUi-target');
      let initial = 1;
      if ($sliderScale.data('initial') >= 0 && $sliderScale.data('initial') <= 2) {
        initial = $sliderScale.data('initial') * 100;
      }

      if (!isInit) {
        noUiSlider.create(sliderScale, {
          start: [initial],
          tooltips: false,
          behaviour: 'snap',
          step: scaleStep,
          range: {
            min: 0,
            max: 200
          },
          pips: {
            mode: 'positions',
            values: [],
            density: 5,
            stepped: true
          },
          ariaFormat: wNumb({
            decimals: 0
          }),
          format: wNumb({
            decimals: 0
          })
        });

        sliderScale.noUiSlider.on('set', function () {
          sliderUpdate();
        });

        sliderScale.noUiSlider.on('change', function () {
          sliderUpdate();
        });
      }
    }

    function initSliderX() {
      const isInit = $sliderX.hasClass('noUi-target');
      const initial = toSafeInteger($sliderX.data('initial')) + 100;

      if (!isInit) {
        noUiSlider.create(sliderX, {
          start: [initial],
          tooltips: false,
          behaviour: 'snap',
          step: xStep,
          range: {
            min: 0,
            max: 100
          },
          pips: {
            mode: 'positions',
            values: [],
            density: 5,
            stepped: true
          },
          ariaFormat: wNumb({
            decimals: 0
          }),
          format: wNumb({
            decimals: 0
          })
        });

        sliderX.noUiSlider.on('set', function () {
          sliderUpdate();
        });

        sliderX.noUiSlider.on('change', function () {
          sliderUpdate();
        });
      }
    }

    function initSliderY() {
      const initial = toSafeInteger($sliderY.data('initial')) + 100;
      const isInit = $sliderY.hasClass('noUi-target');

      if (!isInit) {
        noUiSlider.create(sliderY, {
          start: [initial],
          tooltips: false,
          behaviour: 'snap',
          direction: 'rtl',
          step: yStep,
          range: {
            min: 0,
            max: 100
          },
          pips: {
            mode: 'positions',
            values: [],
            density: 5,
            stepped: true
          },
          ariaFormat: wNumb({
            decimals: 0
          }),
          format: wNumb({
            decimals: 0
          })
        });

        sliderY.noUiSlider.on('set', function () {
          sliderUpdate();
        });

        sliderY.noUiSlider.on('change', function () {
          sliderUpdate();
        });
      }
    }

    function sliderUpdate() {
      let scaleVal = sliderScale.noUiSlider.get() / 100;
      let posx = sliderX.noUiSlider.get() - 100;
      let posy = sliderY.noUiSlider.get() - 100;

      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'save_image_position',
          settings_key: settingsKey,
          scale: scaleVal,
          positionx: posx,
          positiony: posy,
          page_type: pageType
        },
        success() {
          reloadPreview();
        }
      });
    }

    $sliderPanel.find('.range-button').each(function () {
      $(this).on('click', function () {
        const type = $(this).data('type');
        const direction = $(this).data('direction');
        let value;

        switch (type) {
          case 'scale':
            value = sliderScale.noUiSlider.get();
            value = toSafeInteger(value);

            if (direction === 'pos') {
              if (value === 200) {
                return;
              }

              value += scaleStep;
            } else {
              if (value === 0) {
                return;
              }

              value -= scaleStep;
            }

            sliderScale.noUiSlider.set(value);
            break;
          case 'y':
            value = sliderY.noUiSlider.get();
            value = toSafeInteger(value);

            if (direction === 'up') {
              if (value === 100) {
                return;
              }

              value -= yStep;
            } else {
              if (value === 0) {
                return;
              }

              value += yStep;
            }

            sliderY.noUiSlider.set(value);
            break;
          case 'x':
            value = sliderX.noUiSlider.get();
            value = toSafeInteger(value);

            if (direction === 'right') {
              if (value === 100) {
                return;
              }

              value += xStep;
            } else {
              if (value === 0) {
                return;
              }

              value -= xStep;
            }

            sliderX.noUiSlider.set(value);
            break;
        }
      });
    });

    const resetImgPos = () => {
      sliderScale.noUiSlider.set(100);
      sliderY.noUiSlider.set(50);
      sliderX.noUiSlider.set(50);
      sliderUpdate();
    };

    $sliderPanel.find('.reset-img-pos').on('click', function () {
      resetImgPos();
    });
  }

  $('.toggle-slider-panel').on('click', function () {
    const $elem = $(this);
    const key = $elem.data('forpanel');
    const $title = $('.campaign-field-title[data-sliderheader="' + key + '"]');
    const shift = $title.offset().top - 100;
    $('.designer-img-positioning[data-settingskey="' + key + '"]').toggle();
    $elem.find('.slider-panel-show').toggle();
    $elem.find('.slider-panel-hide').toggle();
    $([document.documentElement, document.body]).animate({
      scrollTop: shift
    }, 200);
  });

  const $previewCard = $('#campaign-step-reload #campaign-preview-card');
  if ($previewCard.length) {
    const $primary = $('#campaign-step-reload .cp-primary');
    const $secondary = $('#campaign-step-reload .cp-secondary');
    const sticky = $previewCard[0];
    const offset = $previewCard.offset().top;
    stickyCard = M.Pushpin.init(sticky, {
      top: parseInt(offset, 10),
      onPositionChange(pos) {
        if (pos === 'pinned') {
          const htmlPrimary = $primary.html();
          $primary.html('');
          $secondary.html(htmlPrimary);
          $tabsStickyElem.addClass('pinned');
          $tabsStickyElem.removeClass('pin-top');
        }
        if (pos === 'pin-top') {
          const htmlSecondary = $secondary.html();
          $secondary.html('');
          $primary.html(htmlSecondary);
          $tabsStickyElem.removeClass('pinned');
          $tabsStickyElem.addClass('pin-top');
        }
        contentColorPickers();
      }
    });

    const instance = $('.tabs')[0];
    M.Tabs.init(instance);

    const devices = {
      desktop: {
        width: 1600,
        height: 900,
        scale: 1
      },
      mobile: {
        width: 375,
        height: 559,
        scale: 1
      }
    };
    const $previewCardElem = $('#campaign-preview-card');
    const $desktopElem = $('#desktop');
    const url = $previewCardElem.data('url');
    generatePreview($desktopElem);

    /**
     * Window resize
     */
    let resizeId;
    const win = $(window);
    win.on('resize', () => {
      clearTimeout(resizeId);
      resizeId = setTimeout(doneResizing, 500);
    });

    function doneResizing() {
      // If the campaign preview card is in the DOM
      if ($previewCardElem.length) {
        reloadPreview();
        initDesign();
        // Regenerate the preview
        const tab = $('#campaign-step-reload .card-tabs a.active').attr('href');
        generatePreview($(tab));
      } else {
        win.off('resize');
      }
    }

    $('#campaign-step-reload .card-tabs a').on('click', function () {
      let tab = $(this).attr('href');
      tab = $(tab);
      resetPreviews();
      generatePreview(tab);
    });

    function resetPreviews() {
      $desktopElem.html('');
      $('#mobile').html('');
    }

    function generatePreview(tab) {
      const device = tab.attr('id');
      const contentWidth = $('#campaign-preview-reload').innerWidth();
      if (devices[device].width > contentWidth) {
        devices[device].scale = contentWidth / devices[device].width;
      }
      let html = `<div class="thumbnail-container" style="width: calc(${
        devices[device].width
      }px * ${devices[device].scale}); height: calc(${
        devices[device].height
      }px * ${devices[device].scale});">`;
      html += `<div class="thumbnail" style="width:${
        devices[device].width
      }px; height:${devices[device].height}px; -ms-zoom: ${
        devices[device].scale
      }; -moz-transform: scale(${devices[device].scale}); -o-transform: scale(${
        devices[device].scale
      }); -webkit-transform: scale(${devices[device].scale});">`;
      html += `<iframe src="${url}" onload="this.style.opacity = 1" style="border: 0; width:${
        devices[device].width
      }px; height:${devices[device].height}px;"></iframe>`;
      html += '</div>';
      html += '</div>';
      tab.html(html).show();
    }
  }

  $('.tab-content-editor').on('froalaEditor.save.after', () => {
    reloadPreview();
  });

  const fontFamilyOb = {};
  const priF = $('#dashboard-reload #primary-font').val();
  const secF = $('#dashboard-reload #secondary-font').val();
  fontFamilyOb[
    'apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol'
    ] = 'Device Defaults';
  fontFamilyOb[priF] = priF;
  fontFamilyOb[secF] = secF;

  const froalaToolBar = [
    'bold',
    'italic',
    'underline',
    '|',
    'fontFamily',
    'fontSize',
    'color',
    '|',
    'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    '-',
    'insertLink',
    'insertImage',
    'CDTimerButton',
    'insertSocialIcons',
    'insertSocialShare',
    'embedon',
    'insertTable',
    '|',
    'insertHR',
    'selectAll',
    'clearFormatting',
    '|',
    'html',
    '|',
    'undo',
    'redo'
  ];

  const codeMirrorOpts = {
    theme: 'material',
    indentWithTabs: true,
    lineNumbers: true,
    lineWrapping: true,
    mode: 'text/html',
    tabMode: 'tabs',
    smartIndent: true
  };

  const imgEditorButtons = [
    'imageReplace',
    'imageAlign',
    'imageCaption',
    'imageRemove',
    '|',
    'imageAlt',
    'imageSize',
    'undo',
    'redo'
  ];

  const fontSizes = [
    '8',
    '10',
    '12',
    '14',
    '18',
    '24',
    '30',
    '36',
    '48',
    '60',
    '72'
  ];

  $.FroalaEditor.DefineIcon('insertSocialIcons', {
    NAME: 'share-alt'
  });
  $.FroalaEditor.RegisterCommand('insertSocialIcons', {
    title: 'Add Social Link Icons',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
      this.html.insert(
        '<div class="social-links"><span style="font-size: 15px;">{{social_links_text}}</span></div>'
      );
      this.html.insert(
        '<div class="social-links"><span style="font-size: 30px;">{{social_links}}</span></div>'
      );
    }
  });

  $.FroalaEditor.DefineIcon('insertSocialShare', {
    NAME: 'bullhorn'
  });
  $.FroalaEditor.RegisterCommand('insertSocialShare', {
    title: 'Add Social Share Icons',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
      this.html.insert(
        '<div class="social-share"><span style="font-size: 15px;">{{social_share_text}}</span></div>'
      );
      this.html.insert(
        '<div class="social-share"><span style="font-size: 30px;">{{social_share}}</span></div>'
      );
    }
  });

  $.FroalaEditor.DefineIcon('insertMusicPlayer', {
    NAME: 'music'
  });
  $.FroalaEditor.RegisterCommand('insertMusicPlayer', {
    title: 'Add Variable Music Player',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
      this.html.insert(
        '<div class="variable-music-player">{{variable_music_player_embed}}</div>'
      );
    }
  });

  $.extend($.FroalaEditor.POPUP_TEMPLATES, {
    "CDTimerPlugin.popup": '[_BUTTONS_][_CUSTOM_LAYER_]'
  });

  $.extend($.FroalaEditor.DEFAULTS, {
    popupButtons: ['CDTimerIconPopupClose'],
  });

  const $cdTimerTZClone = $('.cd_timer_tz').clone();
  const defaultCDTimerFormat = $("#cd-timer-format").val();
  const cdTimerExpText = $("#cd-timer-expired-text").val();

  $.FroalaEditor.PLUGINS.CDTimerPlugin = function (editor) {
    function initPopup () {
      let popup_buttons = '';

      if (editor.opts.popupButtons.length > 1) {
        popup_buttons += '<div class="fr-buttons">';
        popup_buttons += editor.button.buildList(editor.opts.popupButtons);
        popup_buttons += '</div>';
      }

      const dtObj = splitDateTime($("#cd-timer-datatime").val());

      const template = {
        buttons: popup_buttons,
        custom_layer: '<div class="cd-timer-block">' +
          '<span class="cd-timer-datepicker-block">' +
          '  <span class="cd-timer-datepicker-input-block"><label class="dtp-label">Month:</label><input type="number" min="1" max="12" class="cd-timer-datepicker-input cd-timer-month-input browser-default" placeholder="mm" value="'+dtObj.month+'"></span> / ' +
          '  <span class="cd-timer-datepicker-input-block"><label class="dtp-label">Day:</label><input type="number" min="1" max="31" class="cd-timer-datepicker-input cd-timer-day-input browser-default" placeholder="dd" value="'+dtObj.day+'"></span> / ' +
          '  <span class="cd-timer-datepicker-input-block"><label class="dtp-label">Year:</label><input type="number" min="' + new Date().getFullYear() + '" class="cd-timer-datepicker-input cd-timer-year-input browser-default" placeholder="yyyy" value="'+dtObj.year+'"></span>' +
          '</span>' +
          '<a class="edit-cd-timer" data-task="date" style="margin-bottom:0.6rem">' +
          '  <i class="fal fa-calendar"></i> Edit Date' +
          '</a><br>' +
          '<span class="cd-timer-timepicker-block">' +
          '  <span class="cd-timer-timepicker-input-block"><label class="dtp-label">Hour:</label><input type="number" min="00" max="24" class="cd-timer-timepicker-input cd-timer-hour-input browser-default" placeholder="hh" value="'+dtObj.hour+'"></span> : ' +
          '  <span class="cd-timer-timepicker-input-block"><label class="dtp-label">Minute:</label><input type="number" min="00" max="60" class="cd-timer-timepicker-input cd-timer-minute-input browser-default" placeholder="mm" value="'+dtObj.minute+'"></span> : ' +
          '  <span class="cd-timer-timepicker-input-block"><label class="dtp-label">Second:</label><input type="number" min="00" max="60" class="cd-timer-timepicker-input cd-timer-second-input browser-default" placeholder="ss" value="'+dtObj.second+'"></span>' +
          '</span>' +
          '<div class="edit-cd-timer-tz-block input-field">' +
          $('.cd_timer_tz').html() +
          '</div>' +
          '<div class="edit-cd-timer-format-block">' +
          '  <label class="radio" for="cd_timer_format_wdhms">' +
          '    <input id="cd_timer_format_wdhms" name="cd_timer_format" type="radio" value="wdhms" ' +
          ((defaultCDTimerFormat === 'wdhms') ? ' checked="checked" ' : '') +
          '      class="with-gap cd_timer_format_wdhms cd_timer_format">' +
          '    <span>Weeks/Days/Hours/Minutes/Seconds</span>' +
          '  </label>' +
          '  <label class="radio" for="cd_timer_format_dhms">' +
          '    <input id="cd_timer_format_dhms" name="cd_timer_format" type="radio" value="dhms" ' +
          ((defaultCDTimerFormat === 'dhms') ? ' checked="checked" ' : '') +
          '      class="with-gap cd_timer_format_dhms cd_timer_format">' +
          '    <span>Days/Hours/Minutes/Seconds</span>' +
          '  </label>' +
          '</div>' +
          '<div class="cd-timer-expiration-text-block">' +
          '  <label class="dtp-label">Expiration:</label><input type="text" class="cd-timer-expiration-input browser-default" placeholder="0d 0h 0m 0s" value="'+cdTimerExpText+'">' +
          '  <span class="tooltiptext">Text to display once timer runs out</span>' +
          '</div>' +
          '</div>' +
          '<div class="fr-action-buttons"><button data-cmd="CDTimerInsert" class="fr-command fr-submit" role="button" href="#" tabindex="4" type="button">Insert</button></div>' +
          '</div>'
      };

      return editor.popups.create('CDTimerPlugin.popup', template);
    }

    function showCDTimerPopup () {
      console.log("showCDTimerPopup begin");
      let $popup = editor.popups.get('CDTimerPlugin.popup');

      console.log($popup);
      if (!$popup) $popup = initPopup();

      editor.popups.setContainer('CDTimerPlugin.popup', editor.$tb);

      const $btn = editor.$tb.find('.fr-command[data-cmd="CDTimerButton"]');

      const left = $btn.offset().left + $btn.outerWidth() / 2;
      const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

      console.log('CDTimerPlugin.popup', left, top, $btn.outerHeight());
      editor.popups.show('CDTimerPlugin.popup', left, top, $btn.outerHeight());

      const $cdTimerBlock = editor.$box.find('.edit-cd-timer-tz-block');

      $cdTimerBlock.html($cdTimerTZClone);
      $cdTimerBlock.find('.cd_timer_tz').show();

      const defaultCDTimerFormat = $("#cd-timer-format").val();
      editor.$box.find('.cd_timer_format_'+defaultCDTimerFormat).prop("checked", true);
    }

    function hideCDTimerPopup () {
      editor.popups.hide('CDTimerPlugin.popup');
    }

    return {
      showPopup: showCDTimerPopup,
      hidePopup: hideCDTimerPopup
    }
  };

  $.FroalaEditor.DefineIcon('CDTimerIcon', { NAME: 'clock'});
  $.FroalaEditor.RegisterCommand('CDTimerButton', {
    title: 'Countdown Timer',
    icon: 'CDTimerIcon',
    undo: false,
    focus: true,
    plugin: 'CDTimerPlugin',
    callback: function () {
      this.CDTimerPlugin.showPopup();
    }
  });

  $.FroalaEditor.RegisterCommand('CDTimerInsert', {
    title: 'Countdown Timer Insert',
    undo: false,
    focus: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert(
        '<div class="countdown-timer">{{variable_countdown_timer}}</div>'
      );
      this.CDTimerPlugin.hidePopup();
    }
  });

  $.FroalaEditor.DefineIcon('CDTimerIconPopupClose', { NAME: 'times' });
  $.FroalaEditor.RegisterCommand('CDTimerIconPopupClose', {
    title: 'Close',
    undo: false,
    focus: false,
    callback: function () {
      this.CDTimerPlugin.hidePopup();
    }
  });

  const customHtmlAllowedAttrs = [
    'accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency',
    'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border',
    'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content',
    'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir',
    'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder',
    'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop',
    'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method',
    'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping',
    'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required',
    'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size',
    'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex',
    'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap', 'allow'
  ];

  const froalaBaseOptions = {
    key: wp.froala_api,
    fontFamily: fontFamilyOb,
    charCounterCount: false,
    toolbarSticky: false,
    toolbarButtons: froalaToolBar,
    fontSize: fontSizes,
    fontSizeDefaultSelection: '14',
    codeMirrorOptions: codeMirrorOpts,
    imageEditButtons: imgEditorButtons,
    imageDefaultWidth: 0,
    imageUploadParams: {
      action: 'froala_upload_files'
    },
    imageManagerLoadParams: {
      action: 'froala_image_manager'
    },
    imageUploadURL: wp.ajaxurl,
    imageManagerLoadURL: wp.ajaxurl,
    saveInterval: 2500,
    saveURL: wp.ajaxurl,
    saveParam: 'content',
    htmlAllowedAttrs: customHtmlAllowedAttrs,
  };

  $('#main-content-above-presave')
    .on('froalaEditor.initialized', function (e, editor) {
      editor.events.bindClick($('body'),
        '#design-builder button.datepicker-done, #design-builder button.datepicker-cancel, #design-builder button.datepicker-day-button',
        function () {
          editor.CDTimerPlugin.showPopup();
        });
    }).froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_above_presave'
    }
  });

  $('#main-content-below-presave').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_below_presave'
    }
  });

  $('#main-content-above-landing-form').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_above_landing_form'
    }
  });

  $('#main-content-below-lf-presave').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_below_lf_presave'
    }
  });

  $('#main-content-below-landing-form').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_below_landing_form'
    }
  });

  $('#main-content-above-youtube').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_above_youtube'
    }
  });

  $('#main-content-below-youtube').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_below_youtube'
    }
  });

  $('#main-content-above-quiz-start').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_above_quiz_start'
    }
  });

  $('#main-content-below-quiz-start').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_below_quiz_start'
    }
  });

  $('#main-content-above-sls-start').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_above_sls_start'
    }
  });

  $('#main-content-below-sls-start').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_below_sls_start'
    }
  });

  for (let q = 0; q < 5; q++) {
    $(`#main-content-quiz-question-above-content-${q}`).froalaEditor({
      ...froalaBaseOptions, saveParams: {
        action: 'froala_save',
        editor: `campaign_design_quiz_question_above_content_${q}`
      }
    });

    $(`#main-content-quiz-question-below-content-${q}`).froalaEditor({
      ...froalaBaseOptions, saveParams: {
        action: 'froala_save',
        editor: `campaign_design_quiz_question_below_content_${q}`
      }
    });
  }

  $('#main-content-above-form').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_above_form'
    }
  });

  $('#main-content-below-form').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_below_form'
    }
  });


  $('#main-content-thanks').froalaEditor({
    key: wp.froala_api,
    fontFamily: fontFamilyOb,
    charCounterCount: false,
    toolbarSticky: false,
    toolbarButtons: [
      'bold',
      'italic',
      'underline',
      '|',
      'fontFamily',
      'fontSize',
      'color',
      '|',
      'paragraphFormat',
      'align',
      'formatOL',
      'formatUL',
      '-',
      'insertLink',
      'insertImage',
      'insertSocialIcons',
      'insertSocialShare',
      'insertMusicPlayer',
      'embedon',
      'myButton',
      'insertTable',
      '|',
      'insertHR',
      'selectAll',
      'clearFormatting',
      '|',
      'html',
      '|',
      'undo',
      'redo'
    ],
    fontSize: fontSizes,
    fontSizeDefaultSelection: '14',
    codeMirrorOptions: codeMirrorOpts,
    imageEditButtons: imgEditorButtons,
    imageDefaultWidth: 0,
    imageUploadParams: {
      action: 'froala_upload_files'
    },
    imageManagerLoadParams: {
      action: 'froala_image_manager'
    },
    imageUploadURL: wp.ajaxurl,
    imageManagerLoadURL: wp.ajaxurl,
    saveInterval: 2500,
    saveURL: wp.ajaxurl,
    saveParam: 'content',
    htmlAllowedAttrs: customHtmlAllowedAttrs,
    saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_thanks'
    }
  });

  $('.tab-variable-editor').each(function () {
    const $tabElem = $(this);
    const mpMode = $tabElem.data('mpmode');
    $tabElem.froalaEditor({
      key: wp.froala_api,
      charCounterCount: false,
      toolbarSticky: false,
      toolbarButtons: [
        'embedon',
        '|',
        'selectAll',
        'clearFormatting',
        '|',
        'html',
        '|',
        'undo',
        'redo'
      ],
      codeMirrorOptions: codeMirrorOpts,
      saveInterval: 2500,
      saveURL: wp.ajaxurl,
      saveParam: 'content',
      htmlAllowedAttrs: customHtmlAllowedAttrs,
      saveParams: {
        action: 'froala_save',
        editor: 'campaign_design_mp_' + mpMode,
        player: mpMode
      }
    });
  });

  $('#main-content-geo').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_geo'
    }
  });


  $('#main-content-expired').froalaEditor({
    ...froalaBaseOptions, saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_expired'
    }
  });

  $('#main-content-age-gate').froalaEditor({
    key: wp.froala_api,
    fontFamily: fontFamilyOb,
    charCounterCount: false,
    toolbarSticky: false,
    toolbarButtons: froalaToolBar,
    fontSize: fontSizes,
    fontSizeDefaultSelection: '14',
    codeMirrorOptions: codeMirrorOpts,
    imageEditButtons: imgEditorButtons,
    imageDefaultWidth: 0,
    imageUploadParams: {
      action: 'froala_upload_files'
    },
    imageManagerLoadParams: {
      action: 'froala_image_manager'
    },
    imageUploadURL: wp.ajaxurl,
    imageManagerLoadURL: wp.ajaxurl,
    imageManagerDeleteURL: wp.ajaxurl,
    imageManagerDeleteParams: {
      action: 'froala_delete_image'
    },
    imageManagerPreloader: '/wp-content/themes/sony/assets/images/loading.gif',
    saveInterval: 2500,
    saveURL: wp.ajaxurl,
    saveParam: 'content',
    htmlAllowedAttrs: customHtmlAllowedAttrs,
    saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_age_gate'
    }
  });

  $('#main-content-age-gate-failure').froalaEditor({
    key: wp.froala_api,
    fontFamily: fontFamilyOb,
    charCounterCount: false,
    toolbarSticky: false,
    toolbarButtons: froalaToolBar,
    fontSize: fontSizes,
    fontSizeDefaultSelection: '14',
    codeMirrorOptions: codeMirrorOpts,
    imageEditButtons: imgEditorButtons,
    imageDefaultWidth: 0,
    imageUploadParams: {
      action: 'froala_upload_files'
    },
    imageManagerLoadParams: {
      action: 'froala_image_manager'
    },
    imageUploadURL: wp.ajaxurl,
    imageManagerLoadURL: wp.ajaxurl,
    imageManagerDeleteURL: wp.ajaxurl,
    imageManagerDeleteParams: {
      action: 'froala_delete_image'
    },
    imageManagerPreloader: '/wp-content/themes/sony/assets/images/loading.gif',
    saveInterval: 2500,
    saveURL: wp.ajaxurl,
    saveParam: 'content',
    htmlAllowedAttrs: customHtmlAllowedAttrs,
    saveParams: {
      action: 'froala_save',
      editor: 'campaign_design_age_gate_failure'
    }
  });

  $stepReload.on('click', '[data-mptab]', e => {
    const {mptab, show} = e.currentTarget.dataset;
    $('[data-mptab]').each((index, value) => {
      const currentTab = $(value).data('mptab');
      if (currentTab === mptab) {
        $(value).addClass('active');
      } else {
        $(value).removeClass('active');
      }
    });
    $('.tab-variable-editor').each((index, value) => {
      const currentId = $(value).attr('id');
      if (currentId === show) {
        $(value).removeClass('hide');
      } else {
        $(value).addClass('hide');
      }
    });
    const $previewFullscreenElem = $('#preview-fullscreen');
    const $iframe = $('#campaign-preview-card iframe');
    let link = $previewFullscreenElem.attr('href');
    /*eslint-disable */
    link = link.replace('&spotify_preview', '');
    link = link.replace('&deezer_preview', '');
    link = link.replace('&apple_preview', '');
    link = link + '&' + mptab + '_preview';
    /* eslint-enable */
    $previewFullscreenElem.attr('href', link);
    $('body #campaign-preview-card').attr('data-url', link);
    $iframe.attr('src', link);
    $iframe[0].contentWindow.location.reload(true);
  });

  $stepReload.on('click', '#set-light-colors', () => {
    const minimal = $('#campaign-step-reload #set-light-colors').data(
      'minimal'
    );
    $bgColorCpElem.val('rgba(255,255,255,1)');
    $bgColorCpElem.css('border-color', 'rgba(255,255,255,1)');
    $headerTextBgCpElem.val('rgba(255,255,255,0)');
    $headerTextBgCpElem.css('border-color', 'rgba(255,255,255,0)');
    $headerTextCpElem.val('rgba(68,68,68,1)');
    $headerTextCpElem.css('border-color', 'rgba(68,68,68,1)');
    $pageTextCpElem.val('rgba(68,68,68,1)');
    $pageTextCpElem.css('border-color', 'rgba(68,68,68,1)');
    $footerBgCpElem.val('rgba(68,68,68,1)');
    $footerBgCpElem.css('border-color', 'rgba(68,68,68,1)');
    $footerTextCpElem.val('rgba(255,255,255,1)');
    $footerTextCpElem.css('border-color', 'rgba(255,255,255,1)');

    $clickableCpElem.val('rgba(45,150,221,1)');
    $clickableHoverCpElem.val('rgba(33,104,163,1)');
    $formLabelCpElem.val('rgba(51,51,51,1)');
    $formFieldTextCpElem.val('rgba(51,51,51,1)');
    $formFieldBgCpElem.val('rgba(255,255,255,1)');
    $formContBgCpElem.val('rgba(255,255,255,1)');
    $hoverDspButtonElem.val('rgba(33,104,163,1)');
    $clickableCpElem.css('border-color', 'rgba(45,150,221,1)');
    $clickableHoverCpElem.css('border-color', 'rgba(33,104,163,1)');
    $formLabelCpElem.css('border-color', 'rgba(51,51,51,1)');
    $formFieldTextCpElem.css('border-color', 'rgba(51,51,51,1)');
    $formFieldBgCpElem.css('border-color', 'rgba(255,255,255,1)');
    $formContBgCpElem.css('border-color', 'rgba(255,255,255,1)');
    $hoverDspButtonElem.css('border-color', 'rgba(33,104,163,1)');
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'design_save_css',
        item: 'set-light'
      },
      error() {
        errorToast();
      },
      success() {
        M.toast({
          html: 'Light CSS Styling Set',
          classes: 'success-toast'
        });
      },
      complete() {
        if (minimal === false) {
          reloadPreview();
        }
      }
    });
  });

  $stepReload.on('click', '#set-dark-colors', () => {
    const minimal = $('#campaign-step-reload #set-dark-colors').data('minimal');
    $bgColorCpElem.val('rgba(0,0,0,1)');
    $bgColorCpElem.css('border-color', 'rgba(0,0,0,1)');
    $headerTextBgCpElem.val('rgba(0,0,0,0)');
    $headerTextBgCpElem.css('border-color', 'rgba(0,0,0,0)');
    $headerTextCpElem.val('rgba(255,255,255,1)');
    $headerTextCpElem.css('border-color', 'rgba(255,255,255,1)');
    $pageTextCpElem.val('rgba(255,255,255,1)');
    $pageTextCpElem.css('border-color', 'rgba(255,255,255,1)');
    $footerBgCpElem.val('rgba(0,0,0,1)');
    $footerBgCpElem.css('border-color', 'rgba(0,0,0,1)');
    $footerTextCpElem.val('rgba(255,255,255,1)');
    $footerTextCpElem.css('border-color', 'rgba(255,255,255,1)');

    $clickableCpElem.val('rgba(23,151,192,1)');
    $clickableHoverCpElem.val('rgba(15,97,124,1)');
    $formLabelCpElem.val('rgba(255,255,255,1)');
    $formFieldTextCpElem.val('rgba(51,51,51,1)');
    $formFieldBgCpElem.val('rgba(255,255,255,1)');
    $formContBgCpElem.val('rgba(0,0,0,1)');
    $hoverDspButtonElem.val('rgba(15,97,124,1)');
    $clickableCpElem.css('border-color', 'rgba(23,151,192,1)');
    $clickableHoverCpElem.css('border-color', 'rgba(15,97,124,1)');
    $formLabelCpElem.css('border-color', 'rgba(255,255,255,1)');
    $formFieldTextCpElem.css('border-color', 'rgba(51,51,51,1)');
    $formFieldBgCpElem.css('border-color', 'rgba(255,255,255,1)');
    $formContBgCpElem.css('border-color', 'rgba(0,0,0,1)');
    $hoverDspButtonElem.css('border-color', 'rgba(15,97,124,1)');
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'design_save_css',
        item: 'set-dark'
      },
      error() {
        errorToast();
      },
      success() {
        M.toast({
          html: 'Dark CSS Styling Set',
          classes: 'success-toast'
        });
      },
      complete() {
        if (minimal === false) {
          reloadPreview();
        }
      }
    });
  });

  let codeMirrorCSS = null;

  if ($('#custom-css-code').length) {
    const code = document.querySelector('#custom-css-code');
    codeMirrorCSS = CodeMirror.fromTextArea(code, {
      lineNumbers: true,
      styleActiveLine: true,
      matchBrackets: true,
      readOnly: false,
      theme: 'material',
      smartIndent: false,
      tabSize: 2,
      mode: 'css'
    });
    codeMirrorCSS.on('blur', () => {
      const value = codeMirrorCSS.getValue();
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'design_save_custom_css',
          value
        },
        error() {
          errorToast();
        },
        success() {
          M.toast({
            html: 'Custom CSS Saved',
            classes: 'success-toast'
          });
        },
        complete() {
          reloadPreview();
        }
      });
    })

    codeMirrorCSS.on('keyup', function (instance, event) {
      const text = instance.getValue();

      if(text.search('\\help') !== -1) {
        initCssLibHelpModal();

        const cssLib = document.querySelector('#modal-css-library-help');
        const modal = M.Modal.getInstance(cssLib);

        modal.open();
      }

    })
    // Ugly hack because CM instance duplicates itself when tabbing design tabs
    const count = document.querySelectorAll(
      '#campaign-design-step .CodeMirror'
    );
    forEach(count, (index, value) => {
      if (index != 0) {
        value.remove();
      }
    });
  }

  $('#campaign-upgrade')
    .off()
    .on('click', () => {
      const campaign = $('#campaign-upgrade').data('id');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'upgrade_campaign',
          campaign
        },
        beforeSend() {
          startLoading();
        },
        error() {
          errorToast();
        },
        success(data) {
          $stepReload.html(data);
          M.toast({
            html: 'Campaign Upgraded',
            classes: 'success-toast'
          });
        },
        complete() {
          stopLoading();
          initCampaigns();
          initDesign();
        }
      });
    });

  $('#campaign-revert')
    .off()
    .on('click', () => {
      const campaign = $('#campaign-revert').data('id');
      $.ajax({
        url: wp.ajaxurl,
        method: 'POST',
        data: {
          action: 'revert_campaign',
          campaign
        },
        beforeSend() {
          startLoading();
        },
        error() {
          errorToast();
        },
        success(data) {
          $stepReload.html(data);
          M.toast({
            html: 'Campaign Reverted',
            classes: 'success-toast'
          });
        },
        complete() {
          stopLoading();
          initCampaigns();
          initDesign();
        }
      });
    });

  $('.img-size-hover').hover(
    function () {
      const unhide = $(this).data('unhide');
      const field = $('#layout-selector').data('meta');
      const type = $(`input[name="${field}"]:checked`).val();
      let text = '';
      if (type === 'hero') {
        if (unhide === 'desk-bg-hover') {
          text = '1920px by 500px';
        } else if (unhide === 'desk-fg-hover') {
          text = '1024px by 1024px';
        } else if (unhide === 'mobile-bg-hover') {
          text = '1024px by 350px';
        } else if (unhide === 'mobile-fg-hover') {
          text = '400px by 350px';
        }
      } else if (type === 'fullscreen') {
        if (unhide === 'desk-bg-hover') {
          text = '1920px by 1080px';
        } else if (unhide === 'mobile-bg-hover') {
          text = '768px by 1024px';
        }
      } else if (type === 'side-right' || type === 'side-left') {
        if (unhide === 'desk-bg-hover') {
          text = '960px by 1080px';
        } else if (unhide === 'desk-fg-hover') {
          text = '1024px by 1024px';
        } else if (unhide === 'mobile-bg-hover') {
          text = '1024px by 350px';
        } else if (unhide === 'mobile-fg-hover') {
          text = '400px by 350px';
        }
      }
      const $cardPanel = $(this).find(`.card-panel`);
      $cardPanel.find('.img-size-content').text(text);
    }
  );

  switchThanksPageRedirectView();

  $("input:radio[name='campaign_redirect_thanks']")
    .off()
    .on("change", () => {
      switchThanksPageRedirectView();
  });

  function switchThanksPageRedirectView() {
    if("1" === $("input:radio:checked[name='campaign_redirect_thanks']").val()) {
      $(".show-on-thanks-redirect").show();
      $(".hide-on-thanks-redirect").hide();
      $(".show-on-thanks-redirect_service_based").hide();
    } else if ( "2" === $("input:radio:checked[name='campaign_redirect_thanks']").val() ) {
      $(".show-on-thanks-redirect").hide();
      $(".hide-on-thanks-redirect").hide();
      $(".show-on-thanks-redirect_service_based").show();
    } else {
      $(".show-on-thanks-redirect").hide();
      $(".hide-on-thanks-redirect").show();
      $(".show-on-thanks-redirect_service_based").hide();
    }
  }

  const $presaveSortableBlock = $('#presave_buttons_block_sortable').sortable({
    axis: 'y'
  });

  $presaveSortableBlock.on('sortupdate',function(){
    const data = $(this).sortable('serialize');

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'design_reorder_presave_buttons',
        data: data,
      },
      error() {
        errorToast();
      },
      complete() {
        reloadPreview();
      }
    });

  });

  $('#custom-button-add-btn').on('click', function (){
    const custBtnTemplate = $("#custom-button-block-template li").first().clone();
    const id = Date.now();

    custBtnTemplate.attr('id', 'custom_button_'+id);
    custBtnTemplate.find('#_button_text').attr('id', id+'_button_text').attr('name', id+'_custom_button_text');
    custBtnTemplate.find('label').attr('for', id+'_button_text');
    custBtnTemplate.find('#_button_link').attr('id', id+'_button_link').attr('name', id+'_custom_button_link');

    $("#presave_buttons_block_sortable").append(custBtnTemplate);
  });

  $(".fasl-remove-btn").on('click', function (e) {
    e.preventDefault();
    const $item = $(this).closest('.presave-button-item');
    const id = $item.attr('id').replace(/^presave_button_/, '');

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'design_delete_custom_button',
        id: id,
      },
      error() {
        errorToast({
          html: "Error during Custom Button delete",
          classes: 'error-toast',
        });
      },
      success() {
        $.when($item.remove()).then(function () {
          M.toast({
            html: 'Custom Button Deleted',
            classes: 'success-toast'
          });
          $presaveSortableBlock.trigger('sortupdate');
        })
      }
    });

    return false;
  });

  $(".presave-button-item .material-icons").on("mousedown", function () {
    $(this).addClass("mouseDown");
  }).on("mouseup", function () {
    $(this).removeClass("mouseDown");
  });

  const today = new Date();

  $("#design-builder")
    .off()
    .on('click', '.edit-cd-timer', function(e) {

      const editType = $(this).data('task');
      let dateTime = $("#cd-timer-datatime").val();
      let defaultDate = null;
      let setDefaultDate = false;
      let defaultTime = 'now';

      if((dateTime.length > 0)) {
        defaultDate = new Date(dateTime);
        setDefaultDate = true;
        let re = /(.+)T(\d\d:\d\d):(.+)/;
        defaultTime = dateTime.replace(re, '$2');
        console.log(defaultTime);
      }

      const datePicker = document.querySelector(
        '#design-builder .add-cd-timer-datepicker'
      );
      const timePicker = document.querySelector(
        '#design-builder .add-cd-timer-timepicker'
      );

      let dateInstance;
      let timeInstance;
      let datetimeObj;

      if (editType === 'date') {
        console.log('date run');
        dateInstance = M.Datepicker.init(datePicker, {
          minDate: today,
          defaultDate: defaultDate,
          setDefaultDate: setDefaultDate,
          onSelect(date) {
            datetimeObj = moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), $(datePicker).data('tz'));
          },
          onClose() {
            dateInstance.destroy();
            if (typeof datetimeObj !== 'undefined') {
              const hours = moment(dateTime).tz($(datePicker).data('tz')).hour();
              const minutes = moment(dateTime).tz($(datePicker).data('tz')).minute();
              datetimeObj.hour(hours);
              datetimeObj.minute(minutes);

              editCDTimerDateTime(datetimeObj);
            }
          }
        });
        dateInstance.open();
      } else if (editType === 'time') {
        timeInstance = M.Timepicker.init(timePicker, {
          twelveHour: true,
          defaultTime: defaultTime
        });
        timeInstance.open();
        $('#design-builder .add-cd-timer-timepicker')
          .off()
          .on('change', function(e) {
            datetimeObj = e.target.value;
            timeInstance.destroy();
            dateTime = moment(dateTime).tz($(this).data('tz'));
            const timeObj = datetimeObj.split(':');
            dateTime.hour(parseInt(timeObj[0]));
            dateTime.minute(parseInt(timeObj[1]));

            editCDTimerDateTime(dateTime);
          });
      }
    })
    .on("change", ".cd-timer-datepicker-input", function (e) {
      editCDTimerDate($(this));
    })
    .on("change", ".cd-timer-expiration-input", function (e) {
      editCDTimerExpText($(this));
    })
    .on("change", ".cd-timer-timepicker-input", function (e) {
      editCDTimerTime($(this));
    });

  function editCDTimerExpText($elem) {
    const text = $elem.val();
    const postID = $('input[name="campaign_id"]').val();

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'edit_cd_timer_exp_date',
        text: text,
        post_id: postID
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        $("#cd-timer-expired-text").val(text);

        M.toast({
          html: 'Countdown Timer Updated',
          classes: 'success-toast'
        });
      }
    });
  }

  function editCDTimerDate($elem) {
    const $parent = $elem.parents('.cd-timer-datepicker-block');

    const month = $parent.find('.cd-timer-month-input').val();
    const day = $parent.find('.cd-timer-day-input').val();
    const year = $parent.find('.cd-timer-year-input').val();

    const tz = $("#cd-timer-tz").val();
    const dateTime = $("#cd-timer-datatime").val();

    if(validateDate(month, day, year)) {
      let time = '00:00';

      if((dateTime.length > 0)) {
        let re = /(.+)T(\d\d:\d\d):(.+)/;
        time = dateTime.replace(re, '$2');
      }
      console.log(time);
      console.log(moment.tz(year+'-'+month+'-'+day+' '+time, tz));
      editCDTimerDateTime(moment.tz(year+'-'+n(month)+'-'+n(day)+' '+time, tz));
    }
  }

  function validateDate(month, day, year) {
    if(month === undefined || day === undefined || year.length === 0) {
      return false
    } else if(month.length === 0 || day.length === 0 || year.length === 0) {
      return false;
    } else if(month > 12 || month < 1) {
      return false;
    } else if(day > 31 || day < 1) {
      return false;
    } else return year >= new Date().getFullYear();
   }


  function editCDTimerTime($elem) {
    const $parent = $elem.parents('.cd-timer-timepicker-block');

    const hour = $parent.find('.cd-timer-hour-input').val();
    const minute = $parent.find('.cd-timer-minute-input').val();
    const second = $parent.find('.cd-timer-second-input').val();

    const tz = $("#cd-timer-tz").val();

    if(validateTime(hour, minute, second)) {
      const dtObj = splitDateTime($("#cd-timer-datatime").val());

      editCDTimerDateTime(moment.tz(dtObj.year+'-'+dtObj.month+'-'+dtObj.day+' '+n(hour)+':'+n(minute)+':'+n(second), tz));
    }
  }

  function validateTime(hour, minute, second) {
    if(hour === undefined || minute === undefined || second.length === 0) {
      return false
    } else if(hour.length === 0 || minute.length === 0 || second.length === 0) {
      return false;
    } else if(hour > 24 || hour < 0) {
      return false;
    } else if(minute > 60 || minute < 0) {
      return false;
    } else return !(second > 60 || second < 0);
  }

  function n(num, len = 2) {
    return `${num}`.padStart(len, '0');
  }

  function editCDTimerDateTime(  datetime ) {
    const obj = datetime.format();
    const postID = $('input[name="campaign_id"]').val();

    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'edit_cd_timer_datetime',
        datetime: obj,
        post_id: postID
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        $("#cd-timer-datatime").val(obj);
        const dtObj = splitDateTime(obj);
        $(".cd-timer-month-input").val(dtObj.month);
        $(".cd-timer-day-input").val(dtObj.day);
        $(".cd-timer-year-input").val(dtObj.year);
        $(".cd-timer-hour-input").val(dtObj.hour);
        $(".cd-timer-minute-input").val(dtObj.minute);
        $(".cd-timer-second-input").val(dtObj.second);

        M.toast({
          html: 'Countdown Timer Updated',
          classes: 'success-toast'
        });
      }
    });
  }

  $('body').on('change', 'select[name="cd_timer_tz"]', function(){
    editCDTimerTZ( $(this).val() );
  })
  .on('change', '.cd_timer_format', function(){
    editCDTimerFormat( $(this).val() );
  });

  function editCDTimerTZ( tz ) {
    const postID = $('input[name="campaign_id"]').val();
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'edit_cd_timer_tz',
        tz: tz,
        post_id: postID
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        $('#cd-timer-tz').val(tz);
        $('.add-cd-timer-datepicker').attr('data-tz', tz);
        $('.add-cd-timer-timepicker').attr('data-tz', tz);
        M.toast({
          html: 'Countdown Timer Updated',
          classes: 'success-toast'
        });
      }
    });
  }

  function editCDTimerFormat( format ) {
    const postID = $('input[name="campaign_id"]').val();
    $.ajax({
      url: wp.ajaxurl,
      method: 'POST',
      data: {
        action: 'edit_cd_timer_format',
        format: format,
        post_id: postID
      },
      error() {
        M.toast({
          html: 'Error Saving',
          classes: 'error-toast'
        });
      },
      success(data) {
        console.log(format);
        $('#cd-timer-format').val(format);
        M.toast({
          html: 'Countdown Timer Updated',
          classes: 'success-toast'
        });
      }
    });
  }

  function splitDateTime(dateTime) {
    let dtObj = {
      year: new Date().getFullYear(),
      month: 1,
      day: 1,
      hour: 0,
      minute: 0,
      second: 0,
    }

    if(dateTime.length > 0) {
      const re = /(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)(.+)/;
      dtObj = {
        year: dateTime.replace(re, '$1'),
        month: dateTime.replace(re, '$2'),
        day: dateTime.replace(re, '$3'),
        hour: dateTime.replace(re, '$4'),
        minute: dateTime.replace(re, '$5'),
        second: dateTime.replace(re, '$6'),
      }
    }

    return dtObj;
  }

  $('.show-clh-collection').on('click', function (e) {
    e.preventDefault();
    initCssLibHelpModal();

    const cssLib = document.querySelector('#modal-css-library-help');
    const mediaModal = M.Modal.getInstance(cssLib);
    mediaModal.open();
  });

  function initCssLibHelpModal() {
    const cssLib = document.querySelector('#modal-css-library-help');

    M.Modal.init(cssLib, {
      dismissible: false,
      opacity: 0.8,
      onCloseEnd() {
        const mediaModal = M.Modal.getInstance(cssLib);
        mediaModal.destroy();
      }
    });
  }

  $('.css-library-help-item').on('click', function () {
    console.log(codeMirrorCSS);
    if (codeMirrorCSS !== null) {
      const snippet = $(this).data('snippet');

      const text = codeMirrorCSS.getValue();
      let new_css = '';
      const re = /\/help/g;

      console.log(text);
      console.log(snippet);

      if(text.search(re) !== -1) {
        new_css = text.replace(re, snippet)
      } else {
        new_css = text + "\n" + snippet;
      }

      console.log(new_css);

      codeMirrorCSS.setValue(new_css);
    }

    const cssLib = document.querySelector('#modal-css-library-help');
    const mediaModal = M.Modal.getInstance(cssLib);
    mediaModal.close();
  });
}
